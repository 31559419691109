
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  removeFAQSection,
  updateFAQSection,
  addFAQContent,
} from "@/components/faq/utils";
import AccordionItem from "@/components/faq/views/components/AccordionItem.vue";
import EditableInput from "@/components/faq/views/components/EditableInput.vue";
import Icon from "@/components/Icons.vue";

@Component({
  components: {
    AccordionItem,
    EditableInput,
    Icon,
  },
})
export default class Accordion extends Vue {
  @Prop({}) accordions!: any;
  @Prop({}) editable!: boolean;

  private editSection: boolean = false;

  public editSectionContent(): boolean {
    return (this.editSection = !this.editSection);
  }

  async updateSection(itemContent: string): Promise<void> {
    await updateFAQSection(this.accordions.id, { title: itemContent });
    this.accordions!.title = itemContent;
    this.$emit("updateSection");
    this.editSection = false;
  }

  async removeSection(id: number): Promise<void> {
    await removeFAQSection(id);
    this.$emit("updateSection");
  }

  async addQuestionAnswer(id: number): Promise<void> {
    await addFAQContent({
      section: id,
      question: "New Question",
      answer: "New Answer",
    });
    await this.$emit("updateSection");
  }

  refreshSection(): void {
    this.$emit("updateSection");
  }

  @Watch("accordions", { immediate: true })
  onUpdateAccordions(val: any){
    if(!val.content.length) {
      if(this.editable) {
        this.addQuestionAnswer(val.id);
      }
    }
  }
}
