var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("div", { staticClass: "lower-title mb-1" }, [
                _vm._v("Description"),
              ]),
              _c("span", [
                _vm._v(" " + _vm._s(_vm.contentTypeDescription) + " "),
              ]),
              _c("b-form-select", {
                staticClass: "mt-2",
                attrs: { options: _vm.contentTypeOptions },
                model: {
                  value: _vm.contentType,
                  callback: function ($$v) {
                    _vm.contentType = $$v
                  },
                  expression: "contentType",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c("b-col", [
            _c(
              "button",
              {
                staticClass: "btn btn-danger w-100 cs-red-btn",
                on: { click: _vm.updateContentType },
              },
              [_vm._v("Save")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }