var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _vm.businessUnits
                ? _c("b-form-select", {
                    staticClass: "cs-bu-select",
                    attrs: { options: _vm.businessUnitsOptions },
                    model: {
                      value: _vm.businessUnit,
                      callback: function ($$v) {
                        _vm.businessUnit = $$v
                      },
                      expression: "businessUnit",
                    },
                  })
                : _c("b-form-select", {
                    staticClass: "cs-bu-select",
                    attrs: { options: _vm.businessUnitsOptions },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c("b-col", [
            _c(
              "button",
              {
                staticClass: "btn btn-danger w-100 cs-red-btn",
                on: { click: _vm.updateBusinessUnit },
              },
              [_vm._v("Save")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }