import service from "../../../utils/http/apiClient";
import store from "@/store";

export const getFAQs = async (): Promise<any> => {
    try {
        const response = await service.get("/faq/");
        return response.data;
    } catch (err) {
        store.commit("setToast", {
            variant: "danger",
            title: "FAQs Failed to Load",
            body: "Please contact your Administrator."
        })
    }
}

export const addFAQSection = async (faqSectionData: any): Promise<any> => {
    try {
        const response = await service.post("/faq-section/", faqSectionData);
        return response.data;
    } catch (err) {
        store.commit("setToast", {
            variant: "danger",
            title: "The FAQ Section could not be saved",
            body: "Please contact your Administrator."
        })
    }
}

export const updateFAQSection = async (id: number, faqSectionData: any): Promise<any> => {
    try {
        const response = await service.put(`/faq-section/${id}/`, faqSectionData);
        return response.data;
    } catch (err) {
        store.commit("setToast", {
            variant: "danger",
            title: "The FAQs could not be updated",
            body: "Please contact your Administrator."
        })
    }
}

export const removeFAQSection = async (id: number): Promise<any> => {
    try {
        const response = await service.remove(`/faq-section/${id}/`);
        return response.data;
    } catch (err) {
        console.log(err);
    }
}

export const addFAQContent = async (faqContentData: any): Promise<any> => {
    try {
        const response = await service.post("/faq-content/", faqContentData);
        return response.data;
    } catch (err) {
        store.commit("setToast", {
            variant: "danger",
            title: "The FAQ Content could not be saved",
            body: "Please contact your Administrator."
        })
    }
}

export const updateFAQContent = async (id: number, faqContentData: any): Promise<any> => {
    try {
        const response = await service.put(`/faq-content/${id}/`, faqContentData);
        return response.data;
    } catch (err) {
        store.commit("setToast", {
            variant: "danger",
            title: "The FAQ Content could not be updated",
            body: "Please contact your Administrator."
        })
    }
}

export const removeFAQContent = async (id: number): Promise<any> => {
    try {
        const response = await service.remove(`/faq-content/${id}/`);
        return response.data;
    } catch (err) {
        console.log(err);
    }
}