
import { Component, Vue } from "vue-property-decorator";
import { getUserProfile } from "@/components/dashboard/utils";
import authUtils from "@/utils/authUtils";
import Icon from "@/components/Icons.vue";

@Component({
  components: {
    Icon,
  },
})
export default class Navbar extends Vue {
  public isActiveLink = "active-link";
  public loggedUser: any = null;
  public grpNo: number = 4;

  async created() {
    const parseUserInfo = JSON.parse(localStorage.getItem("userInfo") ?? '[]');
    if(parseUserInfo != []){
      this.grpNo = parseUserInfo["groups"][0];
    }

    this.loggedUser = authUtils.getUserInfo();

    if (this.loggedUser == null) {
      let data = await getUserProfile();
      authUtils.setUserInfo(data);
    }
  }

  get fullName(): string {
    let firstName: string = this.loggedUser?.first_name ?? "";
    let surName: string = this.loggedUser?.last_name ?? "";
    return `${firstName} ${surName}`;
  }

  public logoutAction() {
    this.$store.dispatch("Login/logoutWithMSALAction");
    authUtils.deleteToken();
    authUtils.deleteMSAuthToken();
  }

  /* Navigation events - Start */
  resetActiveNavClasses(navType: string) {
    let allMenuItem = document.querySelectorAll("#mchp-side-nav .nav-link");
    allMenuItem.forEach((item, index) => {
      item!.classList.remove("router-link-active"); // reset nav containing this class
    });
    this.setActiveParentParams(navType);
  }

  setActiveParentParams(navType: string) {
    // Add back the active class
    navType === "dashboard"
      ? document
          .querySelector("#nav-dashboard .nav-link")
          ?.classList.add("router-link-active")
      : "";
    navType === "syndications"
      ? document
          .querySelector("#nav-syndications .nav-link")
          ?.classList.add("router-link-active")
      : "";
    navType === "partner"
      ? document
          .querySelector("#nav-partner .nav-link")
          ?.classList.add("router-link-active")
      : "";
    navType === "user-settings"
      ? document
          .querySelector("#nav-user-settings .nav-link")
          ?.classList.add("router-link-active")
      : "";
    navType === "faq"
      ? document
          .querySelector("#nav-faq .nav-link")
          ?.classList.add("router-link-active")
      : "";
  }
  /* Navigation events - End */

  fromSideNavLink(e: Event, sideTabName: string) {
    e.preventDefault();
    this.$router.push({ name: sideTabName });
    localStorage.setItem("userBusinessUnit", "");
    localStorage.setItem("isFromDashboard", "false");
  }
}
