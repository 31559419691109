
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({
    name: "EditTitle"
})
export default class EditTitle extends Vue {
    @Prop({}) propTitle: any;
    @Prop({}) propSnippetId: any;

    title = "";
    snippetId = 0;

    mounted() {
        this.title = this.propTitle;
        this.snippetId = this.propSnippetId;
    }

    saveTitle():void {
        let kwargs = { title: this.title, snippetId: this.snippetId }
        this.$emit("updateSnippetTitle", kwargs)
    }
}
