
import {Component, Vue} from 'vue-property-decorator';
import {getEndSoonSnippets, getStartSoonSnippets} from '@/components/dashboard/utils';
import authUtils from "@/utils/authUtils";

@Component({
  name: "EndSoonBlock"
})
export default class EndSoonBlock extends Vue {
  public items: any = null;
  public isLoading: boolean = false;
  public filterItemsWithDateValidation: any = null;

  async mounted() {
    this.isLoading = true;

    let userInfo = authUtils.getUserInfo();
    let params = {business_unit: userInfo.profile.business_unit}
    let response = await getEndSoonSnippets(params);
    this.items = response?.data ?? null;
    
    if(this.items !== null) {
      [...new Map(
          this.items.map((item: any) =>[item["id"], item])
        ).values()
      ].forEach((item: any) => { 
        this.newItems(item) })
    }

    this.isLoading = false;
  }

  newItems(item: any) {
    try {
      const objects = item.snippet_partners.filter(this.checkEndDate);
      const updatedPartners = objects.map((_snippetPartner: any) => { 
        const _partners = item.partners;
        const index = _partners.findIndex( (_partner: any) => _snippetPartner.partner === _partner.id );
        if(index > -1) {
          _partners[index]["snippetPartners"] = _snippetPartner;
          return _partners[index];
        }
      })
      
      const snippets = objects.map((object: any) => {
        item.snippet_partners = objects;
        item.partners = updatedPartners;
        
        return item;
      });
      
      const newSnippets = snippets[0].partners.map((partner: any, index: number) => {
        const snip = {...snippets[index]};
        if (item.partners[0] !== undefined) {
          snip["list"] = partner
        } else {
          let temp = {
            id: item.id,
            title: item.title,
            business_unit: item.business_unit,
            snippetPartners: item.snippet_partners[0]
          }
          snip["list"] = temp
        }
  
        return snip;
      });

      if(this.filterItemsWithDateValidation == null) {
        this.filterItemsWithDateValidation = newSnippets ?? [];
      }
      else {
        this.filterItemsWithDateValidation = [...this.filterItemsWithDateValidation,...newSnippets]
      }
    } catch (error) {
      this.filterItemsWithDateValidation = null;
    }
  }

  checkEndDate(item: any) {
    const dateToday:any = new Date();
    const itemEndDate:any = new Date(item.end_date);

    const numberOfDays = this.subtractDays(dateToday, itemEndDate);

    // 1 to 5 days
    if (numberOfDays < 6 && numberOfDays > 0) {
      return item
    }
  }

  subtractDays(dateToday:any, itemDate: any) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate());
    const utc2 = Date.UTC(itemDate.getFullYear(), itemDate.getMonth(), itemDate.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  get itemCount(): number {
    return this.items?.length ?? 0;
  }
}
