var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "cs-edit-title-container" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-form-input", {
                model: {
                  value: _vm.title,
                  callback: function ($$v) {
                    _vm.title = $$v
                  },
                  expression: "title",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c("b-col", [
            _c(
              "button",
              {
                staticClass: "btn btn-danger w-100 cs-red-btn",
                on: {
                  click: function ($event) {
                    return _vm.saveTitle()
                  },
                },
              },
              [_vm._v("Save")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }