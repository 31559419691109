
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: "EditGAcampaign"
})
export default class EditGAcampaign extends Vue{
  @Prop({}) propGaCampaignCode: any;
  @Prop({}) propSnippetId:any;

  public gaCampaignCode: string = '';
  public snippetId: number = 0;

  created(){
    this.gaCampaignCode = this.propGaCampaignCode;
    this.snippetId = this.propSnippetId;
  }

  public updateGaCampaignCode(){
    let kwargs = {gaCampaignCode: this.gaCampaignCode, snippetId: this.snippetId};
    this.$emit("updatedGaCampaignCode", kwargs);
  }
}
