var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "mchp-editable-area" }, [
              _c("div", { staticClass: "mchp-edit-controls" }, [
                _c(
                  "button",
                  {
                    staticClass: "mchp-edit-control",
                    on: {
                      click: function ($event) {
                        return _vm.formatText("sub")
                      },
                    },
                  },
                  [_c("Icon", { attrs: { name: "subscript" } })],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "mchp-edit-control",
                    on: {
                      click: function ($event) {
                        return _vm.formatText("sup")
                      },
                    },
                  },
                  [_c("Icon", { attrs: { name: "superscript" } })],
                  1
                ),
              ]),
              _c("div", {
                ref: "snippetTextarea",
                attrs: { contenteditable: "true" },
                domProps: { innerHTML: _vm._s(_vm.singleSnippet.summary) },
              }),
            ]),
          ]),
        ],
        1
      ),
      _vm.isDateInvalid
        ? _c(
            "b-row",
            [
              _c("b-col", [
                _c("span", { staticClass: "cs-calendar-error" }, [
                  _vm._v(
                    "End date must be at least one day after the start date."
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "mt-1" },
        [
          _c(
            "b-col",
            [
              _c("label", { attrs: { for: "picker-start-date" } }, [
                _vm._v("Start Date"),
              ]),
              _c("b-form-datepicker", {
                staticClass: "mb-1",
                attrs: {
                  id: "picker-start-date",
                  min: _vm.minDate,
                  placeholder: "Start Date",
                },
                on: {
                  input: function ($event) {
                    return _vm.checkDates()
                  },
                },
                model: {
                  value: _vm.startDate,
                  callback: function ($$v) {
                    _vm.startDate = $$v
                  },
                  expression: "startDate",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c("label", { attrs: { for: "picker-end-date" } }, [
                _vm._v("End Date"),
              ]),
              _c("b-form-datepicker", {
                staticClass: "mb-1",
                attrs: {
                  id: "picker-end-date",
                  min: _vm.minEndDate,
                  placeholder: "End Date",
                },
                on: {
                  input: function ($event) {
                    return _vm.checkDates()
                  },
                },
                model: {
                  value: _vm.endDate,
                  callback: function ($$v) {
                    _vm.endDate = $$v
                  },
                  expression: "endDate",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c("b-col", [
            _c(
              "button",
              {
                staticClass: "btn btn-danger w-100 cs-red-btn",
                attrs: { disabled: _vm.isDateInvalid },
                on: { click: _vm.updateSnippetSummary },
              },
              [_vm._v("Save")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }