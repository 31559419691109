
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  name: "SearchBar",
  methods: {},
})
export default class SearchBar extends Vue {
  @Prop({ default: "" }) readonly childSelect: any;
  @Prop({ default: "" }) readonly fieldDisplay: any;
  @Prop({ default: [] }) readonly dataList: any | Array<any>;
  public addOnList: any[] = [];
  public selected: any = "";

  public onSelect(payload: any) {
    this.selected = payload;
    // Revert to orignal values
    this.addOnList = this.dataList
      .map((row: any) => {
        return row[this.fieldDisplay];
      })
      .filter((x: any, i: any, a: any) => a.indexOf(x) == i);

    const params = {};
    params[this.fieldDisplay] = this.selected;
    this.$emit("onSelect", params);
  }

  mounted() {
    this.selected = "";
    // this.onSelect("");
  }

  @Watch("dataList", { immediate: true, deep: true })
  onChangeList(current: any, previous: any) {
    if (JSON.stringify(current) != JSON.stringify(previous)) {
      this.selected = null;
    }
    this.addOnList = this.dataList
      .map((row: any) => {
        return row[this.fieldDisplay];
      })
      .filter((x: any, i: any, a: any) => a.indexOf(x) == i);
  }

  @Watch("selected", { immediate: true, deep: true })
  onWSelected(val: string) {
    this.addOnList = this.dataList
      .map((row: any) => {
        return row[this.fieldDisplay];
      })
      .filter((x: any, i: any, a: any) => a.indexOf(x) == i);

    if (!this.addOnList.includes(val)) this.addOnList.unshift(val);
  }

  // Empty Selected
  @Watch("childSelect", { immediate: true, deep: true })
  onChildSelectChange() {
    this.selected = "";
  }
}
