var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isSearch
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "cs-search-box" },
                [
                  _c("b-card", { staticClass: "text-center" }, [
                    _vm.isSyndicatedSnippetPage
                      ? _c("div", [
                          _c("h6", [
                            _vm._v(
                              " Found " +
                                _vm._s(_vm.syndicatedSnippetsRow) +
                                " " +
                                _vm._s(
                                  `Result${
                                    _vm.syndicatedSnippetsRow ? "s" : ""
                                  }`
                                ) +
                                " "
                            ),
                          ]),
                        ])
                      : _c("div", [
                          _c("h6", [
                            _vm._v(
                              "Found " +
                                _vm._s(_vm.live_snippets.results.length) +
                                " " +
                                _vm._s(`Results`)
                            ),
                          ]),
                        ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isSyndicatedSnippetPage
        ? _c(
            "div",
            [
              _c(
                "b-overlay",
                {
                  staticClass: "cs-container-scroll-control",
                  attrs: { show: _vm.isLoading },
                },
                _vm._l(_vm.partnerSnippetsForList, function (snip) {
                  return _c("LiveCards", {
                    key: snip.id,
                    attrs: {
                      id: "queueCardList",
                      item: snip,
                      isSearchResult: _vm.isSearch,
                      isSyndicatedSnippetPage: _vm.isSyndicatedSnippetPage,
                      "prop-partner-list": _vm.partnersList,
                      user: _vm.user,
                    },
                    on: {
                      updateSnippetPartner: _vm.reloadAfterSnippetPartnerUpdate,
                      updateSnippetSaved: _vm.reloadAfterUpdate,
                      snippetBackToDraft: _vm.reloadAfterSnippetBackToDraft,
                    },
                  })
                }),
                1
              ),
              _vm.hasSnippets
                ? _c(
                    "b-row",
                    {
                      staticClass: "pl-2 pt-3 pr-2 pagination-container",
                      attrs: { sm: "12" },
                    },
                    [
                      _c("b-col", { attrs: { md: "4", sm: "12" } }, [
                        _c("p", [
                          _vm._v(
                            " Showing " +
                              _vm._s(_vm.getPageStart) +
                              " - " +
                              _vm._s(
                                (_vm.currentPage - 1) * _vm.perPage +
                                  _vm.partnerSnippetsForList.length
                              ) +
                              " of " +
                              _vm._s(_vm.syndicatedSnippetsRow) +
                              " results "
                          ),
                        ]),
                      ]),
                      _c("b-col", { attrs: { md: "4", sm: "12" } }, [
                        _c(
                          "div",
                          [
                            _c("b-pagination", {
                              staticClass: "cs-page-buttons",
                              attrs: {
                                "total-rows": _vm.syndicatedSnippetsRow,
                                "per-page": _vm.perPage,
                                "last-text": ">",
                                "first-text": "<",
                                "next-text": "Next",
                                "prev-text": "Previous",
                                align: "center",
                                "aria-controls": "queueCardList",
                              },
                              model: {
                                value: _vm.currentPage,
                                callback: function ($$v) {
                                  _vm.currentPage = $$v
                                },
                                expression: "currentPage",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("b-col", { attrs: { md: "4", sm: "12" } }, [
                        _c(
                          "p",
                          { staticClass: "text-right" },
                          [
                            _c("span", [_vm._v("Results per page: ")]),
                            _c("b-form-select", {
                              staticClass: "cs-pagination-select",
                              attrs: {
                                align: "right",
                                options: _vm.perPageSelect,
                              },
                              model: {
                                value: _vm.perPage,
                                callback: function ($$v) {
                                  _vm.perPage = $$v
                                },
                                expression: "perPage",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "b-overlay",
                {
                  staticClass: "cs-container-scroll-control",
                  attrs: { show: _vm.isLoading },
                },
                _vm._l(_vm.snippetsForList, function (snip) {
                  return _c("LiveCards", {
                    key: snip.id,
                    staticClass: "cs-regular-snippets",
                    attrs: {
                      id: "queueCardList",
                      item: snip,
                      isSearchResult: _vm.isSearch,
                      "prop-partner-list": _vm.partnersList,
                      user: _vm.user,
                    },
                    on: {
                      updateSnippetPartner: _vm.reloadAfterSnippetPartnerUpdate,
                      updateSnippetSaved: _vm.reloadAfterUpdate,
                      snippetBackToDraft: _vm.reloadAfterSnippetBackToDraft,
                    },
                  })
                }),
                1
              ),
              !_vm.hasSnippets
                ? _c(
                    "b-row",
                    {
                      staticClass: "pl-2 pt-3 pr-2 pagination-container",
                      attrs: { sm: "12" },
                    },
                    [
                      _c("b-col", { attrs: { md: "4", sm: "12" } }, [
                        _c("p", [
                          _vm._v(
                            " Showing " +
                              _vm._s(_vm.getPageStart) +
                              " - " +
                              _vm._s(
                                (_vm.currentPage - 1) * _vm.perPage +
                                  _vm.snippetsForList.length
                              ) +
                              " of " +
                              _vm._s(_vm.rows) +
                              " results "
                          ),
                        ]),
                      ]),
                      _c("b-col", { attrs: { md: "4", sm: "12" } }, [
                        _c(
                          "div",
                          [
                            _c("b-pagination", {
                              staticClass: "cs-page-buttons",
                              attrs: {
                                "total-rows": _vm.rows,
                                "per-page": _vm.perPage,
                                "last-text": ">",
                                "first-text": "<",
                                "next-text": "Next",
                                "prev-text": "Previous",
                                align: "center",
                                "aria-controls": "queueCardList",
                              },
                              model: {
                                value: _vm.currentPage,
                                callback: function ($$v) {
                                  _vm.currentPage = $$v
                                },
                                expression: "currentPage",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("b-col", { attrs: { md: "4", sm: "12" } }, [
                        _c(
                          "p",
                          { staticClass: "text-right" },
                          [
                            _c("span", [_vm._v("Results per page: ")]),
                            _c("b-form-select", {
                              staticClass: "cs-pagination-select",
                              attrs: {
                                align: "right",
                                options: _vm.perPageSelect,
                              },
                              model: {
                                value: _vm.perPage,
                                callback: function ($$v) {
                                  _vm.perPage = $$v
                                },
                                expression: "perPage",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
      _vm.isSyndicatedLoading
        ? _c(
            "div",
            { staticClass: "cs-fullscreen-loading" },
            [
              _c("b-spinner", {
                staticStyle: { width: "3rem", height: "3rem" },
                attrs: { label: "Large Spinner" },
              }),
              _c("b-spinner", {
                staticStyle: { width: "3rem", height: "3rem" },
                attrs: { label: "Large Spinner", type: "grow" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isSyndicatedSnippetPage && !_vm.hasSnippets
        ? _c(
            "div",
            [
              _c(
                "b-row",
                { staticClass: "cs-empty-container" },
                [
                  _c("b-col", [
                    _vm.isSyndicatedLoading
                      ? _c("div", {
                          staticClass:
                            "d-flex align-items-center justify-content-center v-center-height",
                        })
                      : _c("div", { staticClass: "cs-empty-snippets" }, [
                          _vm._v(
                            " There are no items available for this partner yet. "
                          ),
                        ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }