
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import EditableInput from "@/components/faq/views/components/EditableInput.vue";
import { removeFAQContent, updateFAQContent } from "@/components/faq/utils";
import Icon from "@/components/Icons.vue";

interface Question {
  id: number;
  question: string;
  answer: string;
}

@Component({
  name: "Accordion",
  components: {
    Icon,
    EditableInput,
  },
})
export default class AccordionItem extends Vue {
  @Prop({}) accordionItem!: Question;
  @Prop({}) editable!: boolean;

  public isExpanded = false;
  private editItem: boolean = false;
  private editQuestion: boolean = false;
  private editAnswer: boolean = false;

  private headerClicked() {
    if (this.editQuestion || this.editAnswer) {
      return;
    }
    this.isExpanded = !this.isExpanded;
  }

  // Animate the accordion item when it is expanded/collapses
  private beforeEnter(el: HTMLInputElement): void {
    el.style.height = "0";
  }

  private enter(el: HTMLInputElement): void {
    el.style.height = `${el.scrollHeight}px`;
  }

  private beforeLeave(el: HTMLInputElement): void {
    el.style.height = `${el.scrollHeight}px`;
  }

  private leave(el: HTMLInputElement): void {
    el.style.height = "0";
  }

  // Adjust the height of the accordion item when the answer is being edited updated
  private adjustHeight(): void {
    const body = this.$refs.accordionBody as HTMLInputElement;
    const inner = this.$refs.accordionInner as HTMLInputElement;
    const offset = this.editItem ? 80 : 0; // 80px for save button
    body.style.height = "0px";
    body.style.height = `${offset + inner.scrollHeight}px`;
  }

  public editQuestionContent(): void {
    this.editQuestion = !this.editQuestion;
    this.editItem = !this.editItem;
  }

  public editAnswerContent(): void {
    this.editAnswer = !this.editAnswer;
    this.editItem = !this.editItem;
  }

  async updateQuestion(itemContent: string): Promise<void> {
    await updateFAQContent(this.accordionItem.id, {
      question: itemContent,
      answer: this.accordionItem.answer,
    });
    this.$emit("updateItem");
    this.accordionItem.question = itemContent;
    this.editItem = false;
    this.editQuestion = false;
  }

  async updateAnswer(itemContent: string): Promise<void> {
    await updateFAQContent(this.accordionItem.id, {
      question: this.accordionItem.question,
      answer: itemContent,
    });
    this.$emit("updateItem");
    this.accordionItem.answer = itemContent;
    this.editItem = false;
    this.editAnswer = false;
    this.adjustHeight();
  }

  async removeQuestionAnswer(id: number): Promise<void> {
    await removeFAQContent(id);
    this.$emit("updateItem");
  }

  @Watch("editAnswer")
  adjustAnswerHeight(): void {
    this.adjustHeight();
  }
}
