
import { Component, Vue, Watch } from 'vue-property-decorator';
import {mapGetters} from "vuex";
import {getModule} from "vuex-module-decorators";
import FormFilter from "../../common/FilterBarSyndication.vue"
import Module from "./../store"
import {getPartnerTypes, postPartnerType, removePartnerType, putPartnerType} from "./../utils"
import SearchBar from "./../../common/SearchBar.vue"


@Component({
  name: "UserSettings",
  components: {FormFilter, SearchBar},
  computed : {
    ...mapGetters("UserSettings", {
      rows: "fnRows",
      isLoading: "fnLoadingState",
      optionUnits: "fnUnitOptions",
      optionAccess: "fnAccessOptions",
      fields: "fnFields",
      updatedRows: "fnUpdatedRows",
      isDisabled: "fnFormState",
    })
  }
})
export default class UserSettings extends Vue {
    Store: Module | undefined
    rows!: any

    public filter = null
    public filterOn = ['email']
    public sortBy:string = 'email'
    public sortDesc = false;
    public partnerTypes : Array<any> = [];

    public totalRows:number = 1
    public perPage: number = 5;
    public currentPage: number = 1;
    public perPageSelect = [
      {"value": 5, "text": "5"},
      {"value": 10, "text": "10"},
      {"value": 15, "text": "15"},
      {"value": 20, "text": "20"},
      {"value": 25, "text": "25"}
    ];

    public filteredRows : Array<any>
    public selected = null;
    public options: any = [
      { value: null, text: 'Please select an option' },
    ]
    public modalValue: any = {}
      
    async onSearch(payload : string) {
      this.filteredRows = this.rows.filter((item : any) => item.email.includes(payload));
    }

    async onSelect(payload : any) {
      this.filter = payload?.email ?? "";
    }

    async getPartners() {
      const data = await getPartnerTypes();
      this.partnerTypes = data.map((item : any) => {
          item["onEdit"] = false; 
          return item;
        });
    }

    async savePartner(item: any){
      const {id, description} = item;
      if(item.id == 0)
        await postPartnerType({id, description})
      else 
        await putPartnerType({id, description})

      await this.getPartners();
    }

    async showDeleteModal(item: any) {
      this.options = this.partnerTypes.map((partner: any) => {
        if(partner.id != item.id)
          return {
            value: partner.id,
            text:partner.description
          }
        
        return false;
      }).filter(Boolean);
      this.selected = null; 
      this.modalValue = item;

      this.$bvModal.show('modal-partner');
    }

    async deletePartner(payload: any, replaceId: number) {
      if(this.selected != null) {
        const item: any = {
          ...payload,
          newPartner: {
            new_partner_type_id: replaceId
          }
        }
        await removePartnerType(item)
        await this.getPartners();
        this.$bvModal.hide('modal-partner')
      }
      else {
        this.Store!.errMessage({
            variant: 'danger',
            title: 'No replacement found',
            body: "New partner is required before delete."
        });
      }
    }

    editPartner(item: any, isOnEdit: boolean): void {
      item.onEdit = isOnEdit;
    }

    addPartner() : void{
      this.partnerTypes.push({
        description: "",
        id: 0,
        onEdit: true
      });
    }

    get getViewRows() : Array<any> {
      this.filteredRows = this.rows;
      const {end, start} = this.getPageShow;
      let newRows: Array<any> = [];
      let dataSource: Array<any> = this.rows ?? [];
      const email = this.filter ?? "";
      
      // Filter Rows if has email search
      if(email) {
        dataSource = [];
        for(let index = 0; index < this.rows.length; index++) 
          if(this.rows[index].email.includes(email)) dataSource.push(this.rows[index]);
      }
      
      // push data to show based on the current page and page to show
      if(start >= 0)
        for(let index = start; index < end; index++) newRows.push(dataSource[index]);

      return newRows;
    }

    get getPageShow() : any {
      const rowsAvailable : number = this.rows.length;
      const countRows = this.currentPage * this.perPage;
      const rowsToShow = countRows < rowsAvailable ? countRows : rowsAvailable;
      const diff = rowsToShow - this.perPage;
      let rowStart = diff;
      let rowEnd = rowsToShow;
      if(this.currentPage == 1 && rowsToShow > 0) {
        if(diff < 0) {
          rowStart = 0;
          rowEnd = rowsToShow;
        }
      }
      return {
        start: rowStart,
        end: rowEnd
      }
    }
    
    get getNoOfRows() : any {
      const hasFilter: boolean = this.filter ? true : false;
      const searchEmail: string = this.filter ?? "";
      let rowsAvailable : number = this.rows.length;
      if(hasFilter) {
        rowsAvailable = 0;
        for(let index = 0; index < this.rows.length; index++) {
          if(this.rows[index].email.includes(searchEmail)) rowsAvailable++;
        }
      }
      return {
        rowsAvailable,
        start: rowsAvailable ? this.getPageShow.start + 1 : 0,
      };
    }

    async created(): Promise<void>{
      this.Store = getModule(Module, this.$store);
      await this.Store.init();
      await this.getPartners();
    }
    
    roleChanged($event: any, row: any) {
      this.Store!.rowsToUpdate(row);
    }

    displayLoginInfo(modalID: any) {
      let modalElement = document.getElementById(`${modalID}`)
      modalElement?.classList?.remove('hide-login-info')
    }

    hideLoginInfo(modalID: any) {
      let modalElement = document.getElementById(`${modalID}`)
      modalElement?.classList?.add('hide-login-info')
    }
}
