import { Module, VuexModule, Mutation, Action, } from 'vuex-module-decorators';

@Module({name: 'dashboardStore'})
export default class DashboardStore extends VuexModule {

    user: any = {};
    stats: any = {};

    get userObject(){
        return this.user;
    }

    get dashboardStats(){
        return this.stats
    }

    @Mutation
    setUserObject(user: any){
        this.user = user;
    }

    @Mutation
    setDashboardStats(stats: any){
        this.stats = stats;
    }
}