import service from "../../../utils/http/apiClient";
import authUtils from "@/utils/authUtils";
import store from "@/store";

export const getUserProfile = async (): Promise<any> => {
    try{
        const response = await service.get("/syndication-user/account_me/");
        return response.data;
    } catch (err){
        const errorResponse = err.response.data.error;
        store.commit('setToast', {
            variant: 'danger',
            title: `Error Code: ${errorResponse.code}`,
            body: `${errorResponse.message}`
        });
    }
}

export const getEndSoonSnippets = async (params: {[key: string]: any}): Promise<any> => {
    try{
        const response = await service.get("/snippet/end_soon/", params);
        return response.data;
    } catch (err){
        store.commit('setToast', {
            variant: 'danger',
            title: 'Not Found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
    }
}

export const getStartSoonSnippets = async (params: {[key: string]: any}): Promise<any> => {
    try{
        const response = await service.get("/snippet/start_soon/", params);
        return response.data;
    } catch (err){
        store.commit('setToast', {
            variant: 'danger',
            title: 'Not Found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
    }
}

export const getBusinessUnits = async (): Promise<any> => {
    try{
        const result = await service.get('syndication/business-units/');
        return result.data;
    }catch (err){
        store.commit('setToast', {
            variant: 'danger',
            title: 'Server Unreachable',
            body: "Sorry, Server is Unreachable. Please contact your Administrator."
        })
    }
}

export const postBusinessUnit = async (payload: string): Promise<any> => {
    try{
        const result = await service.post('syndication/request-bu/', { business_unit: payload });
        
        store.commit('setToast', {
            variant: 'success',
            title: 'E-mail sent',
            body: "An email has been sent to the admin to update your business unit."
        })

    }catch (err){
        store.commit('setToast', {
            variant: 'danger',
            title: 'Server Unreachable',
            body: "Sorry, Server is Unreachable. Please contact your Administrator."
        })
    }
}