import axios from 'axios';
import {API_SERVER_URL} from "@/config";
import authUtils from "@/utils/authUtils";
import { getUserProfile } from "@/components/dashboard/utils";
import store from "./../../store";

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const httpClient = axios.create({
    withCredentials: true,
    baseURL: API_SERVER_URL,
    timeout: 30000
  });

async function getAuthHeader(){
    const token: any = await authUtils.refreshAccessTokenIfExpired();
    const authHeader = { Authorization: `Bearer ${token.access}` };

    return authHeader;
}

async function userRights(method: string){
    const profile = authUtils.getUserInfo();
    const grpNo = profile?.groups[0] ?? 4;
    const authHeader = await getAuthHeader();
    const userRight = await axios.get('/api/v1/syndication-user/account_me/', {
        headers: {
            ...authHeader
        }
      })
        .then((response) => {
            const profile = response.data;
            const userAccess = profile!.groups[0] ?? 4;

            return userAccess;
        });

    if(["post","put", "delete"].includes(method)) {
        try {
            if(profile == null) return true;
            if(grpNo != userRight) return false;
        } catch (error) {
            if(!localStorage.getItem("ms_token")) return false;
        }
    }

    return true;
}

// Add a request interceptor
httpClient.interceptors.request.use(async function (config) {
    const method: string = config?.method ?? ""
    const uri: string = config?.url ?? ""

    if (uri == "/api/v1/msal/login_url" && method == "get") return config;
    if (uri == "/api/v1/msal/login_with_code" && method == "post") return config;
    if (uri == "/server/logout" && method == "post") return config;
    if(!await userRights(method)) {
        await setTimeout(async function(){
            store.commit('setToast', {
                variant: 'warning',
                title: 'User rights changed!',
                body: "An administrator updated your settings, your page will reload."
            });
            
            authUtils.setUserInfo(await getUserProfile());
            window.location.reload()
        }, 1000)
    }
    else {
        const authHeader = await getAuthHeader();
        config.headers = { ...authHeader };
    
        return config;
    }
}, function (error) {
    return Promise.reject(error);
});

export default httpClient;