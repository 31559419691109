var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-modal",
    {
      attrs: {
        id: `original-summary-${_vm.id}`,
        "hide-footer": "",
        centered: "",
        size: "lg",
        title: "Original Summary",
      },
    },
    [
      _c("div", { staticClass: "d-block text-center" }, [
        _c("p", [_vm._v(_vm._s(_vm.originalSummary))]),
      ]),
      _c(
        "b-button",
        {
          staticClass: "mt-3 cs-red-btn",
          attrs: { block: "" },
          on: {
            click: function ($event) {
              return _vm.$bvModal.hide(`original-summary-${_vm.id}`)
            },
          },
        },
        [_vm._v("Close ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }