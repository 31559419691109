
import { Component, Prop, Vue } from 'vue-property-decorator'
import {getPartnerVFilterConstants} from "@/components/partners/utils";
import EditPartner from '../components/EditPartner.vue'
import {getModule} from "vuex-module-decorators";
import FilterModule from "@/store/Filter";

@Component({
  name: "ActiveCards",
  computed: {},
  components: { EditPartner }
})
export default class ActiveCards extends Vue {
  @Prop() public role: any | undefined
  @Prop({default: "" }) item: any;
  @Prop({}) isSearchResult: any;
  @Prop() readonly user: any | undefined

  public isUpdating: boolean = false;
  public partner: any = null;
  public snippetsLength:number = 0;

  filterStore = getModule(FilterModule, this.$store);

  private partnerTypes:any = null;

  async created(){
    let response = await getPartnerVFilterConstants()
    this.partnerTypes = response.results;
    this.partner = Object.assign({}, this.item);
    this.countSyndicatedSnippets();
  }

  countSyndicatedSnippets() {
    const DRAFT_STATE = 1
    const data = this.partner.partner_snippets.filter((partner:any) => {
      return partner.snippet.content_state != DRAFT_STATE
    })
    this.snippetsLength = data.length
  }

  getSyndicatedSnippets(id:string) {
    this.$router.push({name: 'Syndicated Live', params: { partnerId: id }})
  }

  get partnerType(): string{
    if(this.partnerTypes != null) {
      let typeList = this.partnerTypes.partner_type;
      let partnerType = typeList.find((i: any) => i.value == this.partner.partner_type)
      return partnerType.text;
    }

    return ""
  }

  get searchFlag(): boolean{
    return this.isSearchResult;
  }

  get searchNameArray(): Array<string> {
    let queries = this.filterStore.searchNameValue?.split(" ") ?? [];
    return queries;
  }

}
