var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "mchp-dashboard-heading mb-3" }, [
      _vm.fullName !== " "
        ? _c("h2", { staticClass: "mchp-dashboard-heading-name" }, [
            _vm._v(_vm._s(_vm.fullName)),
          ])
        : _c("h2", { staticClass: "mchp-dashboard-heading-name" }, [
            _vm._v("Unknown Name"),
          ]),
      _c("div", { staticClass: "mchp-dashboard-heading-gesture" }, [
        _vm._v(_vm._s(_vm.userBusinessUnitFromServer)),
      ]),
      _vm.jobTitle === ""
        ? _c("div", { staticClass: "mchp-dashboard-heading-title" }, [
            _vm._v(_vm._s(_vm.jobTitle || "Unknown Job Title")),
          ])
        : _vm._e(),
      _c("div", { staticClass: "mchp-dashboard-heading-email" }, [
        _vm._v(_vm._s(_vm.email || "No email")),
      ]),
    ]),
    _c("div", { staticClass: "mchp-content-container" }, [
      _vm.business_unit
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "mchp-card-container cs-dashboard-card-container",
              },
              [
                _vm._m(0),
                _c("div", { staticClass: "bu-item" }, [
                  _c("div", { staticClass: "bu-text" }, [
                    _vm._v(
                      "There are " + _vm._s(_vm.bu_stats.draft) + " in Drafts"
                    ),
                  ]),
                  _c("div", { staticClass: "bu-link" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function ($event) {
                            return _vm.fromDashboardLink($event, "Drafts")
                          },
                        },
                      },
                      [_vm._v("View Drafts")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "bu-item" }, [
                  _c("div", { staticClass: "bu-text" }, [
                    _vm._v(
                      "There are " + _vm._s(_vm.bu_stats.queued) + " in Queue"
                    ),
                  ]),
                  _c("div", { staticClass: "bu-link" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function ($event) {
                            return _vm.fromDashboardLink($event, "Queue")
                          },
                        },
                      },
                      [_vm._v("View Queue")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "bu-item" }, [
                  _c("div", { staticClass: "bu-text" }, [
                    _vm._v(
                      " There are " +
                        _vm._s(_vm.bu_stats.published) +
                        " in Currently Live "
                    ),
                  ]),
                  _c("div", { staticClass: "bu-link" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function ($event) {
                            return _vm.fromDashboardLink($event, "Live")
                          },
                        },
                      },
                      [_vm._v("View Currently Live")]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "bu-item" }, [
                  _c("div", { staticClass: "bu-text" }, [
                    _vm._v(
                      " There are " +
                        _vm._s(_vm.bu_stats.retired) +
                        " in No Longer Live "
                    ),
                  ]),
                  _c("div", { staticClass: "bu-link" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function ($event) {
                            return _vm.fromDashboardLink($event, "Finished")
                          },
                        },
                      },
                      [_vm._v("View No Longer Live")]
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "mchp-card-container cs-dashboard-card-container",
              },
              [
                _c(
                  "div",
                  { staticClass: "bu-date-summary" },
                  [_c("StartSoonBlock"), _c("EndSoonBlock")],
                  1
                ),
              ]
            ),
          ])
        : _c(
            "div",
            [
              _c(
                "b-row",
                { staticClass: "justify-content-md-center mt-4 mb-4" },
                [
                  _vm.userHasBURequest
                    ? _c(
                        "b-col",
                        {
                          staticClass: "text-center",
                          attrs: { lg: "8", md: "8", sm: "8" },
                        },
                        [
                          _c("p", { staticClass: "text-center" }, [
                            _c("b", [
                              _vm._v(
                                "Your business unit role assignment has been requested and sent to the Admin."
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  !_vm.userHasBURequest
                    ? _c(
                        "b-col",
                        {
                          staticClass: "text-center",
                          attrs: { lg: "8", md: "8", sm: "8" },
                        },
                        [
                          _c("p", { staticClass: "text-center" }, [
                            _c("b", [
                              _vm._v(
                                "You are not associated with a Business Unit yet."
                              ),
                            ]),
                          ]),
                          _c("span", { staticClass: "text-center" }, [
                            _vm._v(
                              "Let the admin know which Business Unit you are associated with."
                            ),
                          ]),
                          _c("hr", { staticClass: "my-4" }),
                        ]
                      )
                    : _vm._e(),
                  !_vm.userHasBURequest
                    ? _c(
                        "b-col",
                        { attrs: { col: "", lg: "8", md: "auto" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "mb-0",
                              attrs: { "label-for": "user-access" },
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "user-access",
                                  options: _vm.options,
                                },
                                model: {
                                  value: _vm.unitSelected,
                                  callback: function ($$v) {
                                    _vm.unitSelected = $$v
                                  },
                                  expression: "unitSelected",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-danger w-100 cs-red-btn mt-2 mb-2",
                              attrs: {
                                disabled: !_vm.unitSelected || _vm.isSubmit,
                              },
                              on: { click: _vm.onRequest },
                            },
                            [_vm._v("Submit")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h2", [_c("strong", [_vm._v("Business Unit Overview")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }