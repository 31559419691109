
import {Component, Vue} from 'vue-property-decorator';
import FilterBarPartner from "../../common/FilterBarPartner.vue";
import store from "./../../../store";

@Component({
  name: "Partners",
  components: {FilterBarPartner},
  computed: {
    user() {
      return store.getters.getUserGroup('partners')
    },
    userRole() {
      return store.getters.getUserRole
    }
  }
})
export default class Partners extends Vue {
  user!: any[]
  public dataList: any[] = []
  public fieldDisplay: string|any = ""

  mounted() {
    // Retain active class if reload page
    let navMenuItem = document.querySelector('#nav-partners .nav-link');
    navMenuItem?.classList.add('router-link-active')
  }

  searchBarDataList(payload : any, field : string) {
    this.dataList = payload!.results ?? [];
    this.fieldDisplay = field ?? ""
  }
  
  setActiveParent(): void {
    let navMenuItem = document.querySelector('#nav-partners .nav-link');
    navMenuItem?.classList.add('router-link-active');
  }

}
