var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "accordion" },
        [
          _c(
            "h3",
            { staticClass: "accordion-header" },
            [
              _c("EditableInput", {
                attrs: {
                  value: _vm.accordions.title,
                  editInput: _vm.editSection,
                },
                on: { update: _vm.updateSection },
              }),
              _vm.editable
                ? _c(
                    "button",
                    {
                      staticClass: "mchp-util-unstyledButton",
                      on: { click: _vm.editSectionContent },
                    },
                    [
                      _c("Icon", {
                        attrs: { name: _vm.editSection ? "cancel" : "edit" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable
                ? _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.modal-removeSection",
                          modifiers: { "modal-removeSection": true },
                        },
                        {
                          name: "b-modal",
                          rawName: "v-b-modal",
                          value: `remove-section-${_vm.accordions.id}`,
                          expression: "`remove-section-${accordions.id}`",
                        },
                      ],
                      staticClass: "mchp-util-unstyledButton",
                    },
                    [_c("Icon", { attrs: { name: "remove" } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._l(_vm.accordions.content, function (q) {
            return _c(
              "div",
              { key: q.id },
              [
                _c("AccordionItem", {
                  attrs: { accordionItem: q, editable: _vm.editable },
                  on: { updateItem: _vm.refreshSection },
                }),
              ],
              1
            )
          }),
          _c("div", { staticClass: "mchp-util-pushRight mb-4" }, [
            _vm.editable
              ? _c(
                  "button",
                  {
                    staticClass: "mchp-util-unstyledButton",
                    on: {
                      click: function ($event) {
                        return _vm.addQuestionAnswer(_vm.accordions.id)
                      },
                    },
                  },
                  [
                    _c("Icon", { attrs: { name: "add" } }),
                    _vm._v("Add a Question/Answer to this Section "),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: `remove-section-${_vm.accordions.id}`,
            centered: "",
            "hide-header": "",
            "hide-footer": "",
          },
        },
        [
          _c("div", { staticClass: "mchp-modalHeader" }, [
            _c(
              "button",
              {
                staticClass: "mchp-util-unstyledButton",
                on: {
                  click: function ($event) {
                    return _vm.$bvModal.hide(
                      `remove-section-${_vm.accordions.id}`
                    )
                  },
                },
              },
              [
                _c("Icon", {
                  attrs: { name: "cancel", height: "20", width: "20" },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "py-2 px-3" },
            [
              _c("h3", { staticClass: "font-weight-bold" }, [
                _vm._v("Delete FAQ Section"),
              ]),
              _c("p", { staticClass: "my-4" }, [
                _vm._v(
                  " Removing this section will remove all question/answers contained inside it. "
                ),
              ]),
              _c(
                "b-button",
                {
                  staticClass: "mb-2 btn mchp-deleteBtn",
                  on: {
                    click: function ($event) {
                      return _vm.removeSection(_vm.accordions.id)
                    },
                  },
                },
                [_vm._v("Delete FAQ Section")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }