<template>
    <b-container>
      <b-row class="mb-2" align-h="between">
        <b-col lg="6" class="mr-0 mb-2">
          <b-row v-if="editSnippetImage" class="d-block mb-2">
            <h4>Original Image</h4>
            <div>
              <b-img :src="snippetOriginalImage" center thumbnail fluid class="snippet-image" alt="Image">
              </b-img>
            </div>
          </b-row>
          <b-row class="d-block">
            <h4>Current Image</h4>
            <div v-if="snippetImage">
              <b-img :src="snippetImage" center thumbnail fluid class="snippet-image" alt="Image">
              </b-img>
            </div>
            <div v-else class="d-flex justify-content-center">
              <b-icon
                icon="card-image"
                variant="secondary"
                font-scale="15"
                class="snippet-image"
                style="cursor: pointer; outline: none !important;">
              </b-icon>
            </div>
          </b-row>
        </b-col>
        <b-col lg="5" class="ml-auto">
          <b-row class="mb-2">
            <b-input-group>
              <b-form-input
                v-model="imageSearchTerm"
                placeholder="Search Images"
                @keyup.enter="getImageOptions(true, true)"
                :disabled="searchDisable">
              </b-form-input>
              <b-input-group-append>
                <b-button v-if="!imageSearched" variant="info" @click="getImageOptions(false, true)">
                  <b-icon icon="search"></b-icon>
                </b-button>
                <b-button v-else variant="info" @click="clearSearch()" :disabled="searchDisable">
                  <b-icon icon="x"></b-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-row>
          <b-row class="list-content-row">
            <div v-if="this.imageOptions">
              <b-list-group @scroll="imageOptionScroll">
                <b-list-group-item
                  button
                  v-for="(imageOption, index) in this.imageOptions"
                  @click="selectImage(imageOption)"
                  :key="index">
                  <b-container>
                    <b-row align-h="center" align-v="center">
                      <b-col md="5">
                        <b-img
                          :src="imageOption.path"
                          thumbnail
                          alt="Image"
                          class="rounded-0">
                        </b-img>
                      </b-col>
                      <b-col md="7">
                        <b-row class="mb-2">
                          <b-col cols="4" class="text-sm-right"><b>Date:</b></b-col>
                          <b-col cols="8" class="text-break">{{ imageOption.date }}</b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="4" class="text-sm-right"><b>Name:</b></b-col>
                          <b-col cols="8" class="text-break">{{ imageOption.name }}</b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-list-group-item>
                <b-list-group-item v-if="this.loading" class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div v-else-if="this.loading" class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Loading...</strong>
            </div>
            <div v-else class="text-center text-info my-2">
              <span v-if="!this.imageSearchTerm" class="text-danger">Fill out the search field to start.</span>
              <span v-if="this.imageSearched">No results found</span>
            </div>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="modal-footer">
        <b-button class="mr-auto p-2" variant="secondary" @click="closeModal()">Cancel</b-button>
        <b-button
          class="ml-auto p-2"
          variant="primary"
          :disabled="this.saveButtonDisable"
          @click="saveImage()">
          Save
        </b-button>
      </b-row>
    </b-container>
</template>

<script>
import store from "@/store";
import {
  getSnippetImageOptions,
} from "@/components/syndications/utils";

export default {
  name: 'EditImage',

  props: [
    'propEditSnippetImage',
    'propSnippetId',
    'propSnippetImageData'
  ],

  data() {
    return {
      loading: false,
      searchDisable: false,
      saveButtonDisable: true,
      editSnippetImage: this.propEditSnippetImage,
      snippetId: this.propSnippetId,
      snippetImageId: this.propSnippetImageData.id ?? null,
      snippetImage: this.propSnippetImageData.path ?? null,
      snippetOriginalImage: this.propSnippetImageData.original_path ?? null,
      snippetCurrentImage: this.propSnippetImageData.path ?? null,
      selectedImageData: null,
      imageOptions: null,
      imageOptionPageNo: 1,
      imageOptionTotalPages: null,
      imageSearched: false,
      imageSearchTerm: null
    }
  },

  created() {
    // Copy to snippet image if snippet original image is empty
    this.snippetOriginalImage = this.snippetOriginalImage !== ''
      ? this.snippetOriginalImage
      : this.snippetImage;
    // this.getImageOptions(true); // For default retrieval. Also update input to onload = true
  },

  methods: {
    imageOptionScroll(event) {
      let imageOptions = event.target;

      if (
        // Check if loading
        !this.loading
        // Check scroll if at the bottom
        && Math.floor(imageOptions.scrollTop + imageOptions.offsetHeight) >= imageOptions.scrollHeight - 1
        // Check if already passed the total pages
        && this.imageOptionPageNo < this.imageOptionTotalPages
      ) {
        // Append new image options
        this.getImageOptions(true, true, true);
      }
    },

    clearSearch() {
      this.imageOptions = null;
      this.imageSearched = false;
      this.imageSearchTerm = null;
      // Get default search
      // this.getImageOptions(true, true); // For default retrieval
    },

    setAxiogConfig(onload, searched, nextPage) {
      let params = {};
      let config = {};

      // For clear search button
      if (this.imageSearchTerm && searched) {
        this.imageSearched = searched;
        // Add parameter for search term
        params['_search'] = this.imageSearchTerm

        // Clear image options for new results
        if (!nextPage) {
          this.imageOptions = null;
        }
      } else if (!onload) {
        // Don't search if empty search term
        return false;
      } else {
        this.imageSearched = false;
      }

      // Get next page when users scroll to the bottom
      if (nextPage) {
        this.imageOptionPageNo++

        // Get only pages greater than 1
        if (this.imageOptionPageNo > 1) {
          params['_page'] = this.imageOptionPageNo
        }
      } else {
        // Reset pages
        this.imageOptionPageNo = 1;
        this.imageOptionTotalPages = null;
      }

      if (params) {
        config['params'] = {
          "_": btoa(JSON.stringify(params))
        };
      }

      return config;
    },

    async getImageOptions(onload = false, searched = false, nextPage = false) {
      // Configuration for the request to AEM
      let config = this.setAxiogConfig(onload, searched, nextPage);

      if (config) {
        // Disable for avoiding multiple requests
        if (onload && searched) {
          this.searchDisable = true;
        }
        // Display spinner
        this.loading = true

        // Request to AEM to retrieve optional images
        let response = await getSnippetImageOptions(config['params']);

        if (response && 'items' in response) {
          // Get total pages
          this.imageOptionTotalPages = response.pages;
          // Apply the domain for the images
          this.imageOptions = !nextPage
            ? this.filterImageOption(response.items)
            // Append new page
            : this.imageOptions.concat(this.filterImageOption(response.items));
        } else {
          store.commit('setToast', {
              variant: 'danger',
              title: 'Not Found',
              body: response && 'error' in response
                ? response.error.join(' ')
                : 'Unable to retieve image options.'
          });
        }
        this.loading = false;
        this.disableSearch();
      }
    },

    filterImageOption(imageOptions) {
      return imageOptions
        .filter((item) => {
          // Remove unnecessary files from list
          const filteredFiles = [
            'mp4'
          ];

          return !filteredFiles.includes(item.path.split('.').pop());
        });
    },

    disableSearch() {
      setTimeout(() => {
          this.searchDisable = false
        },
        6000
      )
    },

    selectImage(imageOption) {
      // Update current snippet image
      this.snippetImage = imageOption.path;
      this.selectedImageData = imageOption
      this.saveButtonDisable = false;

      // Don't allow to save if current image is the same one
      if (this.snippetCurrentImage == imageOption.path) {
        this.saveButtonDisable = true;
      }
    },

    closeModal() {
      const addOrEdit = this.editSnippetImage ? '-edit' : '-add';
      const kwargs = {
        modalId: this.snippetId + addOrEdit +'-snippet-image'
      };
      this.$emit('closeModal', kwargs);
    },

    saveImage() {
      const addOrEdit = this.editSnippetImage ? '-edit' : '-add';

      if (this.editSnippetImage) {
        this.$emit('updateSnippetImage', {
          modalId: this.snippetId + addOrEdit + '-snippet-image',
          snippetId: this.snippetId,
          snippetImageId: this.snippetImageId,
          selectedImageData: this.selectedImageData
        });
      } else {
        this.$emit('addSnippetImage', {
          modalId: this.snippetId + addOrEdit + '-snippet-image',
          snippetId: this.snippetId,
          selectedImageData: this.selectedImageData
        });
      }
    }
  }
}
</script>

<style scoped>
.snippet-image {
  height: calc(55vh - 225px);
}

.list-content-row {
  display: flow-root;
}

.list-group {
  height: calc(92vh - 225px);
  overflow-y: auto;
}

.list-group::-webkit-scrollbar {
  display: none;
}

.modal-footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid #dee2e6;
  padding-top: 10px;
  padding-bottom: 0;
}
</style>
