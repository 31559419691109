import Vue from 'vue';
import Vuex from 'vuex';
import Login from '@/components/login/store';
import SyndicationStore from "@/components/syndications/store";
import DashboardStore from "@/components/dashboard/store";
import FaqStore from "@/components/faq/store";
import UserSettings from "@/components/usersettings/store";
import FilterModule from "@/store/Filter";
import {default as userAccess} from "./usermodules.json";
import service from "../utils/http/apiClient";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    toastParams: {},
    isLoggedIn: false,
    ms_auth_token: "",
    userGroup: {},
    userRole: 0
  },
  mutations: {
    setToast(state, payload) {
      state.toastParams = payload
    },
    setUserGroup(state, payload) {
      state.userGroup = payload;
    },
    setUserRole(state, payload) {
      state.userRole = payload;
    }
  },
  getters: {
    getToastValue(state) {
      return state.toastParams;
    },
    getUserGroup: (state) => (payload: any) =>  {
      return state.userGroup[payload];
    },
    getUserRole(state) {
      return state.userRole;
    }
  },
  actions: {
    async userAccess({commit}) {
      try {
        const role = JSON.parse(localStorage.getItem("userInfo") ?? "" )["groups"][0];
        const grpNo = JSON.parse(localStorage.getItem("userInfo") ?? "" )["groups"][0] ?? 4;
        const response = await service.get(`syndication/groups/`);
        const group = response.data.groups.filter((row: any) => row.id == grpNo).pop();

        commit("setUserGroup", userAccess[group.name])
        commit("setUserRole", role)
      } catch (err) {
          commit('setToast', {
              variant: 'danger',
              title: 'Not Found',
              body: "Sorry, Unable to connect to server. Please contact your administrator."
          })
      }
    }
  },
  modules: {
    Login,
    SyndicationStore,
    DashboardStore,
    FaqStore,
    FilterModule,
    UserSettings
  }
});