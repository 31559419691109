import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import {getUserAccess, getBusinessUnits, getSyndicationUsers, updateUsersDetails} from "./../utils"
import { DateTime } from 'luxon';
import store from "./../../../store";

interface Person {
  id: number,
  name: string,
  email: string,
  user_access: number,
  business_unit: string,
  login_info: {
    last_login: Date 
  }
}

@Module({ namespaced: true, name: 'UserSettings' })
export default class UserSettings extends VuexModule {
  private accessOptions: any = null
  private unitOptions: any = null
  private itemRows: any = null
  private updatedRows: Array<any> = [];
  private isLoading: boolean = false;
  private isFormDisabled: boolean = false;

  @Mutation
  setLoading(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setFormDisabled(payload: boolean) {
    this.isFormDisabled = payload;
  }

  @Mutation
  setUserAccess(payload: any) {
    this.accessOptions = payload;
  }
  
  @Mutation
  setBusinessUnits(payload: any) {
    this.unitOptions = payload;
  }
  
  @Mutation
  setTableRows(payload: any) {
    this.itemRows = payload;
  }

  @Action({rawError: true})
  async init(): Promise<void> {
    this.context.commit("reset")
    this.context.commit("setLoading", true);
    const [acess, units, items] = await Promise.all([
      getUserAccess(), 
      getBusinessUnits(), 
      getSyndicationUsers() 
    ]);
    
    await this.context.commit("setUserAccess", acess);
    await this.context.commit("setBusinessUnits", units);

    const rows = items ?? [];
    await this.context.commit("setTableRows", rows);
    this.context.commit("setLoading", false);
  }


  @Mutation
  setUpdatedRow(payload: Person) {
    this.updatedRows.push(payload);
  }

  @Mutation
  removeUpdatedRow(payload: Person) {
    const newRows = this.updatedRows.filter(row => payload.id != row.id);
    this.updatedRows = newRows;
  }

  @Action({commit: 'setUpdatedRow'})
  async rowsToUpdate(payload: any): Promise<void> {
    if(!this.updatedRows.length) return payload;
    
    const found = this.updatedRows.filter(row => row.id == payload.id).shift() ?? false;
    if(found) this.context.commit("removeUpdatedRow", found);
    
    return payload;
  }

  @Mutation
  reset() {
    this.updatedRows = [];
  }

  @Action({rawError: true})
  async errMessage(payload: any): Promise<void> {
    store.commit('setToast', payload)
  }

  @Action({rawError: true})
  async onUpdate(): Promise<void> {
    this.context.commit("setFormDisabled", true);
    if(this.updatedRows.length) {
      await updateUsersDetails(this.itemRows, this.updatedRows);
      this.context.commit("reset")
      await this.context.dispatch('init');
    }
    else {
      store.commit('setToast', {
        variant: 'info',
        title: 'Nothing to update',
        body: "There are no users status updated."
      })
    }
    this.context.commit("setFormDisabled", false);
  }

  get fnAccessOptions() { 
    return this.accessOptions.map((option: any) => {
      return {
        value: option.id,
        text: option.name
      }
    })
  }
  get fnUnitOptions(){ 
    const list: Array<any> = [];
    for (const key in this.unitOptions) {
      list.push({
        value: key,
        text: this.unitOptions[key].title
      })
    }
    
    return list;
  }

  get fnRows() { 
    const rows = this.itemRows ?? [];
    const users: Array<any> = [];
    for(let i = 0; i < rows.length; i++) {
      users.push({
        "id": rows[i].id,
        "name": `${rows[i].first_name} ${rows[i].last_name}`,
        "email": rows[i].email,
        "user_access": rows[i].groups[0],
        "business_unit": rows[i]?.profile?.business_unit?.toLocaleLowerCase() ?? "",
        "login_info": {
          "last_login": DateTime.fromISO(rows[i].last_login).toLocaleString(DateTime.DATETIME_MED)
        },
        "access_updated_by": rows[i]?.profile?.access_updated_by ?? "",
        "last_action": DateTime.fromISO(rows[i]?.profile?.last_action).toLocaleString(DateTime.DATETIME_MED),
      });
    }

    return users 
  }

  get fnFields() {
    return [
      { key: 'name', sortable: true, thStyle: { width: "20%" } },
      { key: 'email', sortable: true, thStyle: { width: "40%" }  },
      { key: 'user_access', sortable: false },
      { key: 'business_unit', sortable: false },
      { key: 'login_info', sortable: false },
    ]
  }

  get fnUpdatedRows() {
    return this.updatedRows;
  }

  get fnLoadingState() {
    return this.isLoading;
  }

  get fnFormState() {
    return this.isFormDisabled;
  }
}