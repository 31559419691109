var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "cs-add-partner-container" },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { md: "1" } }),
              _c("b-col", { attrs: { md: "10" } }, [
                _c(
                  "form",
                  {
                    attrs: { method: "post" },
                    on: { submit: _vm.checkAddPartnerForm },
                  },
                  [
                    _c("h1", { staticClass: "mt-5" }, [
                      _vm._v("Add a Partner"),
                    ]),
                    _vm.errors.length
                      ? _c("div", { staticClass: "cs-error-info" }, [
                          _c("b", [
                            _vm._v("Please correct the following error(s):"),
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function (error) {
                              return _c("li", { key: error.id }, [
                                _vm._v(_vm._s(error)),
                              ])
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0",
                        attrs: {
                          label: "Company Name*",
                          "label-for": "company-name",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isShowCompany,
                                expression: "isShowCompany",
                              },
                            ],
                            staticClass: "company-error-note",
                          },
                          [_vm._v(_vm._s(_vm.companyErrorNote))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "cs-company-list",
                            on: {
                              click: function ($event) {
                                return _vm.showCompanyDropdown()
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "cs-content-group" }, [
                              _c("span", { staticClass: "cs-company-value" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.changeDataset.company_name) +
                                    " "
                                ),
                              ]),
                              _c("span", { staticClass: "dropup" }, [
                                _c("span", { staticClass: "caret-group" }, [
                                  _c("span", { staticClass: "caret-up" }),
                                  _c("span", { staticClass: "caret-down" }),
                                ]),
                              ]),
                            ]),
                            _c(
                              "ul",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isActiveContainer,
                                    expression: "isActiveContainer",
                                  },
                                ],
                                staticClass: "cs-company-dropdown",
                              },
                              _vm._l(_vm.companyDropdown, function (item) {
                                return _c("li", { key: item.id }, [
                                  _c(
                                    "span",
                                    {
                                      class: item.disabled ? "disabled" : "",
                                      on: {
                                        click: function ($event) {
                                          item.disabled
                                            ? _vm.showError(item.text)
                                            : _vm.getCompanyValue(item.text)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.text))]
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0",
                        attrs: { label: "Country*", "label-for": "country" },
                      },
                      [
                        _c("b-form-select", {
                          staticClass: "mb-4",
                          attrs: {
                            required: true,
                            id: "country",
                            options: _vm.newCountryList,
                          },
                          model: {
                            value: _vm.changeDataset.country,
                            callback: function ($$v) {
                              _vm.$set(_vm.changeDataset, "country", $$v)
                            },
                            expression: "changeDataset.country",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0",
                        attrs: { label: "Address *", "label-for": "address-1" },
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "mb-3",
                          attrs: { id: "address-1", required: true },
                          model: {
                            value: _vm.changeDataset.address_1,
                            callback: function ($$v) {
                              _vm.$set(_vm.changeDataset, "address_1", $$v)
                            },
                            expression: "changeDataset.address_1",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0",
                        attrs: {
                          label: "Company URL*",
                          "label-for": "company-url",
                        },
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "mb-3",
                          attrs: {
                            id: "company-url",
                            placeholder: "http://",
                            required: true,
                          },
                          model: {
                            value: _vm.changeDataset.company_url,
                            callback: function ($$v) {
                              _vm.$set(_vm.changeDataset, "company_url", $$v)
                            },
                            expression: "changeDataset.company_url",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("hr"),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0",
                        attrs: {
                          label: "Contact Name*",
                          "label-for": "contact-name",
                        },
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "mb-3",
                          attrs: { id: "contact-name", required: true },
                          model: {
                            value: _vm.contact.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.contact, "name", $$v)
                            },
                            expression: "contact.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mb-0",
                                attrs: {
                                  label: "Email Address*",
                                  "label-for": "email-address",
                                },
                              },
                              [
                                _c("b-form-input", {
                                  staticClass: "mb-3",
                                  attrs: {
                                    id: "email-address",
                                    required: true,
                                  },
                                  model: {
                                    value: _vm.contact.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.contact, "email", $$v)
                                    },
                                    expression: "contact.email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mb-0",
                                attrs: {
                                  label: "Phone Number*",
                                  "label-for": "phone-number",
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.contact.phone_number,
                                      expression: "contact.phone_number",
                                    },
                                  ],
                                  staticClass: "mb-3 form-control",
                                  attrs: { id: "phone-number", required: "" },
                                  domProps: { value: _vm.contact.phone_number },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.contact,
                                        "phone_number",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("hr"),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0",
                        attrs: {
                          label: "Microchip Owner*",
                          "label-for": "cs-owner",
                        },
                      },
                      [
                        _c("b-form-select", {
                          staticClass: "mb-4",
                          attrs: {
                            id: "cs-owner",
                            options: _vm.ownersID,
                            required: "",
                          },
                          model: {
                            value: _vm.changeDataset.user_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.changeDataset, "user_id", $$v)
                            },
                            expression: "changeDataset.user_id",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mb-0",
                                attrs: {
                                  label: "Type of Partner*",
                                  "label-for": "partner-type",
                                },
                              },
                              [
                                _c("b-form-select", {
                                  staticClass: "mb-4",
                                  attrs: {
                                    id: "partner-type",
                                    options: _vm.filterPartnerTypes,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.changeDataset.partner_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.changeDataset,
                                        "partner_type",
                                        $$v
                                      )
                                    },
                                    expression: "changeDataset.partner_type",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mb-0",
                                attrs: {
                                  label: "Max number of live snippets*",
                                  "label-for": "max-snippet-number",
                                },
                              },
                              [
                                _c("b-form-select", {
                                  staticClass: "mb-3",
                                  attrs: {
                                    id: "max-snippet-number",
                                    options: _vm.snippetLimitOptions,
                                    disabled: _vm.role === 2 ? true : false,
                                  },
                                  model: {
                                    value: _vm.changeDataset.snippet_limit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.changeDataset,
                                        "snippet_limit",
                                        $$v
                                      )
                                    },
                                    expression: "changeDataset.snippet_limit",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "cs-agreement-container-note" }, [
                      _c("i", [
                        _vm._v(
                          "Please upload the signed Partner Agreement to the Terms and Condition Content Fragment in AEM."
                        ),
                      ]),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-danger w-100 cs-red-btn mt-4 mb-4 pt-2 pb-2",
                        attrs: {
                          disabled:
                            _vm.changeDataset.company_name === ""
                              ? true
                              : false,
                          type: "submit",
                        },
                      },
                      [_vm._v("Save")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("b-col", { attrs: { md: "1" } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }