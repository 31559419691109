
import {Component, Vue} from 'vue-property-decorator';
import FilterBarPartnerSyndication from "../../common/FilterBarPartnerSyndication.vue";
import {getPartnerSnippetsFromServer} from "@/components/partners/utils";
import store from "./../../../store";

@Component({
  name: "Partners",
  components: {FilterBarPartnerSyndication},
  computed: {
    user() {
      return store.getters.getUserGroup('syndications')
    }
  }
})
export default class SyndicatedSnippets extends Vue {
  public partner:any = null;
  public company:string = "";
  public partnerId:number = parseInt(this.$route.params.partnerId);

  async mounted() {
    // Retain active class if reload page
    let navMenuItem = document.querySelector('#nav-partners .nav-link');
    navMenuItem?.classList.add('router-link-active')

    this.partner = await getPartnerSnippetsFromServer(this.partnerId);
    this.company = this.partner.company_name
  }

  get companyName() {
    let navMenuItem = document.querySelector('#nav-partners .nav-link');
    navMenuItem?.classList.add('router-link-active')
    return this.company
  }
  
  setActiveParent(): void {
    let navMenuItem = document.querySelector('#nav-partners .nav-link');
    navMenuItem?.classList.add('router-link-active');
  }
}
