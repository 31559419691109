var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("b-col", { staticClass: "cs-category-title", attrs: { md: "10" } }, [
        _c("h3", [_c("b", [_vm._v("Partners")])]),
      ]),
      _c("b-tabs", {
        staticClass: "tab-container",
        scopedSlots: _vm._u([
          {
            key: "tabs-end",
            fn: function () {
              return [
                _c(
                  "b-nav-item",
                  {
                    staticClass: "cs-nav-item-inner",
                    attrs: {
                      href: "#",
                      role: "Active",
                      to: { name: "Active" },
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setActiveParent()
                      },
                    },
                  },
                  [_c("h5", [_vm._v("Active")])]
                ),
                _c(
                  "b-nav-item",
                  {
                    staticClass: "cs-nav-item-inner",
                    attrs: {
                      href: "#",
                      role: "Inactive",
                      to: { name: "Inactive" },
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setActiveParent()
                      },
                    },
                  },
                  [_c("h5", [_vm._v("Inactive")])]
                ),
                _vm.user.canAdd
                  ? _c(
                      "b-nav-item",
                      {
                        staticClass: "cs-nav-item-inner",
                        attrs: {
                          href: "#",
                          role: "Add a Partner",
                          to: { name: "Add Partner" },
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setActiveParent()
                          },
                        },
                      },
                      [_c("h5", [_vm._v("Add a Partner")])]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "w-100" }),
      _c("FilterBarPartner", {
        attrs: {
          id: "cs-bar-filter",
          dataList: _vm.dataList,
          fieldDisplay: _vm.fieldDisplay,
        },
      }),
      _c(
        "b-container",
        {
          staticStyle: { "background-color": "#F1F2F2" },
          attrs: { fluid: "" },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "mb-3 p-0" },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "cs-card-container",
                      attrs: { "no-body": "" },
                    },
                    [
                      _c(
                        "b-card-body",
                        { staticClass: "p-0" },
                        [
                          _c("router-view", {
                            attrs: { user: _vm.user, role: _vm.userRole },
                            on: { searchBarDataList: _vm.searchBarDataList },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }