
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  name: "ModalOriginalSummary",
})
export default class ModalOriginalSummary extends Vue {
  private originalSummary: any | string = "";
  private id: any | number = 0;

  public onShowModal(payload: any){
    this.$nextTick(function () {
      this.originalSummary = payload.text;
      this.id = payload.id;
      this.$nextTick(function () {
        this.$bvModal.show(`original-summary-${this.id}`);
      })
    })
  }
}
