var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      staticClass: "sparkline",
      attrs: {
        viewBox: `0 0 ${_vm.width} ${_vm.height}`,
        "stroke-width": _vm.stroke,
        "stroke-linejoin": "round",
      },
    },
    [_c("path", { attrs: { d: _vm.shape, fill: "none" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }