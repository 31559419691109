var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mchp-main-tab-container mb-5" },
    [
      _c("b-col", { staticClass: "ml-md-auto mr-md-auto" }, [
        _c(
          "div",
          { staticClass: "mchp-app-heading" },
          [
            _c("router-link", { attrs: { to: { name: "Dashboard" } } }, [
              _c("img", {
                attrs: {
                  src: require("../assets/images/logo.png"),
                  alt: "Microchip logo",
                },
              }),
            ]),
            _c("div", { staticClass: "mchp-app-heading-list" }, [
              _c(
                "div",
                { staticClass: "mchp-app-heading-item", attrs: { href: "#" } },
                [_vm._v(" Welcome! " + _vm._s(_vm.fullName) + " ")]
              ),
              _c("div", { staticClass: "mchp-app-heading-separator" }, [
                _vm._v("|"),
              ]),
              _c(
                "div",
                {
                  staticClass: "mchp-app-heading-item",
                  on: { click: _vm.logoutAction },
                },
                [_vm._v("Logout")]
              ),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "mchp-app-container ml-md-auto mr-md-auto" },
        [
          _c(
            "b-nav",
            {
              staticClass: "mchp-side-nav",
              attrs: { vertical: "", id: "mchp-side-nav" },
            },
            [
              _c(
                "b-nav-item",
                {
                  staticClass: "mchp-side-nav-item",
                  attrs: { to: { name: "Dashboard" }, id: "nav-dashboard" },
                  on: {
                    mouseup: function ($event) {
                      return _vm.resetActiveNavClasses("dashboard")
                    },
                  },
                },
                [
                  _c(
                    "h6",
                    [
                      _c("Icon", { attrs: { name: "dashboard" } }),
                      _vm._v(" Dashboard "),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "li",
                {
                  staticClass: "mchp-side-nav-item",
                  attrs: { id: "nav-syndications" },
                  on: {
                    mouseup: function ($event) {
                      return _vm.resetActiveNavClasses("syndications")
                    },
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      on: {
                        click: function ($event) {
                          return _vm.fromSideNavLink($event, "Drafts")
                        },
                      },
                    },
                    [
                      _c(
                        "h6",
                        [
                          _c("Icon", { attrs: { name: "syndication" } }),
                          _vm._v(" Syndications "),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "b-nav-item",
                {
                  staticClass: "mchp-side-nav-item",
                  attrs: { to: { name: "Active" }, id: "nav-partners" },
                  on: {
                    mouseup: function ($event) {
                      return _vm.resetActiveNavClasses("partners")
                    },
                  },
                },
                [
                  _c(
                    "h6",
                    [
                      _c("Icon", { attrs: { name: "partners" } }),
                      _vm._v(" Partners "),
                    ],
                    1
                  ),
                ]
              ),
              _vm.grpNo == 1
                ? _c(
                    "b-nav-item",
                    {
                      staticClass: "mchp-side-nav-item",
                      attrs: {
                        to: { name: "User Settings" },
                        id: "nav-user-settings",
                      },
                      on: {
                        mouseup: function ($event) {
                          return _vm.resetActiveNavClasses("user-settings")
                        },
                      },
                    },
                    [
                      _c(
                        "h6",
                        [
                          _c("Icon", { attrs: { name: "settings" } }),
                          _vm._v(" Admin Settings "),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "b-nav-item",
                {
                  staticClass: "mchp-side-nav-item",
                  attrs: { to: { name: "Faq" }, id: "nav-faq" },
                  on: {
                    mouseup: function ($event) {
                      return _vm.resetActiveNavClasses("faq")
                    },
                  },
                },
                [
                  _c(
                    "h6",
                    [
                      _c("Icon", { attrs: { name: "info" } }),
                      _vm._v(" FAQ/Help "),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c("router-view"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }