var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("MakeToast"),
      _c(
        "b-container",
        { staticClass: "app-container", attrs: { fluid: "" } },
        [_c("router-view")],
        1
      ),
      _c("div", { staticClass: "copyright" }, [
        _vm._v(
          "© Copyright 1998-" +
            _vm._s(new Date().getFullYear()) +
            " Microchip Technology Inc. All rights reserved."
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }