import { render, staticRenderFns } from "./SyndicatedSnippets.vue?vue&type=template&id=3511dc7a&scoped=true&"
import script from "./SyndicatedSnippets.vue?vue&type=script&lang=ts&"
export * from "./SyndicatedSnippets.vue?vue&type=script&lang=ts&"
import style0 from "./SyndicatedSnippets.vue?vue&type=style&index=0&id=3511dc7a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3511dc7a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/frontvue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3511dc7a')) {
      api.createRecord('3511dc7a', component.options)
    } else {
      api.reload('3511dc7a', component.options)
    }
    module.hot.accept("./SyndicatedSnippets.vue?vue&type=template&id=3511dc7a&scoped=true&", function () {
      api.rerender('3511dc7a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/partners/views/SyndicatedSnippets.vue"
export default component.exports