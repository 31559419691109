var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { staticClass: "mt-4 mb-4", attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { attrs: { "align-v": "center" } },
        [
          _c(
            "b-col",
            { attrs: { md: "10" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("label", { attrs: { for: "input-live" } }, [
                        _vm._v("Search"),
                      ]),
                      _c(
                        "b-input-group",
                        {
                          staticClass: "mb-2",
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function () {
                                return [
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass:
                                        "cs-syndicator-filter-icon-group",
                                    },
                                    [
                                      _c("b-icon", {
                                        attrs: { icon: "search" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass:
                                        "cs-partner-close-icon-group hide",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clearPreventDefault(
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("X")]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "cs-syndication-filter-search-term",
                              type: "text",
                              placeholder: "Enter term",
                            },
                            on: {
                              keyup: [
                                function ($event) {
                                  return _vm.hideSearchIcon()
                                },
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.submitSearch.apply(null, arguments)
                                },
                              ],
                            },
                            model: {
                              value: _vm.filledSearchTerm,
                              callback: function ($$v) {
                                _vm.filledSearchTerm = $$v
                              },
                              expression: "filledSearchTerm",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("label", { attrs: { for: "input-live" } }, [
                        _vm._v("Filter by Content Type"),
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.contentTypeOptions },
                            on: { change: _vm.submitContentType },
                            model: {
                              value: _vm.selectedContentTypeFilter,
                              callback: function ($$v) {
                                _vm.selectedContentTypeFilter = $$v
                              },
                              expression: "selectedContentTypeFilter",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("label", { attrs: { for: "input-live" } }, [
                        _vm._v("Business Type"),
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "mb-2" },
                        [
                          _c("b-form-select", {
                            staticClass: "cs-bu-select",
                            attrs: { options: _vm.businessUnitsOptions },
                            on: { change: _vm.submitBusinessUnit },
                            model: {
                              value: _vm.selectedBusinessUnit,
                              callback: function ($$v) {
                                _vm.selectedBusinessUnit = $$v
                              },
                              expression: "selectedBusinessUnit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticStyle: { top: "12px", "text-align": "center" },
              attrs: { md: "2", right: "" },
            },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.top",
                      value: "Clear Filters",
                      expression: "'Clear Filters'",
                      modifiers: { hover: true, top: true },
                    },
                  ],
                  attrs: { variant: "default" },
                  on: { click: _vm.resetFilter },
                },
                [
                  _c(
                    "a",
                    { staticClass: "clear-filter", attrs: { href: "#" } },
                    [_vm._v("Clear Filters")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }