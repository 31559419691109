var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mt-5 pt-5" }, [
    _c("div", { staticClass: "container h-100 mt-5 pt-5" }, [
      _c("div", { staticClass: "d-flex justify-content-center h-100" }, [
        _c("div", { staticClass: "user_card" }, [
          _vm._m(0),
          _c("div", { staticClass: "mt-4" }, [
            _c("div", { staticClass: "d-flex justify-content-center links" }, [
              _c(
                "div",
                [
                  _c(
                    "b-button",
                    { attrs: { variant: "success" }, on: { click: _vm.auth } },
                    [_vm._v("Sign In via AAD")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c("div", { staticClass: "brand_logo_container" }, [
        _c("img", {
          staticClass: "brand_logo",
          attrs: {
            src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8ZOIOvP37ZqTt1qUs_E-WgWDsr1jFf06O6rCAm0ccPE4Y24Lzhe0zqYCij2V8p9ZRtMY&usqp=CAU",
            alt: "Logo",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }