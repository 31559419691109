
import {Component, Vue, Prop} from 'vue-property-decorator'
import {getSnippetDetailFromServer} from "@/components/syndications/utils";
import store from "@/store";

@Component({
  name: "ElemURL"
})
export default class ElemURL extends Vue {
  @Prop({default: "Snippet URL"}) readonly urlString!: string;
  @Prop({default: null}) readonly snippetID!: number;

  public copyToClipboard(): void {
    this.getCampaignId();
  }

  public convertUtmMedium(contentType: string): string {
    return contentType
      .split('')
      .map((character) => {
        if (character == character.toUpperCase()) {
          return '-' + character.toLowerCase();
        } else {
          return character;
        }
      })
      .join('');
  }

  async getCampaignId() {
    const snippetDetail = await getSnippetDetailFromServer(this.snippetID);
    const queryParams = {
      'utm_campaign': snippetDetail.ga_campaign_id ?? '',
      'utm_source': '',
      'utm_medium': this.convertUtmMedium(snippetDetail.content_type),
      'utm_bu': snippetDetail.business_unit ?? '',
      'utm_content': 'content-syndication'
    };
    navigator.clipboard.writeText(`${this.urlString}?${new URLSearchParams(queryParams).toString()}`);
    store.commit('setToast', {
      variant: 'success',
      title: 'URL Copied',
      body: "URL copied to System Clipboard."
    });
  }
}
