var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 16 16",
        "aria-labelledby": _vm.name,
        role: "presentation",
      },
    },
    [
      _c("title", { attrs: { id: _vm.name, lang: "en" } }),
      _c("path", { attrs: { fill: _vm.color, d: _vm.path } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }