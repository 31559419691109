
import { Component, Vue } from "vue-property-decorator";

import authUtils from "@/utils/authUtils";
import { getFAQs, addFAQSection } from "@/components/faq/utils";
import Accordion from "@/components/faq/views/components/Accordion.vue";
import Icon from "@/components/Icons.vue";
import store from "@/store";

interface Section {
  id: number;
  title: string;
}

interface FAQ {
  id: number;
  title: string;
  content: Section[];
}

@Component({
  components: {
    Accordion,
    Icon,
  },
  computed: {
    user() {
      return store.getters.getUserGroup("faqs");
    },
  },
})
export default class Faq extends Vue {
  public canEdit: boolean = false;
  public faqs: FAQ[] = [];
  user!: any[];

  async addSection(): Promise<void> {
    await addFAQSection({ title: "New Section" });
    this.refreshFAQs();
    store.commit('setToast', {
        variant: 'info',
        title: 'Reminder: Add answer to FAQ',
        body: "Please add answer(s) to question in the section added."
    });
  }

  async refreshFAQs(): Promise<void> {
    this.faqs = await getFAQs();
  }

  created(): void {
    this.refreshFAQs();
    if (this.user == null || this.user["canEdit"] === 0) {
      this.canEdit = false;
    } else {
      this.canEdit = true;
    }
  }
}
