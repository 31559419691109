
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";
import store from "@/store";

@Component
export default class EditableInput extends Vue {
  @Prop({}) value!: string;
  @Prop({}) editInput!: boolean;
  @Prop({ default: "text" }) readonly tag?: string;

  public valueLocal: string = this.value;

  public onInputChange(el: HTMLInputElement): void {
    this.valueLocal = el.value;
  }

  public onTextareaChange(textareaValue: string): void {
    this.valueLocal = textareaValue;
  }

  @Emit("update")
  saveValue():string {
    return this.valueLocal;
  }

  onSaveValue():void {
    if(this.valueLocal.trim())
      this.saveValue();
    else 
      store.commit('setToast', {
          variant: 'warning',
          title: 'Field is Required',
          body: "Please set text before saving."
      });
  }

  @Emit("type")
  adjustHeight(): void {
    return;
  }

  @Watch("editInput")
  onEditInput(val: any){
    if(!val) this.valueLocal = this.value;
  }

}
