import service from "../../../utils/http/apiClient";
import { AccessTokens } from "@/utils/types";
import store from "@/store";
import router from "@/router";

export const authCredentials = async (payload: any):Promise<any> => {
    try {
        const response = await service.post("/token/", payload);
        const tokenizer: AccessTokens = response.data;
        window.localStorage.setItem('mchp', JSON.stringify(tokenizer));

        router.push("/dashboard");
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger', 
            title: 'Not Found', 
            body: "Sorry, Credentials unverified."
        })
    }
}

export const getPartnersFromServer = async (is_active: boolean):Promise<any> => {
    try {
        const response = await service.get("partner/",  {'approve': is_active, 'active': is_active});
        return response.data;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Not Found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
    }
}

export const searchPartnersInServer = async (params: { [key: string]: unknown }): Promise<any> => {
    try {
        const response = await service.get("partner/", params);
        return response.data;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Not Found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
    }
}

export const getPartnerVFilterConstants = async ():Promise<any> => {
    try {
        const response = await service.get("partners/v-filters/");
        return response.data;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Not Found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
    }
}

export const authRemoveAccess = async ():Promise<void> => {
    try {
        await service.post("/token/", {});
        window.localStorage.removeItem('mchp')
    } catch (err) {
        console.log("Credentials unverified.")
    }
}

export const addNewPartnerToServer = async (partnerData: any): Promise<any> => {
    try {
        const response = await service.post('partner/', partnerData);
        store.commit('setToast', {
          variant: 'success',
          title: 'Partner creation',
          body: "Successfully added a new partner. Reloading page.."
        });
        setTimeout(()=> { location.reload()}, 2000)
        return response.data;
    } catch (err) {
        const storeErrMsg = []
        const data:any = err.response.data
        for (const [key, value] of Object.entries(data)) {
            const v:any = value
            const k:any = key
            k === 'contact' ? storeErrMsg.push(v.email[0]) : storeErrMsg.push(v[0])
        }
        for(const val of storeErrMsg) {
            // setTimeout is necessary NOT to override the error message directly.
            setTimeout(() => {
                store.commit('setToast', {
                    variant: 'danger',
                    title: 'Request Error',
                    body: val,
                    noAutoHide: true,
                    appendToast: true
                });
            }, 300)
        }
    }
}

export const editPartnerFromServer = async (id: number, contactId:number, contactData:any, partnerData: any, liveData: any, stashedData: any): Promise<any> => {
    let isReload = true;
    try {
        if (JSON.stringify(liveData) != JSON.stringify(stashedData)) {
            const response = await service.put(`partner/${id}/`, partnerData);

            try {
                const response = await service.put(`contact-person/${contactId}/`, contactData);
                if(isReload) {
                    store.commit('setToast', {
                        variant: 'success',
                        title: 'Edit Partner',
                        body: "Partner information succesfully updated."
                    });
                    isReload ? setTimeout(() => { location.reload()}, 2000) : isReload = false
                }
                return response.data;
            }
            catch (err) {
                isReload = false; // Prevent from loading the page if error occurs
                const storeErrMsg = []
                const data:any = err.response.data
                for (const [key, value] of Object.entries(data)) {
                    const v:any = value
                    const k:any = key
                    k === 'contact' ? storeErrMsg.push(v.email[0]) : storeErrMsg.push(v[0])
                }
                for(const val of storeErrMsg) {
                    // setTimeout is necessary NOT to override the error message directly.
                    setTimeout(() => {
                        store.commit('setToast', {
                            variant: 'danger',
                            title: 'Request Error',
                            body: val,
                            noAutoHide: true,
                            appendToast: true
                        });
                    }, 300)
                }
            }
            return response.data;
        } 
    } catch (err) {
        isReload = false;
        store.commit('setToast', {
            variant: 'danger',
            title: 'Request Error',
            body: String(err.response.data.company_url[0]),
            noAutoHide: true,
            appendToast: true
        });
        try {
            const response = await service.put(`contact-person/${contactId}/`, contactData);
            isReload ? setTimeout(() => { location.reload()}, 2000) : isReload = false
            return response.data;
        }
        catch (err) {
            isReload = false; // Prevent from loading the page if error occurs
            const storeErrMsg = []
            const data:any = err.response.data
            for (const [key, value] of Object.entries(data)) {
                const v:any = value
                const k:any = key
                k === 'contact' ? storeErrMsg.push(v.email[0]) : storeErrMsg.push(v[0])
            }
            for(const val of storeErrMsg) {
                // setTimeout is necessary NOT to override the error message directly.
                setTimeout(() => {
                    store.commit('setToast', {
                        variant: 'danger',
                        title: 'Request Error',
                        body: val,
                        noAutoHide: true,
                        appendToast: true
                    });
                }, 300)
            }
        }
    }
}

export const updateContactFromServer = async (id: number, contactData: any): Promise<any> => {
    try {
        const response = await service.put(`contact-person/${id}/`, contactData);
        return response.data;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Request Error',
            body: String(err.response.data.email[0]),
            noAutoHide: true,
            appendToast: true
        });
    }
}

export const getPartnerSnippetsFromServer = async (id: number): Promise<any> => {
    try {
        const response = await service.get(`partner/${id}/`);
        return response.data;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Not Found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        })
    }
}

export const getPartnerSnippetsFromServerFiltered = async (id:number, params: { [key: string]: unknown }):Promise<any> => {
    try {
        const response = await service.get(`partner/${id}/snippets/`,  params);
        return response.data;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Not Found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
    }
}

export const getPartnerAgreements = async ():Promise<void> => {
    try{
        const response = await service.get("syndication/agreements/");
        return response.data;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Company name not found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        })
    }
}

export const getOwners = async ():Promise<void> => {
    try {
        // Super admin and Content Syndication Manager
        const response = await service.get("syndication-user/?groups=1&groups=2");
        return response.data;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Display owners error',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        })
    }
}

export const setNewAPIKey = async (partnerDetails:any):Promise<any> => {
    try {
        const response = await service.post("partner-api-key/", partnerDetails);
        return response.data;
    } catch(err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'API Key Error',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        })
    }
}