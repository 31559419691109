import httpClient from './api-middleware';

const post = async(uri: string, params: { [key: string]: unknown }):Promise<any> => {
    return await httpClient.post(uri, params);
}

const get = async(uri: string, params?: { [key: string]: unknown }):Promise<any> => {
    return await httpClient.get(uri, { params })
}

const patch = async(uri: string, params: { [key: string]: unknown }):Promise<any> => {
    return await httpClient.patch(uri, params);
}

const put = async(uri: string, params: { [key: string]: unknown }):Promise<any> => {
    return await httpClient.put(uri, params);
}

const remove = async(uri: string, params?: { [key: string]: unknown }):Promise<any> => {
    await httpClient.delete(uri, params);
}

const download = async(uri: string):Promise<void> => {
    window.location.href = uri
}

const exec = async(params: { [key: string]: unknown }):Promise<any> => {
    return await httpClient(params);
}


export default {
    post,
    get,
    patch,
    put,
    remove,
    download,
    exec
}