var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: { "--textarea": _vm.tag === "textarea" },
      style: _vm.editInput ? "width: 100%" : "width: auto;",
    },
    [
      _vm.editInput
        ? _c(
            "div",
            { staticClass: "mchp-editableInput" },
            [
              _vm.tag === "text"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.valueLocal,
                        expression: "valueLocal",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { type: "text" },
                    domProps: { value: _vm.valueLocal },
                    on: {
                      change: function ($event) {
                        return _vm.onInputChange($event.target)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.valueLocal = $event.target.value
                      },
                    },
                  })
                : _vm._e(),
              _vm.tag === "textarea"
                ? _c("b-form-textarea", {
                    attrs: { rows: "3", "max-rows": "8" },
                    on: {
                      change: function ($event) {
                        return _vm.onTextareaChange($event)
                      },
                      input: function ($event) {
                        return _vm.adjustHeight($event.target)
                      },
                    },
                    model: {
                      value: _vm.valueLocal,
                      callback: function ($$v) {
                        _vm.valueLocal = $$v
                      },
                      expression: "valueLocal",
                    },
                  })
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "mchp-util-primaryButton",
                  on: {
                    click: function ($event) {
                      return _vm.onSaveValue()
                    },
                  },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          )
        : _c("div", [_c("pre", [_vm._v(_vm._s(_vm.valueLocal))])]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }