var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.isLoading
      ? _c(
          "div",
          {
            staticClass:
              "d-flex align-items-center justify-content-center v-center-height",
          },
          [_c("b-spinner", { attrs: { variant: "primary" } })],
          1
        )
      : _c("div", [
          _vm.itemCount == 0
            ? _c("div", [_c("h2", [_vm._v("Starting Soon")]), _vm._m(0)])
            : _c(
                "div",
                [
                  _c("h2", [_vm._v("Starting Soon")]),
                  _vm._l(
                    _vm.filterItemsWithDateValidation,
                    function (item, idx) {
                      return _c(
                        "div",
                        {
                          key: `${item.id}-${Math.random(1000)}`,
                          staticClass: "bu-item-by-date",
                        },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                variant: "outline-secondary mt-3 mb-2",
                              },
                            },
                            [
                              _vm._v(
                                "Start Date: " +
                                  _vm._s(item.list.snippetPartners.start_date)
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "text-monospace" }, [
                            _vm._v(
                              _vm._s(_vm._f("capitalize")(item.business_unit)) +
                                " "
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "mb-2 font-weight-bolder" },
                            [_vm._v(_vm._s(item.title))]
                          ),
                          _c(
                            "div",
                            { staticClass: "mb-2 font-weight-bolder" },
                            [_vm._v(_vm._s(item.list.company_name))]
                          ),
                          idx != _vm.items.length - 1 ? _c("hr") : _vm._e(),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "d-flex align-items-center justify-content-center v-center-height",
      },
      [_c("h5", [_vm._v("No results")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }