
import { Component, Vue, Prop } from "vue-property-decorator";

interface Coordinates {
  x: number;
  y: number;
}

@Component({
  name: "Sparkline",
})
export default class Sparkline extends Vue {
  @Prop({}) dataPoints!: number[];

  private stroke: number = 4;
  private width: number = 466;
  private height: number = 150;

  get count(): number {
    return this.dataPoints.length - 1;
  }

  get shape(): string {
    const stroke = this.stroke;
    const width = this.width;
    const height = this.height - stroke * 2;
    const data = this.dataPoints || [];
    const highestPoint: number = Math.max.apply(null, data);
    const coordinates: Coordinates[] = [];
    const totalPoints = this.count;
    data.forEach((item, n) => {
      const x: number = (n / totalPoints) * width + stroke;
      const y: number = height - (item / highestPoint) * height + stroke;
      coordinates.push({ x, y });
    });
    if (!coordinates[0]) {
      return (
        "M 0 " +
        this.stroke +
        " L 0 " +
        this.stroke +
        " L " +
        this.width +
        " " +
        this.stroke
      );
    }
    const path: string[] = [];
    coordinates.forEach((point) => path.push(["L", point.x, point.y].join(" ")));
    return ["M" + coordinates[0].x, coordinates[0].y, ...path].join(" ");
  }
}
