
import { Component, Vue } from 'vue-property-decorator';
import {mapGetters} from 'vuex';

@Component({
    computed: {
        ...mapGetters({
            showToast: 'getToastValue'
        })
    },
    watch: {
        showToast: {
            handler: function(val) {
                if(val) {
                    const h = this.$createElement
                    
                    // Create the title
                    const vNodesTitle = h(
                        'div',
                        { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
                        [
                            h('strong', { class: 'mr-2' }, val?.title),
                        ]
                    )
                    
                    this.$bvToast.toast(val?.body ?? "", {
                        title: [vNodesTitle],
                        variant: val?.variant ?? 'default',
                        toaster: val?.toaster ?? 'b-toaster-top-right',
                        solid: true,
                        noAutoHide: val?.noAutoHide ?? false,
                        appendToast: val?.appendToast ?? false
                    })
                }
            },
            deep: true
        }
    }
})

export default class Toast extends Vue {}
