
import { Component, Prop, Vue } from "vue-property-decorator";
import EditSnippets from "../components/EditSnippets.vue";
import ElemURL from "../components/ElemURL.vue";
import { getModule } from "vuex-module-decorators";
import FilterModule from "@/store/Filter";
import AnalyticGraph from "../components/AnalyticGraph.vue";
import Icon from "@/components/Icons.vue";
import ModalOriginalSummary from "./../common/ModalOriginalSummary.vue"

@Component({
  name: "FinishedCards",
  components: { EditSnippets, ElemURL, AnalyticGraph, Icon, ModalOriginalSummary },
})
export default class FinishedCards extends Vue {
  @Prop() readonly user: any | undefined;
  @Prop({ default: "" }) item: any;
  @Prop({ default: false }) isSearchResult: any;
  @Prop({ default: false }) isSyndicatedSnippetPage: any;
  @Prop({ default: "" }) propPartnerList: any;

  filterStore = getModule(FilterModule, this.$store);

  public isEdited: boolean = true;
  public hideFooter: boolean = true;
  public snippet: any = null;
  public partnerList: any = null;
  private modalPartnerList: any = [];
  private selectedPartners: any = [];

  public onShowModal(payload : any) {
    const params = {text: payload.original_summary, id: payload.id};
    const ref = this.$refs!.refSeeOriginal ?? "";
    ref["onShowModal"](params);
  }

  get searchFlag(): boolean {
    return this.isSearchResult;
  }

  get searchTermArray(): Array<string> {
    let queries = this.filterStore.searchTermValue?.split(" ") ?? [];
    return queries;
  }

  public checkIfSelected(partner_id: number) {
    const found: any = this.item.partners.find((e: any) => {
      return e.id == partner_id;
    });
    return found ? true : false;
  }

  public findPartnerSnippetEndDate(partner_id: number, snippet_id: number): any {
    const partner = this.partnerList.results.find((e: any) => e.id == partner_id);
    const partner_snippet = partner.partner_snippets.find(
      (e: any) => e.snippet.id == snippet_id
    );
    let end_date = new Date(partner_snippet?.end_date) ?? "";
    let live_end_date = this.snippet.snippet_partners[0]?.end_date ?? "";
    const queueCardSnippetEndDate = live_end_date ? new Date(live_end_date)?.toISOString()?.split('T')[0] : "";
    return this.isValidDate(end_date) ? end_date.toISOString().split('T')[0] : queueCardSnippetEndDate;
  }

  public findPartnerSnippetStartDate(partner_id: number, snippet_id: number): any {
    const partner = this.partnerList.results.find((e: any) => e.id == partner_id);
    const partner_snippet = partner.partner_snippets.find(
      (e: any) => e.snippet.id == snippet_id
    );
    let start_date = new Date(partner_snippet?.start_date);

    return this.isValidDate(start_date)
      ? start_date.toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0];
  }

  public isValidDate(d: any): boolean {
    return !isNaN(d.getTime());
  }

  get syndicatorStartDate(): string {
    if (this.snippet.length != 0 && this.isSyndicatedSnippetPage === false) {
      try {
        return this.snippet.snippet_partners[0]["start_date"];
      } catch (err) {
        return "---";
      }
    }
    return this.item.start_date;
  }

  get syndicatorEndDate(): string {
    if (this.snippet.length != 0 && this.isSyndicatedSnippetPage === false) {
      try {
        return this.snippet.snippet_partners[0]["end_date"];
      } catch (err) {
        return "---";
      }
    }
    return this.item.end_date;
  }

  public computePartnerList(): void {
    if (this.partnerList != null) {
      let partner_set = this.partnerList.results.map((e: any) => {
        let item = {
          partnerId: e.id,
          name: e.company_name,
          snippetId: this.snippet.id,
          is_selected: this.checkIfSelected(e.id),
          start_date: this.findPartnerSnippetStartDate(e.id, this.snippet.id),
          end_date: this.findPartnerSnippetEndDate(e.id, this.snippet.id),
        };

        if (item.is_selected) {
          const matched = this.item.snippet_partners.find(
            (e: any) => e.partner == item.partnerId
          );
          if (matched) {
            item["syndicatorId"] = matched.id;
          }
          this.selectedPartners.push(item);
        }

        return item;
      });
      this.modalPartnerList = partner_set;
    }
  }

  get updatedAt(): any {
    let date = new Date(this.snippet.updated_at).toISOString().split("T")[0];
    return date;
  }

  created(): void {
    // Regular syndication page or Syndicated page
    this.snippet = this.item
    this.partnerList = this.propPartnerList;
  }

  mounted() {
    this.computePartnerList();
  }
}
