
import { Component, Vue } from 'vue-property-decorator';
import {getStartSoonSnippets} from '@/components/dashboard/utils';
import authUtils from "@/utils/authUtils";

@Component({
  name: "StartSoonBlock"
})
export default class StartSoonBlock extends Vue{
  public items: any = null;
  public isLoading: boolean = false;
  public filterItemsWithDateValidation: any = null;

  async mounted(){
    this.isLoading = true;

    let userInfo = authUtils.getUserInfo();
    let params = {business_unit: userInfo.profile.business_unit}
    let response = await getStartSoonSnippets(params);
    this.items = response.data;
    this.newItems(response.data)

    this.isLoading = false;
  }

  newItems(items: any) {
    const objects = items.filter(this.checkStartDate)

    let snippets : any[] = []
    objects.map((item : any) => {
      if(snippets.length) {
        const found = snippets.findIndex(x => x.id == item.id);
        if(found < 0) snippets.push(item)
      }
      else snippets.push(item) 
    });

    const newSnippets : any[] = [];
    snippets.map((item: any) => {
      let partnerDetails: any = item;
      const snippetPartners = item.snippet_partners;
      const partners = item.partners;
      let snippetItems: any[] = [] 

      if(partners.length > 0) {
        partners.map((partner: any) => {
          let index = snippetPartners.findIndex((snippet : any) => partner.id == snippet.partner)
          partner['snippetPartners'] = snippetPartners[index];
          snippetItems.push(partner);
        })
      } else {
        // If partner key doesn't exists.
        let temp = {
          id: item.id,
          title: item.title,
          business_unit: item.business_unit,
          snippetPartners: item.snippet_partners[0]
        }
        snippetItems.push(temp);
      }


      snippetItems.map((snip: any) => {
        let newItem = {...item, ...{'list': {...snip}}};
        newSnippets.push(newItem)
      })
    })
    
    this.filterItemsWithDateValidation = newSnippets;
  }

  checkStartDate(item: any) {
    const dateToday:any = new Date();
    const itemStartDate:any = new Date(item.snippet_partners[0].start_date);

    const numberOfDays = this.subtractDays(dateToday, itemStartDate);

    // 1 to 5 days
    if (numberOfDays < 6 && numberOfDays > 0) {
      return item
    }
  }

  subtractDays(dateToday:any, itemDate: any) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate());
    const utc2 = Date.UTC(itemDate.getFullYear(), itemDate.getMonth(), itemDate.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  get itemCount(): number{
    return this.items?.length ?? 0;
  }


}
