
import {Component, Vue, Prop} from 'vue-property-decorator';
import {getPartnerVFilterConstants} from "@/components/partners/utils";
import {getModule} from "vuex-module-decorators";
import FilterModule from "@/store/Filter";
import SearchBar from "./SearchBar.vue"

@Component({
  name: "FilterBarPartner",
  components: {SearchBar}
})
export default class FilterBarPartner extends Vue {
  @Prop({ default: "" }) readonly fieldDisplay: any;
  @Prop({ default: [] }) readonly dataList: any | Array<any>;
  public vfilters: any = null;
  public resetSearchBar: any = "";

  public filledSearchName: string = '';
  public selectPartnerType: string = '';

  store = getModule(FilterModule, this.$store);

  async mounted() {
    const resp_data = await getPartnerVFilterConstants();
    this.vfilters = resp_data['results'];
  }

  get partnerTypeOptions(){
    if(this.vfilters?.partner_type){
      const nullVal = {"text": "Filter", "value": ''};
      return [nullVal, ...this.vfilters.partner_type.sort((a: any, b: any) => a.text.localeCompare(b.text))];
    }
    return {};
  }

  async onSelect(payload : any) {
    this.store.setSearchName(payload?.company_name ?? "");
  }

  submitPartnerType(e: Event): void{
    this.store.setPartnerType(this.selectPartnerType);
  }

  resetFilter(): void{
    this.store.setSearchName('');
    this.filledSearchName = '';
    this.resetSearchBar = Math.floor(Math.random() * 1000);

    this.store.setPartnerType('');
    this.selectPartnerType = '';

    this.store.setResetSearchFlag(true);
  }

  hideSearchIcon() {
    const iconSearch = document.querySelector(".cs-partner-search-icon-group");
    const iconClose = document.querySelector(".cs-partner-close-icon-group");
    if (this.filledSearchName !== '') {
      iconSearch?.classList.add('hide')
      iconSearch?.classList.remove('show')
      iconClose?.classList.remove('hide')
      iconClose?.classList.add('show')
      
    } else {
      iconSearch?.classList.remove('hide')
      iconSearch?.classList.add('show')
      iconClose?.classList.add('hide')
      iconClose?.classList.remove('show')
      this.clearFilter()
    }
  }

  clearPreventDefault(e:Event) {
    e.preventDefault();
    this.filledSearchName = ''
    const iconSearch = document.querySelector(".cs-partner-search-icon-group");
    const iconClose = document.querySelector(".cs-partner-close-icon-group");
    if (this.filledSearchName !== '') {
      iconSearch?.classList.add('hide')
      iconSearch?.classList.remove('show')
      iconClose?.classList.remove('hide')
      iconClose?.classList.add('show')
      
    } else {
      iconSearch?.classList.remove('hide')
      iconSearch?.classList.add('show')
      iconClose?.classList.add('hide')
      iconClose?.classList.remove('show')
      this.clearFilter()
    }
  }

  clearFilter() {
    this.resetFilter()
  }
}
