var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("h3", { staticClass: "mchp-category-title" }, [_vm._v("FAQ/Help")]),
    _vm._m(0),
    _c("div", { staticClass: "mchp-content-container" }, [
      _c("div", { staticClass: "mchp-card-container" }, [
        _vm.faqs
          ? _c(
              "div",
              [
                _vm._v(" " + _vm._s(_vm.faqs.content) + " "),
                _vm._l(_vm.faqs.results, function (section) {
                  return _c(
                    "div",
                    { key: section.id },
                    [
                      _c("Accordion", {
                        attrs: { accordions: section, editable: _vm.canEdit },
                        on: { updateSection: _vm.refreshFAQs },
                      }),
                    ],
                    1
                  )
                }),
                _c(
                  "div",
                  {
                    class: [
                      _vm.faqs.count === 0
                        ? "mchp-faqEmpty"
                        : "mchp-util-pushRight",
                    ],
                  },
                  [
                    _vm.canEdit
                      ? _c(
                          "button",
                          {
                            staticClass: "mchp-util-unstyledButton",
                            on: { click: _vm.addSection },
                          },
                          [
                            _c("Icon", { attrs: { name: "add" } }),
                            _vm.faqs.count === 0
                              ? _c("span", [_vm._v("FAQs are empty.")])
                              : _vm._e(),
                            _vm._v(" Add a FAQ Section "),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              2
            )
          : _c("div", [
              _vm.faqs.count === 0
                ? _c("span", [_vm._v("FAQs are empty.")])
                : _vm._e(),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h5", { staticClass: "mchp-faq-subtitle" }, [
      _vm._v(" For additional help: "),
      _c(
        "a",
        {
          attrs: {
            href: "mailto:content.syndication@microchip.com?&subject=Microchip content syndication help",
          },
        },
        [_vm._v("content.syndication@microchip.com")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }