var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        { staticClass: "mb-2", attrs: { "align-h": "between" } },
        [
          _c(
            "b-col",
            { staticClass: "mr-0 mb-2", attrs: { lg: "6" } },
            [
              _vm.editSnippetImage
                ? _c("b-row", { staticClass: "d-block mb-2" }, [
                    _c("h4", [_vm._v("Original Image")]),
                    _c(
                      "div",
                      [
                        _c("b-img", {
                          staticClass: "snippet-image",
                          attrs: {
                            src: _vm.snippetOriginalImage,
                            center: "",
                            thumbnail: "",
                            fluid: "",
                            alt: "Image",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c("b-row", { staticClass: "d-block" }, [
                _c("h4", [_vm._v("Current Image")]),
                _vm.snippetImage
                  ? _c(
                      "div",
                      [
                        _c("b-img", {
                          staticClass: "snippet-image",
                          attrs: {
                            src: _vm.snippetImage,
                            center: "",
                            thumbnail: "",
                            fluid: "",
                            alt: "Image",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "d-flex justify-content-center" },
                      [
                        _c("b-icon", {
                          staticClass: "snippet-image",
                          staticStyle: {
                            cursor: "pointer",
                            outline: "none !important",
                          },
                          attrs: {
                            icon: "card-image",
                            variant: "secondary",
                            "font-scale": "15",
                          },
                        }),
                      ],
                      1
                    ),
              ]),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "ml-auto", attrs: { lg: "5" } },
            [
              _c(
                "b-row",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          placeholder: "Search Images",
                          disabled: _vm.searchDisable,
                        },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getImageOptions(true, true)
                          },
                        },
                        model: {
                          value: _vm.imageSearchTerm,
                          callback: function ($$v) {
                            _vm.imageSearchTerm = $$v
                          },
                          expression: "imageSearchTerm",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          !_vm.imageSearched
                            ? _c(
                                "b-button",
                                {
                                  attrs: { variant: "info" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getImageOptions(false, true)
                                    },
                                  },
                                },
                                [_c("b-icon", { attrs: { icon: "search" } })],
                                1
                              )
                            : _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "info",
                                    disabled: _vm.searchDisable,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clearSearch()
                                    },
                                  },
                                },
                                [_c("b-icon", { attrs: { icon: "x" } })],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("b-row", { staticClass: "list-content-row" }, [
                this.imageOptions
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-list-group",
                          { on: { scroll: _vm.imageOptionScroll } },
                          [
                            _vm._l(
                              this.imageOptions,
                              function (imageOption, index) {
                                return _c(
                                  "b-list-group-item",
                                  {
                                    key: index,
                                    attrs: { button: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectImage(imageOption)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b-container",
                                      [
                                        _c(
                                          "b-row",
                                          {
                                            attrs: {
                                              "align-h": "center",
                                              "align-v": "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { md: "5" } },
                                              [
                                                _c("b-img", {
                                                  staticClass: "rounded-0",
                                                  attrs: {
                                                    src: imageOption.path,
                                                    thumbnail: "",
                                                    alt: "Image",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              { attrs: { md: "7" } },
                                              [
                                                _c(
                                                  "b-row",
                                                  { staticClass: "mb-2" },
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "text-sm-right",
                                                        attrs: { cols: "4" },
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v("Date:"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "text-break",
                                                        attrs: { cols: "8" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            imageOption.date
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "text-sm-right",
                                                        attrs: { cols: "4" },
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v("Name:"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "text-break",
                                                        attrs: { cols: "8" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            imageOption.name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            this.loading
                              ? _c(
                                  "b-list-group-item",
                                  { staticClass: "text-center text-info my-2" },
                                  [
                                    _c("b-spinner", {
                                      staticClass: "align-middle",
                                    }),
                                    _c("strong", [_vm._v(" Loading...")]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : this.loading
                  ? _c(
                      "div",
                      { staticClass: "text-center text-info my-2" },
                      [
                        _c("b-spinner", { staticClass: "align-middle" }),
                        _c("strong", [_vm._v(" Loading...")]),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "text-center text-info my-2" }, [
                      !this.imageSearchTerm
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v("Fill out the search field to start."),
                          ])
                        : _vm._e(),
                      this.imageSearched
                        ? _c("span", [_vm._v("No results found")])
                        : _vm._e(),
                    ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "modal-footer" },
        [
          _c(
            "b-button",
            {
              staticClass: "mr-auto p-2",
              attrs: { variant: "secondary" },
              on: {
                click: function ($event) {
                  return _vm.closeModal()
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "b-button",
            {
              staticClass: "ml-auto p-2",
              attrs: { variant: "primary", disabled: this.saveButtonDisable },
              on: {
                click: function ($event) {
                  return _vm.saveImage()
                },
              },
            },
            [_vm._v(" Save ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }