var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h3", { staticClass: "mchp-category-title" }, [
        _vm._v("Syndications"),
      ]),
      _c("b-tabs", {
        staticClass: "tab-container",
        scopedSlots: _vm._u([
          {
            key: "tabs-end",
            fn: function () {
              return [
                _c(
                  "b-nav-item",
                  {
                    staticClass: "mchp-nav-item-inner",
                    attrs: {
                      href: "#",
                      role: "Drafts",
                      to: { name: "Drafts" },
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setActiveParent()
                      },
                    },
                  },
                  [_c("h5", [_vm._v("Drafts")])]
                ),
                _c(
                  "b-nav-item",
                  {
                    staticClass: "mchp-nav-item-inner",
                    attrs: { href: "#", role: "Queue", to: { name: "Queue" } },
                    on: {
                      click: function ($event) {
                        return _vm.setActiveParent()
                      },
                    },
                  },
                  [_c("h5", [_vm._v("Queue")])]
                ),
                _c(
                  "b-nav-item",
                  {
                    staticClass: "mchp-nav-item-inner",
                    attrs: {
                      href: "#",
                      role: "Currently Live",
                      to: { name: "Live" },
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setActiveParent()
                      },
                    },
                  },
                  [_c("h5", [_vm._v("Currently Live")])]
                ),
                _c(
                  "b-nav-item",
                  {
                    staticClass: "mchp-nav-item-inner",
                    attrs: {
                      href: "#",
                      role: "No Longer Live",
                      to: { name: "Finished" },
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setActiveParent()
                      },
                    },
                  },
                  [_c("h5", [_vm._v("No Longer Live")])]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("FilterBarSyndication", {
        attrs: {
          dataList: _vm.dataList,
          fieldDisplay: _vm.fieldDisplay,
          businessUnits: _vm.businessUnits,
        },
      }),
      _c(
        "div",
        { staticClass: "mchp-content-container", attrs: { id: "queue-view" } },
        [
          _c(
            "div",
            { staticClass: "mchp-card-container", attrs: { "no-body": "" } },
            [
              _c("router-view", {
                attrs: { user: _vm.user, businessUnits: _vm.businessUnits },
                on: { searchBarDataList: _vm.searchBarDataList },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }