var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isSearch
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "cs-search-box" },
                [
                  _c("b-card", { staticClass: "text-center" }, [
                    _c("h6", [
                      _vm._v(
                        "Found " +
                          _vm._s(_vm.inActivePartnerList.results.length) +
                          " " +
                          _vm._s("Results")
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-overlay",
        {
          staticClass: "cs-container-scroll-control",
          attrs: { show: _vm.isLoading },
        },
        _vm._l(_vm.snippetsForList, function (partner) {
          return _c("InActiveCards", {
            key: partner.id,
            attrs: {
              item: partner,
              isSearchResult: _vm.isSearch,
              user: _vm.user,
            },
          })
        }),
        1
      ),
      _c(
        "b-row",
        {
          staticClass: "ml-2 mr-2 pt-3 pagination-container",
          attrs: { sm: "12" },
        },
        [
          _c("b-col", { attrs: { md: "4", sm: "12" } }, [
            _c("p", [
              _vm._v(
                "Showing " +
                  _vm._s(_vm.getPageStart) +
                  " - " +
                  _vm._s(
                    (_vm.currentPage - 1) * _vm.perPage +
                      _vm.snippetsForList.length
                  ) +
                  " of " +
                  _vm._s(_vm.rows) +
                  " results"
              ),
            ]),
          ]),
          _c("b-col", { attrs: { md: "4", sm: "12" } }, [
            _c(
              "div",
              [
                _c("b-pagination", {
                  staticClass: "cs-page-buttons",
                  attrs: {
                    "total-rows": _vm.rows,
                    "per-page": _vm.perPage,
                    "last-text": ">",
                    "first-text": "<",
                    "next-text": "Next",
                    "prev-text": "Previous",
                    align: "center",
                    "aria-controls": "queueCardList",
                  },
                  model: {
                    value: _vm.currentPage,
                    callback: function ($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("b-col", { attrs: { md: "4", sm: "12" } }, [
            _c(
              "p",
              { staticClass: "text-right" },
              [
                _c("span", [_vm._v("Results per page: ")]),
                _vm._v(" "),
                _c("b-form-select", {
                  staticClass: "cs-pagination-select",
                  attrs: { align: "right", options: _vm.perPageSelect },
                  model: {
                    value: _vm.perPage,
                    callback: function ($$v) {
                      _vm.perPage = $$v
                    },
                    expression: "perPage",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }