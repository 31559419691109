
import { Component, Prop, Vue } from 'vue-property-decorator'
import {getPartnerVFilterConstants} from "@/components/partners/utils";
import {getModule} from "vuex-module-decorators";
import EditPartner from '../components/EditPartner.vue'
import FilterModule from "@/store/Filter";

@Component({
  name: "InActiveCards",
  computed: {},
  components: { EditPartner }
})
export default class InActiveCards extends Vue {
  @Prop() readonly user: any | undefined
  @Prop({default: "" }) item: any;
  @Prop({}) isSearchResult: any;

  public isUpdating: boolean = false;
  public partner: any = null;

  filterStore = getModule(FilterModule, this.$store);

  private partnerTypes:any = null;

  async created(){
    let response = await getPartnerVFilterConstants();
    this.partnerTypes = response.results;
    this.partner = this.item;
  }

  get partnerType(): string{
    if(this.partnerTypes != null) {
      let typeList = this.partnerTypes.partner_type;
      let partnerType = typeList.find((i: any) => i.value == this.partner.partner_type)
      return partnerType.text;
    }

    return ""
  }

  get searchFlag(): boolean{
    return this.isSearchResult;
  }

  get searchNameArray(): Array<string> {
    let queries = this.filterStore.searchNameValue?.split(" ") ?? [];
    return queries;
  }
}
