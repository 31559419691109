
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Icon from "@/components/Icons.vue";
import { DateTime } from 'luxon';

@Component({
  name: "EditSnippets",
  components: {
    Icon
  },
})
export default class EditSnippets extends Vue {
  @Prop({}) propSnippet: any;

  public singleSnippet: any = null;
  public startDate: Date = new Date();
  public endDate: Date = new Date();
  public isDateInvalid: boolean = true;
  public minDate = DateTime.local().plus({ days: 1 }).toISODate()
  public minEndDate = DateTime.local().plus({ days: 2 }).toISODate()

  created(): void{
    
    this.singleSnippet = this.propSnippet;
    if(this.singleSnippet.snippet_partners.length != 0){
      const snippetStartDate = this.singleSnippet.snippet_partners[0].start_date
      const snippetEndDate = this.singleSnippet.snippet_partners[0].end_date

      this.startDate = snippetStartDate;
      this.endDate = snippetEndDate;

      this.minEndDate = DateTime.fromISO(snippetStartDate).plus({ days: 1 }).toISODate();
    }
  }

  mounted() {
    this.checkDates()
  }
// start here
  public updateSnippetSummary(): void{
    let isoStartDate = new Date(this.startDate).toISOString().split('T');
    let isoEndDate = new Date(this.endDate).toISOString().split('T');
    this.getHTMLFromContentEditor();
    let params = {snippet: this.singleSnippet, start_date: isoStartDate[0], end_date: isoEndDate[0]};
    this.$emit("editedSummarySaved", params);
  }

  public getHTMLFromContentEditor(): void {
    const textareaContent =  this.$refs.snippetTextarea as HTMLInputElement;
    this.singleSnippet.summary = textareaContent.innerHTML;

    if (!textareaContent.innerText.trim()) {
      this.singleSnippet.summary = textareaContent.innerText.trim();
    }
  }

  public formatText(tag: string): void {
    let selectedText = window.getSelection();
    let parentNode = selectedText?.anchorNode?.parentNode as HTMLElement;
    if (selectedText && (parentNode.tagName === "SUB" || parentNode.tagName === "SUP")) {
        parentNode.replaceWith(...parentNode.childNodes);
    } else {
      let wrapper = document.createElement(tag)
      window.getSelection()?.getRangeAt(0)?.surroundContents(wrapper);
    }
    this.getHTMLFromContentEditor();
  }

  checkDates() {
    let isoStartDate = new Date(this.startDate).toISOString().split('T');
    let isoEndDate = new Date(this.endDate).toISOString().split('T');
    if (isoStartDate > isoEndDate) {
      this.isDateInvalid = true;
      // const start = document.getElementById('picker-start-date__outer_');
      const end = document.getElementById('picker-end-date__outer_');
      end!.style.border = "solid 1px #ed1b2e"
    } else {
      this.isDateInvalid = false;
      const end = document.getElementById('picker-end-date__outer_');
      end!.style.border = "1px solid #ced4da"
    }
  }

  @Watch('startDate')
  onChangeStartDate(val : any) {
    this.minEndDate = DateTime.fromISO(val).plus({ days: 1 }).toISODate();
  }

}
