var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "overflow-hidden mb-3", attrs: { "no-body": "" } },
        [
          _c(
            "b-card-body",
            [
              _c(
                "b-row",
                {
                  staticClass: "snippet-container",
                  attrs: { "no-gutters": "", "align-v": "stretch" },
                },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "p-2 border-right d-flex flex-column",
                      attrs: { md: "8" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "top-container" },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { sm: "6" } }, [
                                _c("div", { staticClass: "mr-auto" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "cs-left-header",
                                      class:
                                        _vm.snippet.images.length != 0
                                          ? "has-image-adjustment"
                                          : "no-image-adjustment",
                                    },
                                    [
                                      _vm.snippet.images.length != 0
                                        ? _c("b-card-img", {
                                            staticClass:
                                              "cs-card-thumbnail rounded-0 p-2 mb-2",
                                            staticStyle: {
                                              "max-width": "120px",
                                              "max-height": "120px",
                                            },
                                            attrs: {
                                              src: _vm.snippet.images[0].path,
                                              thumbnail: "",
                                              alt: "Image",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _c(
                                "b-col",
                                { staticClass: "ml-auto", attrs: { sm: "6" } },
                                [
                                  _c(
                                    "b-badge",
                                    {
                                      staticClass: "pt-2 pb-2 pl-4 pr-4",
                                      staticStyle: {
                                        "background-color": "#9da8b7",
                                        color: "#ffffff",
                                        float: "right",
                                      },
                                      attrs: { variant: "light", pill: "" },
                                    },
                                    [_vm._v(_vm._s(_vm.item.content_type))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("b-card-title", [
                                    _vm.searchFlag
                                      ? _c(
                                          "span",
                                          [
                                            _c(
                                              "text-highlight",
                                              {
                                                attrs: {
                                                  queries: _vm.searchTermArray,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.snippet.title)
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.snippet.title) +
                                              " "
                                          ),
                                        ]),
                                  ]),
                                  _c("b-card-text", {
                                    staticClass: "cs-card-text",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.snippet.summary),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "bottom-container mt-auto" },
                        [
                          _c("hr"),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("div", { staticClass: "lower-title" }, [
                                  _vm._v("Syndication URL"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "lower-link" },
                                  [
                                    _c("ElemURL", {
                                      attrs: {
                                        "url-string": _vm.snippet.source_link,
                                        snippetID: _vm.snippet.id,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { cols: "6" } }, [
                                _c(
                                  "div",
                                  { staticClass: "lower-title mt-2 w-100" },
                                  [_vm._v("GA Campaign")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "cs-ga-campaign-text" },
                                  [_vm._v(_vm._s(_vm.snippet.ga_campaign_id))]
                                ),
                              ]),
                              _c("b-col", { attrs: { cols: "6" } }, [
                                _vm._v(" "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "p-2 d-flex flex-column",
                      attrs: { md: "4" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "top-container" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger w-100 cs-red-btn",
                              staticStyle: { cursor: "unset" },
                            },
                            [_vm._v(" Syndication in Progress ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-danger w-100 cs-plain-btn mt-2",
                              staticStyle: { cursor: "unset" },
                            },
                            [
                              _vm._v(
                                " Start Date: " +
                                  _vm._s(_vm.syndicatorStartDate) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-danger w-100 cs-plain-btn mt-2",
                              staticStyle: { cursor: "unset" },
                            },
                            [
                              _vm._v(
                                " End Date: " +
                                  _vm._s(_vm.syndicatorEndDateValue) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal",
                                  value: `${_vm.snippet.id}-partners-live`,
                                  expression: "`${snippet.id}-partners-live`",
                                },
                              ],
                              staticClass:
                                "btn btn-danger w-100 cs-plain-btn cs-btn-no-border mt-4",
                              attrs: { disabled: false },
                            },
                            [
                              _c("b-icon-pencil", {}),
                              _vm._v(" See Partners/Set End Dates "),
                              _c("b-badge", { attrs: { variant: "dark" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.selectedPartners.length) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          !_vm.selectedPartners.length
                            ? _c(
                                "b-card",
                                {
                                  staticClass: "mt-2",
                                  attrs: {
                                    align: "center",
                                    "border-variant": "danger",
                                    "text-variant": "danger",
                                  },
                                },
                                [
                                  _c("b-card-text", [
                                    _vm._v(" No Partners have been selected. "),
                                    _c("br"),
                                  ]),
                                  _c(
                                    "b-card-text",
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "exclamation-triangle-fill",
                                          scale: "2",
                                          variant: "danger",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "bottom-container mt-auto" }, [
                        _c("hr"),
                        _vm.updatedAt
                          ? _c("div", { staticClass: "cs-edit-history" }, [
                              _vm._v(
                                " Edited on " + _vm._s(_vm.updatedAt) + " | "
                              ),
                              _vm.user.canViewReport
                                ? _c("span", [
                                    _vm._v(" | "),
                                    _c("a", { attrs: { href: "" } }, [
                                      _c(
                                        "i",
                                        [
                                          _vm._v("See Original "),
                                          _c("b-icon-eye"),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "cs-edit-history" }, [
                          _vm.user.canViewReport || _vm.user.canEdit
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal",
                                      value: `${_vm.snippet.id}-clicks`,
                                      expression: "`${snippet.id}-clicks`",
                                    },
                                  ],
                                  attrs: { role: "button", tabindex: "0" },
                                },
                                [
                                  _c("Icon", {
                                    attrs: { name: "analytics-graph" },
                                  }),
                                  _c("b", { staticClass: "pl-2 mt-1" }, [
                                    _vm._v("Views Report"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "cs-edit-history" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-dark w-100 cs-black-btn p-2 mt-4",
                              attrs: { disabled: !_vm.user.canEdit },
                              on: {
                                click: function ($event) {
                                  return _vm.sendBackToDraft(_vm.snippet.id)
                                },
                              },
                            },
                            [_vm._v(" Send Back to Drafts ")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: `${_vm.snippet.id}-partners-live`,
            size: "lg",
            title: "Partners Receiving This Snippet",
            centered: "",
            "hide-footer": true,
            "header-class": "mchp-edit-header",
          },
        },
        [
          _c("b-overlay", { attrs: { show: _vm.isUpdating } }, [
            _c(
              "div",
              [
                _c("p", [
                  _vm._v(
                    " Partners who will receive this snippet are listed below. You can also set the end date individually for each partner below. "
                  ),
                ]),
                _c("hr"),
                _c(
                  "b-table-simple",
                  { attrs: { responsive: "", "sticky-header": "" } },
                  [
                    _c(
                      "b-thead",
                      { attrs: { "head-variant": "dark" } },
                      [
                        _c(
                          "b-tr",
                          [
                            _c("b-th", { attrs: { colspan: "2" } }, [
                              _vm._v("PARTNERS RECEIVING THIS SYNDICATION"),
                            ]),
                            _c("b-th", [_vm._v("END DATE")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-tbody",
                      _vm._l(_vm.modalPartnerList, function (pitem) {
                        return _c(
                          "b-tr",
                          { key: pitem.partnerId },
                          [
                            _c("b-td", [_vm._v(_vm._s(pitem.name))]),
                            _c(
                              "b-td",
                              { staticClass: "text-right" },
                              [
                                _c("b-form-checkbox", {
                                  attrs: { value: pitem, disabled: "" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.chOnChange(pitem)
                                    },
                                  },
                                  model: {
                                    value: _vm.selectedPartners,
                                    callback: function ($$v) {
                                      _vm.selectedPartners = $$v
                                    },
                                    expression: "selectedPartners",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-td",
                              [
                                _c("b-form-datepicker", {
                                  attrs: {
                                    id: `picker-end-date-${pitem.partnerId}-${pitem.snippetId}`,
                                    min: _vm.minEndDate,
                                    disabled: !_vm.user.canEdit,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.syndicatorEndDate()
                                    },
                                  },
                                  model: {
                                    value: pitem.end_date,
                                    callback: function ($$v) {
                                      _vm.$set(pitem, "end_date", $$v)
                                    },
                                    expression: "pitem.end_date",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "button",
            {
              staticClass: "btn btn-danger w-100 p-2",
              on: {
                click: function ($event) {
                  return _vm.savePartner()
                },
              },
            },
            [_vm._v(" Save ")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: `${_vm.snippet.id}-edit`,
            size: "lg",
            title: "Edit Snippet",
            centered: "",
            "hide-footer": true,
            "header-class": "mchp-edit-header",
          },
        },
        [
          _c("EditSnippets", {
            attrs: { propSnippet: _vm.snippet },
            on: { editedSummarySaved: _vm.savingUpdatedSnippet },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: `${_vm.snippet.id}-clicks`,
            centered: "",
            "hide-footer": "",
            "header-class": "mchp-edit-header",
            title: "Total Number of Views",
          },
        },
        [
          _c("AnalyticGraph", {
            attrs: { campaignId: _vm.snippet.ga_campaign_id },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }