
import {
    Component,
    Prop, 
    Vue
} from 'vue-property-decorator';
import { 
    getPartnersFromServer, 
    editPartnerFromServer,
    updateContactFromServer,
    getPartnerVFilterConstants,
    getOwners,
    setNewAPIKey
} from "@/components/partners/utils";
import { getUserBusinessUnit } from "@/components/syndications/utils";
import { Country, State }  from 'country-state-city';
import { SnippetOptionsEnum } from "./../../../../enums";

interface stashPartnerDataType {
    address_1: string,
    company_url: string,
    country: string,
    company_name: string,
    partner_type: number,
    snippet_limit: number,
    user_id: number,
    owner_uuid:string,
    contact: {
        name: string,
        email: string,
        phone_number: string
    }
}

@Component({
    data() {
        return {
            selected: null
        }
    },
    components: {},
    computed: {},
    methods: {}
})
export default class EditPartner extends Vue {
    @Prop() public role: any | undefined
    @Prop({default: "" }) id: any;
    @Prop({default: "" }) partner_item: any;
    @Prop({default: "" }) page_is_active: any;

    public activePartnerList: any = null;
    public partnerTypes:any = null;
    public snippetLimitOptions:Array<number> = Object.assign([], SnippetOptionsEnum['SNIPPET_LIMITS']);
    public errors:any = [];
    public country:any = [];
    public newCountryList:any = []
    public state:any = [];
    public name:string = "";
    public stateFinalStorage:any = [];
    public owners:any = [];
    public ownersID:any = [];
    public userBU:any = null;
    public partnerCardData:any = [];
    public apiKey:string = "";
    public counterDegree:number = 180;
    public contactDataFromProps:any = {
        name: '',
        email: '',
        phone_number: ''
    }
    public isOwnerEmpty:boolean = false;
    public partnerStashedData:stashPartnerDataType = {
        address_1: '',
        company_url: '',
        country: '',
        company_name: '',
        partner_type: 1,
        snippet_limit: 10,
        user_id: 0,
        owner_uuid: "",
        contact: {
            name: '',
            email: '',
            phone_number: ''
        }
    };

    async mounted(){
        this.activePartnerList = await getPartnersFromServer(this.page_is_active);
        this.partnerTypes = await getPartnerVFilterConstants();
        this.country = Country.getAllCountries();
        this.userBU = await getUserBusinessUnit();
        this.owners = await getOwners();
        this.partnerCardData = Object.assign({}, this.partner_item);
        this.changeCountryState();
        this.combineNameFromProps();
        this.filterOwners();
        this.filterCountry();
        this.loadAPIKeyFromLocalStorage();
        this.stashPartnerData();
        this.filterSnippetLimitOptions();
    }

    stashPartnerData():void {
        this.partnerStashedData = {
            address_1: this.partner_item.address_1,
            company_url: this.partner_item.company_url,
            country: this.partner_item.country,
            company_name: this.partner_item.company_name,
            partner_type: this.partner_item.partner_type,
            snippet_limit: this.partner_item.snippet_limit,
            user_id: this.partner_item.user_id,
            owner_uuid: "",
            contact: {
                name: this.partner_item.contact.first_name + " " + this.partner_item.contact.last_name,
                email: this.partner_item.contact.email,
                phone_number: this.partner_item.contact.phone_number
            }
        }
    }

    combineNameFromProps() {
        this.contactDataFromProps = {
            name: this.partner_item.contact.first_name + " " + this.partner_item.contact.last_name,
            email: this.partner_item.contact.email,
            phone_number: this.partner_item.contact.phone_number
        }
    }

    filterOwners() {
        const data = this.owners.filter((item:any) => {
            return item
        })
        
        const filterID = data.map((item:any) => {
            return { value: item.id, text: item.email }
        })

        filterID.unshift({ value: null, text: 'Select' })
        this.ownersID = filterID
    }

    loadAPIKeyFromLocalStorage() {
        const data = localStorage.getItem(`storage_api_key-${this.partnerCardData.id}`);
        this.apiKey = data !== null ? data : '';
    }

    async renewAPIKey(e:Event, elementID:string) {
        e.preventDefault();
        const { id, company_name } = this.partnerCardData;
        const { api_key } = await setNewAPIKey({name: company_name,partner: id});
        this.apiKey = api_key;

        localStorage.setItem(`storage_api_key-${id}`, this.apiKey)
        
        const targetElement = document.querySelector<HTMLElement>(`#${elementID} svg`)!;
        targetElement.style.transform = `rotate(${this.counterDegree}deg)`
        this.counterDegree += 180;
    }

    copyAPIKey(e:Event, copyAlertID:any) {
        e.preventDefault();
        navigator.clipboard.writeText(this.apiKey);
        let alertHTML = document.getElementById(`${copyAlertID}`)!;
        if (alertHTML.classList.contains('hide')) {
            alertHTML.classList.remove('hide')
        }
        setTimeout(()=> alertHTML.classList.add('hide'), 2000)
    }

    get userRole() {
        return this.role
    }

    get filterPartnersCompany() {
        if(this.activePartnerList?.results) {
            const data = this.activePartnerList.results
            return data?.map((item:any ) => {
                return item.company_name
            })
        }
        return []
    }

    get filterPartnerTypes() {
        if(this.partnerTypes?.results.partner_type) {
            const data = this.partnerTypes.results.partner_type
            return data?.map((item:any ) => {
                return item
            })
        }
        return []
    }

    public filterSnippetLimitOptions() {
        // Check if partner data has already snippet limit
        if (
            this.partnerCardData?.snippet_limit
            && this.partnerCardData?.snippet_limit >= 0
            && !this.snippetLimitOptions.includes(this.partnerCardData?.snippet_limit)
        ) {
            // Append to options
            this.snippetLimitOptions.push(this.partnerCardData.snippet_limit)
            // Sort to ascending
            this.snippetLimitOptions.sort((first, second) => {
                return first - second;
            })
        }
    }

    filterCountry() {
        const USObject = { value: "US", text: "United States" };
        const countryList = this.country.filter((item:any) => {
            return item.name !== USObject.text ? { value: item.isoCode, text: item.name } : ''
        })
        const finalData = countryList.map((item:any) => {
            return { value: item.isoCode, text: item.name }
        })
        finalData.unshift(USObject)
        finalData.unshift({ value: null, text: 'Select', disabled:true })
        this.newCountryList = finalData
    }

    // Changing of state is only available for United States, based in the back end. Endpoint won't accept any state except from US.
    changeCountryState() {
        // this.state = getStatesOfCountry(this.changeDataset.country) // General
        this.state = State.getStatesOfCountry('US')
        this.stateFinalStorage = this.state?.map((item:any) => {
            let data = {
                value: item.isoCode,
                text: item.name
            }
            return data
        })
    }

    async checkEditPartnerForm(e:any) {
        e.preventDefault();
        this.errors = [];

        if (!this.partnerCardData.company_name) { this.errors.push('Company name is required.'); }
        if (!this.partnerCardData.address_1) { this.errors.push('Address 1 is required.'); }
        if (!this.partnerCardData.company_url) { this.errors.push('Company URL is required.'); }
        if (!this.contactDataFromProps.name) { this.errors.push('Contact name is required.'); }
        if (!this.contactDataFromProps.email) { this.errors.push('Email is required.'); }
        if (!this.contactDataFromProps.phone_number) { this.errors.push('Phone number is required.'); }
        if (!this.partnerCardData.snippet_limit) { this.errors.push('Max number of snippet is required.'); }
        if (!this.partnerCardData.partner_type) { this.errors.push('Partner type is required.'); }
        if (!this.partnerCardData.country) { this.errors.push('Country is required.'); }

        if (this.partnerCardData.company_name &&
            this.partnerCardData.address_1 &&
            this.partnerCardData.company_url &&
            this.partnerCardData.country &&
            this.contactDataFromProps.name &&
            this.contactDataFromProps.email &&
            this.contactDataFromProps.phone_number) {
            
            const fname = this.contactDataFromProps.name.split(" ").splice(-2)[0];
            let lname = this.contactDataFromProps.name.split(" ").splice(-1)[0];
            if (lname === fname) {
                lname = ""
            } else if (lname === "" || lname === " ") {
                lname = ""
            } else {
                lname = this.contactDataFromProps.name.split(" ").splice(-1)[0]
            }

            let contactData = {
                last_name: lname,
                first_name: fname,
                email: this.contactDataFromProps.email,
                phone_number: this.contactDataFromProps.phone_number.includes('+')
                    ? `${this.contactDataFromProps.phone_number}`
                    : `+${this.contactDataFromProps.phone_number}`
            }

            // Removed contact object from the updated information to prevent the request from return 500 Internal Server Error.
            let {contact, ...newData} = this.partnerCardData;
            
            const liveData:stashPartnerDataType = {
                address_1: this.partnerCardData.address_1,
                company_url: this.partnerCardData.company_url,
                country: this.partnerCardData.country,
                company_name: this.partnerCardData.company_name,
                partner_type: this.partnerCardData.partner_type,
                snippet_limit: this.partnerCardData.snippet_limit,
                user_id: this.partnerCardData.user_id,
                owner_uuid: "",
                contact: {
                    name: this.contactDataFromProps.name,
                    email: this.contactDataFromProps.email,
                    phone_number: this.contactDataFromProps.phone_number
                }
            }
            const stashed:stashPartnerDataType = this.partnerStashedData
            
            await editPartnerFromServer(this.partnerCardData.id, this.partnerCardData.contact.id, contactData, newData, liveData, stashed)
            // await updateContactFromServer(this.partnerCardData.contact.id, contactData, liveData, stashed)
        }
    }
}
