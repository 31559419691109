
import {Component, Vue, Prop} from 'vue-property-decorator';
import {getSnippetVFilterConstants} from "@/components/syndications/utils";
import {getModule} from "vuex-module-decorators";
import FilterModule from "@/store/Filter";
import SearchBar from "./SearchBar.vue"


@Component({
  name: "FilterBarSyndication",
  components: {SearchBar}
})
export default class FilterBarSyndication extends Vue {
  @Prop({ default: "" }) readonly fieldDisplay: any;
  @Prop({ default: [] }) readonly dataList: any | Array<any>;
  @Prop({ default: "" }) businessUnits: any;

  public resetSearchBar: any = "";
  public vfilters: any = null;
  public businessUnitFilters: any = null;
  public filledSearchTerm: string = '';
  public selectedContentTypeFilter: string = '';
  public selectedBusinessUnit: any = localStorage.getItem("userBusinessUnit")?.toLowerCase();
  public selectPartnerType: string = '';

  // store
  store = getModule(FilterModule, this.$store);

  async mounted() {
    const resp_data = await getSnippetVFilterConstants();
    this.vfilters = resp_data.results;

    const isFromDashboard = localStorage.getItem("isFromDashboard");
    if (isFromDashboard === 'true') {
      this.store.setBusinessUnit(this.selectedBusinessUnit);
    } else {
      this.resetFilter()
    }
  }

  get contentTypeOptions(){
    if(this.vfilters?.content_type){
      const nullVal = {"text": "Filter", "value": ''};
      return [nullVal, ...this.vfilters.content_type];
    }
    return {};
  }

  get businessUnitsOptions(){
    let businessUnitOptions = [{"text": "Filter", "value": ''}];

    if (this.businessUnits) {
      for (let [key, value] of Object.entries<any>(this.businessUnits)) {
        businessUnitOptions.push({"text": value['title'], "value": key});
      }
    }

    return businessUnitOptions;
  }

  get partnerTypeOptions(){
    if(this.vfilters?.partner_type){
      const nullVal = {"text": "Filter", "value": ''};
      return [nullVal, ...this.vfilters.partner_type.sort((a: any, b: any) => a.text.localeCompare(b.text))];
    }
    return {};
  }

  async onSelect(payload : any) {
    this.store.setSearchTerm(payload?.title ?? "");
  }

  submitContentType(e: Event): void{
    this.store.setContentType(this.selectedContentTypeFilter);
  }

  submitBusinessUnit(e: Event): void{
    this.store.setBusinessUnit(this.selectedBusinessUnit);
  }

  submitPartnerType(e: Event): void{
    this.store.setPartnerType(this.selectPartnerType);
  }

  resetFilter(): void{
    this.store.setSearchTerm('');
    this.filledSearchTerm = '';
    this.resetSearchBar = Math.floor(Math.random() * 1000);

    this.store.setContentType('');
    this.selectedContentTypeFilter = '';

    this.store.setBusinessUnit('');
    this.selectedBusinessUnit = '';

    this.store.setPartnerType('');
    this.selectPartnerType = '';

    this.store.setResetSearchFlag(true);
    localStorage.setItem("userBusinessUnit", '')
    this.submitBusinessUnit(this.selectedBusinessUnit)
    this.store.setBusinessUnit(this.selectedBusinessUnit);
  }

  hideSearchIcon() {
    const iconSearch = document.querySelector(".cs-syndicator-filter-icon-group");
    const iconClose = document.querySelector(".cs-partner-close-icon-group");
    if (this.filledSearchTerm !== '') {
      iconSearch?.classList.add('hide')
      iconSearch?.classList.remove('show')
      iconClose?.classList.remove('hide')
      iconClose?.classList.add('show')
      
    } else {
      iconSearch?.classList.remove('hide')
      iconSearch?.classList.add('show')
      iconClose?.classList.add('hide')
      iconClose?.classList.remove('show')
      this.clearFilter()
    }
  }

  clearPreventDefault(e:Event) {
    e.preventDefault();
    this.filledSearchTerm = ''
    const iconSearch = document.querySelector(".cs-syndicator-filter-icon-group");
    const iconClose = document.querySelector(".cs-partner-close-icon-group");
    if (this.filledSearchTerm !== '') {
      iconSearch?.classList.add('hide')
      iconSearch?.classList.remove('show')
      iconClose?.classList.remove('hide')
      iconClose?.classList.add('show')
      
    } else {
      iconSearch?.classList.remove('hide')
      iconSearch?.classList.add('show')
      iconClose?.classList.add('hide')
      iconClose?.classList.remove('show')
      this.clearFilter()
    }
  }

  clearFilter() {
    if (this.filledSearchTerm === '') {
      this.resetFilter()
    }
  }
}
