
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import EditSnippets from '../components/EditSnippets.vue';
import EditTitle from "../components/EditTitle.vue";
import EditImage from "../components/EditImage.vue";
import EditGAcampaign from '../components/EditGAcampaign.vue';
import EditBusinessUnit from "../components/EditBusinessUnit.vue";
import EditContentType from "../components/EditContentType.vue";
import ElemURL from "../components/ElemURL.vue";
import {getModule} from "vuex-module-decorators";
import FilterModule from "@/store/Filter";
import ModalOriginalSummary from "./../common/ModalOriginalSummary.vue"
import {getPartnerListDetailFromServer} from "@/components/syndications/utils";

@Component({
  name: 'QueueCards',
  components: {
    EditSnippets,
    EditTitle,
    EditImage,
    EditGAcampaign,
    EditBusinessUnit,
    EditContentType,
    ElemURL,
    ModalOriginalSummary
  }
})
export default class QueueCards extends Vue {
  @Prop() readonly user: any | undefined
  @Prop({default: ""}) item: any;
  @Prop({default: ""}) propPartnerList: any;
  @Prop({default: false}) isSearchResult: any;
  @Prop({default: false}) isSyndicatedSnippetPage: any;
  @Prop({default: ""}) snippetDetail: any;
  @Prop({default: ""}) businessUnits: any;

  public QUEUED_STATE = 2;
  public isUpdating: boolean = false;
  public snippet: any = null;
  public partnerList: any = null;
  public hasPartnerUpdate = false;

  filterStore = getModule(FilterModule, this.$store);

  private DRAFT_STATE: number = 1;
  private selectedPartners: any = [];
  private removedPartners: any = [];
  private modalPartnerList: any = [];

  public onShowModal(payload : any) {
    const params = {text: payload.original_summary, id: payload.id};
    const ref = this.$refs!.refSeeOriginal ?? "";
    ref["onShowModal"](params);
  }

  public closeModal(e: Event): void {
    this?.$bvModal.hide(e['modalId']);
  }

  public savingUpdatedSnippet(e: Event): void{
    let ref_id = `${e['snippet'].id}-edit`;
    this?.$bvModal.hide(ref_id);

    if(this.snippet.snippet_partners.length != 0){
      this.snippet.snippet_partners[0]['start_date'] = e['start_date'];
      this.snippet.snippet_partners[0]['end_date'] = e['end_date'];
    }

    this.$emit("updateSnippetSaved", e);
  }

  public savingUpdatedGaCampaignCode(e: Event): void{
    // e: {gaCampaignCode, snippetId}
    let ref_id = `${e['snippetId']}-gacampaign`;
    this?.$bvModal.hide(ref_id);

    let gaCode = e['gaCampaignCode'];
    this.snippet.ga_campaign_id = gaCode;

    this.$emit("updateGaCampaignCodeSaved", {gaCampaignCode: gaCode, id: e['snippetId']});
  }

  public savingUpdatedBusinessUnit(e: Event): void {
    // Close Modal
    this?.$bvModal.hide(e['modalId']);
    // Update current business unit
    this.snippet.business_unit = e['businessUnit'];

    this.$emit('updateBusinessUnitSaved', {
      id: e['snippetId'],
      businessUnit: e['businessUnit']
    });
  }

  public savingUpdatedContentType(e: Event): void {
    // Close Modal
    this?.$bvModal.hide(e['modalId']);
    // Update current business unit
    this.item.content_type = e['contentType'];

    this.$emit('updateContentTypeSaved', {
      id: e['snippetId'],
      contentType: e['contentType']
    });
  }

  public savingUpdateSnippetTitle(e: Event):void {
    let ref_id = `${e["snippetId"]}-snippet-title`;
    this?.$bvModal.hide(ref_id);

    let snip_title = e["title"];
    this.snippet.title = snip_title;

    this.$emit("updateSnippetTitleSaved", { title: snip_title, id: e["snippetId"] })
  }

  public savingUpdateSnippetImage(e: Event): void {
    // Close Modal
    this?.$bvModal.hide(e['modalId']);

    // Update only if original_path is empty
    if (!this.snippet.images[0].original_path) {
      this.snippet.images[0].original_path = this.snippet.images[0].path
    }
    // Update current image
    this.snippet.images[0].path = e['selectedImageData'].path
    // Update current image from db
    this.$emit('updateSnippetImageSaved', {
      snippetId: e['snippetId'],
      snippetImageId: e['snippetImageId'],
      selectedImageData: e['selectedImageData']
    })
  }

  public savingAddSnippetImage(e: Event): void {
    // Close Modal
    this?.$bvModal.hide(e['modalId']);

    // Update current image
    this.snippet.images = [{
      path: e['selectedImageData'].path,
      original_path: e['selectedImageData'].path
    }];

    // Update current image from db
    this.$emit('addSnippetImageSaved', {
      snippetId: e['snippetId'],
      selectedImageData: e['selectedImageData']
    })
  }

  public sendBackToDraft(id: number): void{
    this.$emit("snippetBackToDraft", {id: id, payload: {"content_state": this.DRAFT_STATE}});
  }

  public async savePartner(){
    this.isUpdating = true;
    this.hasPartnerUpdate = true;
    await this.$emit("updateSnippetPartner", {selected: this.selectedPartners, remove: this.removedPartners, replaceStartDate: this.snippet.snippet_partners[0]['start_date']})

    // close modal
    let modalId = `${this.snippet.id}-partners`;
    this.$bvModal.hide(modalId);
    this.removedPartners = [];

    this.isUpdating = false;
    this.hasPartnerUpdate = false;
  }

  public async onHide(){
    // Revert Partner Updates
    if(!this.hasPartnerUpdate) {
      this.removedPartners = [];
      this.selectedPartners = [];
      this.snippet = this.item
      this.partnerList = this.propPartnerList;
      await this.computePartnerList()
    }
  }

  public findPartnerSnippetEndDate(partner_id: number, snippet_id: number): any {
    const partner = this.partnerList.results.find((e: any) => e.id == partner_id);
    const partner_snippet = partner.partner_snippets.find((e: any) => e.snippet.id == snippet_id);
    let end_date = new Date(partner_snippet?.end_date) ?? "";
    let queue_end_date = this.snippet.snippet_partners[0]?.end_date ?? ""
    const queueCardSnippetEndDate = queue_end_date ? new Date(queue_end_date)?.toISOString()?.split('T')[0] : "";
    return this.isValidDate(end_date) ? end_date.toISOString().split('T')[0] : queueCardSnippetEndDate;
  }

  public findPartnerSnippetStartDate(partner_id: number, snippet_id: number): any {
    const partner = this.partnerList.results.find((e: any) => e.id == partner_id);
    const partner_snippet = partner.partner_snippets.find((e: any) => e.snippet.id == snippet_id);
    let start_date = new Date(partner_snippet?.start_date);

    return this.isValidDate(start_date) ? start_date.toISOString().split('T')[0] : new Date().toISOString().split('T')[0];
  }

  public isValidDate(d: any): boolean{
    return !isNaN(d.getTime());
  }

  public checkIfSelected(partner_id: number){
    const found: any = this.item.partners.find((e: any) => {return e.id == partner_id});
    return found ? true : false;
  }

  public computePartnerList(): void {
    if (this.partnerList != null) {   // there are partners from the server
      let partner_set = this.partnerList.results.map((e: any) => {  // loop over and match with snippet partners
        let item = {
          partnerId: e.id,
          name: e.company_name,
          snippetId: this.snippet.id,
          is_selected: this.checkIfSelected(e.id),
          start_date: this.findPartnerSnippetStartDate(e.id, this.snippet.id),
          end_date: this.findPartnerSnippetEndDate(e.id, this.snippet.id)
        };
        if(item.is_selected){
          const matched = this.item.snippet_partners.find((e: any) => e.partner == item.partnerId);

          if(matched){
            item["syndicatorId"] =  matched.id;
          }
          this.selectedPartners.push(item);
        }

        return item;
      });

      this.modalPartnerList = partner_set;
    }
  }

  buttonDisabled():void {
    let buttonDisabled = true
    const id = this.modalPartnerList[0].snippetId;
    try {
      const data = this.modalPartnerList.filter((item:any) => {
        return item.is_selected === true
      })
      data.length > 0 ? buttonDisabled = false : buttonDisabled = true
    } catch (e) {
      console.log(e)
    } finally {
      const btnElement = document.getElementById(`${id}-queue-save-btn`)!;
      if (buttonDisabled) {
        btnElement.setAttribute('disabled', '')
      }
    }

  }

  public async chOnChange(pItem: any, snippetId: any){
    const found = this.removedPartners.find((e: any) => e.partnerId == pItem.partnerId);
    const detail = await getPartnerListDetailFromServer(pItem.partnerId)
    const btnElement = document.getElementById(`${snippetId}-queue-save-btn`)!;
    const noteElement = document.getElementById(`${pItem.partnerId}-snippet-limit`)!
    const snippetLimit = detail.snippet_limit;
    const countSnippetPartners = detail.partner_snippets.length + 1
    const snippetTotal = countSnippetPartners

    try {
      if(this.checkIfSelected(pItem.partnerId) && !found ){
        this.removedPartners.push(pItem);
      } else {
        this.removedPartners.pop();
      }
    } catch(e) {
      console.log(e)
    }
    finally {
      if (this.selectedPartners.length > 0) {
        if (snippetTotal > snippetLimit) {
          const items = this.selectedPartners.filter((item:any) => {
            return item.partnerId === pItem.partnerId
          })
          if (items.length > 0) {
            btnElement.setAttribute('disabled', '')
            noteElement.classList.remove('hide')
          } else {
            btnElement.removeAttribute('disabled')
            noteElement.classList.add('hide')
            
            let ishideExisting = true
            const modalElement = document.getElementById(`${snippetId}-partners`)!;
            const snippetNote = modalElement.querySelectorAll(".limit-note")!
            snippetNote.forEach((item,index) => {
              if(!item.classList.contains('hide')) {
                ishideExisting = false
              }
            })
            if (!ishideExisting) {
              btnElement.setAttribute('disabled', '')
            } else {
              btnElement.removeAttribute('disabled')
            }

          }

        } else {
          btnElement.removeAttribute('disabled')
          noteElement.classList.add('hide')

          let ishideExisting = true
          const modalElement = document.getElementById(`${snippetId}-partners`)!;
          const snippetNote = modalElement.querySelectorAll(".limit-note")!
          snippetNote.forEach((item,index) => {
            if(!item.classList.contains('hide')) {
              ishideExisting = false
            }
          })

          if (!ishideExisting) {
            btnElement.setAttribute('disabled', '')
          } else {
            btnElement.removeAttribute('disabled')
          }
        }
      } else {
        btnElement.setAttribute('disabled', '')
        noteElement.classList.add('hide')
      }
    }
  }

  get searchFlag(): boolean{
    return this.isSearchResult;
  }

  get syndicatorStartDate(): string{
    if(this.snippet.length != 0 && this.isSyndicatedSnippetPage === false){
      try {
        return this.snippet.snippet_partners[0]['start_date']
      } catch(err) {
        return '---'
      }
    }
    return this.item.snippet_partners[0]['start_date'];
  }

  get syndicatorEndDate(): string{
    if(this.snippet.length != 0 && this.isSyndicatedSnippetPage === false){
      try {
        let endDate = this.snippet.snippet_partners[0]['end_date']
        let isSameEndDate = this.selectedPartners.every((partner: any) => partner.end_date === endDate);
        if(this.selectedPartners.length > 1 && !isSameEndDate) return "varies by partner"
        return this.snippet.snippet_partners[0]['end_date']
      } catch(err) {
        return '---'
      }
    }
    return this.item.snippet_partners[0]['end_date'];
  }

  get searchTermArray(): Array<string> {
    let queries = this.filterStore.searchTermValue?.split(" ") ?? [];
    return queries;
  }

  get minDate(): Date {
    return new Date( new Date(this.syndicatorStartDate).getTime() + ( 3600 * 1000 * 24))
  }

  get maxDate(): Date {
    const max = ( 3600 * 1000 * 24) * 365 * 3;
    return new Date( new Date(this.syndicatorStartDate).getTime() + max)
  }

  get updatedAt(): any {
    let date = new Date(this.snippet.updated_at).toISOString().split("T")[0];
    return date;
  }

  get snippetBusinessUnit(): any {
    return this.businessUnits[this.snippet.business_unit]
      ? this.businessUnits[this.snippet.business_unit]?.title
      : this.snippet.business_unit
  }

  created(){
    this.snippet = this.item
    this.partnerList = this.propPartnerList;
  }

  mounted(){
    this.computePartnerList();
  }

  beforeUpdate() {
    this.snippet.id === this.snippetDetail.id
      ? (this.snippet = this.snippetDetail)
      : (this.snippet = this.item);
  }
}
