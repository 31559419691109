var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "elem-url" } }, [
    _c("span", [_vm._v(_vm._s(_vm.urlString))]),
    _c(
      "div",
      { staticClass: "block-inline", on: { click: _vm.copyToClipboard } },
      [
        _c("span", { staticClass: "cs-copy-icon ml-2" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "23",
                height: "24",
                viewBox: "0 0 23 24",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M9.60832 21.087H1.95648C1.42823 21.087 1 20.6588 1 20.1305V4.82684C1 4.29859 1.42823 3.87036 1.95648 3.87036H3.86944",
                  stroke: "#53575A",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M13.4343 3.87036H15.3473C15.8755 3.87036 16.3038 4.29859 16.3038 4.82684V8.17452",
                  stroke: "#53575A",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M8.65176 3.86944C9.44414 3.86944 10.0865 3.22709 10.0865 2.43472C10.0865 1.64235 9.44414 1 8.65176 1C7.85939 1 7.21704 1.64235 7.21704 2.43472C7.21704 3.22709 7.85939 3.86944 8.65176 3.86944Z",
                  stroke: "#53575A",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M10.0052 2.91388H12.4777C13.006 2.91388 13.4342 3.34211 13.4342 3.87036V4.82684C13.4342 5.35509 13.006 5.78332 12.4777 5.78332H4.82586C4.29762 5.78332 3.86938 5.35509 3.86938 4.82684V3.87036C3.86938 3.34211 4.29762 2.91388 4.82586 2.91388H7.29837",
                  stroke: "#53575A",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M22.0425 22.0435C22.0425 22.5718 21.6143 23 21.086 23H12.4777C11.9495 23 11.5212 22.5718 11.5212 22.0435V11.044C11.5212 10.5158 11.9495 10.0875 12.4777 10.0875H19.2553C19.509 10.0875 19.7522 10.1884 19.9316 10.3678L21.7623 12.1985C21.9417 12.3778 22.0425 12.6211 22.0425 12.8747V22.0435Z",
                  stroke: "#53575A",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M14.3906 14.3907H19.173",
                  stroke: "#53575A",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
              _c("path", {
                attrs: {
                  d: "M14.3906 17.2601H19.173",
                  stroke: "#53575A",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                },
              }),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }