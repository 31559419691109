var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticStyle: { "font-weight": "normal" } },
    [
      _c(
        "vue-typeahead-bootstrap",
        {
          attrs: { data: _vm.addOnList, minMatchingChars: 1 },
          on: {
            hit: function ($event) {
              return _vm.onSelect(_vm.selected)
            },
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _c("template", { slot: "append" }, [
            _vm.selected
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    staticStyle: {
                      border: "1px solid #ced4da",
                      "border-left": "1px solid rgb(255, 255, 255)",
                      padding: "0 10px 0 5px",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onSelect("")
                      },
                    },
                  },
                  [_c("b-icon", { attrs: { icon: "x" } })],
                  1
                )
              : _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    staticStyle: {
                      border: "1px solid #ced4da",
                      "border-left": "1px solid rgb(255, 255, 255)",
                      padding: "0 10px 0 5px",
                    },
                  },
                  [_c("b-icon", { attrs: { icon: "search" } })],
                  1
                ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }