const SERVER_URL = getServerURL();
const API_SERVER_URL = process.env.VUE_APP_API_SERVER_URL ?? "http://localhost:8000/api/v1";
const AEM_PUBLISH_BASE_URL = process.env.VUE_APP_AEM_PUBLISH_BASE_URL
  ?? 'http://chn-vm-aemqapub.mchp-main.com:4503';

function getServerURL() {
  const NODE_ENV = process.env.NODE_ENV;

  if (NODE_ENV === "development" || NODE_ENV === "production" || NODE_ENV === "staging") {
    return window.location.origin
  } else {
    return "http://localhost:8000"
  }
}

export {
  AEM_PUBLISH_BASE_URL,
  API_SERVER_URL,
  SERVER_URL
}