const SnippetOptionsEnum = Object.freeze({
  SNIPPET_LIMITS: [25,50,75,100],
  SNIPPET_CONTENT_TYPES: [
    {
      value: 'blog',
      text: 'blog',
      description: 'Article'
    },
    {
      value: 'dev-tool',
      text: 'dev-tool',
      description: 'Development Tools Pages'
    },
    {
      value: 'npi',
      text: 'npi',
      description: 'Product Overview Content Pages'
    },
    {
      value: 'reference-design',
      text: 'reference-design',
      description: 'Reference Design'
    },
    {
      value: 'product-pages',
      text: 'product-pages',
      description: 'Product Pages'
    },
    {
      value: 'video',
      text: 'video',
      description: 'Video'
    },
    {
      value: 'data-sheet',
      text: 'data-sheet',
      description: 'Data Sheet'
    }
  ],
});

export default SnippetOptionsEnum;
