var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "overflow-hidden mb-3", attrs: { "no-body": "" } },
        [
          _c(
            "b-card-body",
            [
              _c(
                "b-row",
                {
                  staticClass: "snippet-container",
                  attrs: { "no-gutters": "", "align-v": "stretch" },
                },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "p-2 border-right d-flex flex-column",
                      attrs: { md: "8" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "top-container" },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { sm: "6" } }, [
                                _c("div", { staticClass: "mr-auto" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "cs-left-header",
                                      class:
                                        _vm.snippet.images.length !== 0
                                          ? "has-image-adjustment"
                                          : "",
                                    },
                                    [
                                      _vm.snippet.images.length !== 0
                                        ? _c(
                                            "div",
                                            [
                                              _c("b-card-img", {
                                                directives: [
                                                  {
                                                    name: "b-modal",
                                                    rawName: "v-b-modal",
                                                    value: `${_vm.snippet.id}-edit-snippet-image`,
                                                    expression:
                                                      "`${snippet.id}-edit-snippet-image`",
                                                  },
                                                ],
                                                staticClass:
                                                  "cs-card-thumbnail rounded-0 p-2 mb-2",
                                                staticStyle: {
                                                  "max-width": "120px",
                                                  "max-height": "120px",
                                                },
                                                style: _vm.user.canEdit
                                                  ? ""
                                                  : "cursor: default; outline: none !important;",
                                                attrs: {
                                                  src: _vm.snippet.images[0]
                                                    .path,
                                                  alt: "Image",
                                                  thumbnail: "",
                                                  disabled: !_vm.user.canEdit,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                                staticStyle: {
                                                  outline: "none !important",
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  directives: [
                                                    {
                                                      name: "b-modal",
                                                      rawName: "v-b-modal",
                                                      value: `${_vm.snippet.id}-add-snippet-image`,
                                                      expression:
                                                        "`${snippet.id}-add-snippet-image`",
                                                    },
                                                  ],
                                                  staticClass: "mr-1",
                                                  style: !_vm.user.canEdit
                                                    ? ""
                                                    : "cursor: pointer; outline: none !important;",
                                                  attrs: {
                                                    icon: "card-image",
                                                    variant: "secondary",
                                                    "font-scale": "6",
                                                    disabled: !_vm.user.canEdit,
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold text-secondary",
                                                  },
                                                  [_vm._v("No Image")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c(
                                "b-col",
                                { staticClass: "ml-auto", attrs: { sm: "6" } },
                                [
                                  _c(
                                    "b-badge",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal",
                                          value: `${_vm.snippet.id}-content-type`,
                                          expression:
                                            "`${snippet.id}-content-type`",
                                        },
                                      ],
                                      staticClass:
                                        "cs-snippet-content-type pt-2 pb-2 pl-4 pr-4",
                                      style: !_vm.user.canEdit
                                        ? ""
                                        : "cursor: pointer; outline: none !important;",
                                      attrs: {
                                        pill: "",
                                        variant: "light",
                                        disabled: !_vm.user.canEdit,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.item.content_type) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("b-card-title", [
                                    _vm.searchFlag
                                      ? _c(
                                          "span",
                                          [
                                            _c(
                                              "text-highlight",
                                              {
                                                attrs: {
                                                  queries: _vm.searchTermArray,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.snippet.title)
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.snippet.title) +
                                              " "
                                          ),
                                        ]),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal",
                                            value: `${_vm.snippet.id}-snippet-title`,
                                            expression:
                                              "`${snippet.id}-snippet-title`",
                                          },
                                        ],
                                        staticClass:
                                          "btn btn-danger cs-plain-btn ml-3",
                                        attrs: { disabled: !_vm.user.canEdit },
                                      },
                                      [
                                        _c("b-icon-pencil", {}),
                                        _vm._v(" Edit "),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal",
                                            value: `original-title-${_vm.snippet.id}`,
                                            expression:
                                              "`original-title-${snippet.id}`",
                                          },
                                        ],
                                        staticClass: "cs-original-title",
                                        attrs: { id: "show-btn" },
                                      },
                                      [
                                        _c(
                                          "i",
                                          [
                                            _vm._v("See Original "),
                                            _c("b-icon-eye"),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("b-card-text", {
                                    staticClass: "cs-card-text",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.snippet.summary),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "bottom-container mt-auto" },
                        [
                          _c("hr"),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("div", { staticClass: "lower-title" }, [
                                  _vm._v("Syndication URL"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "lower-link" },
                                  [
                                    _c("ElemURL", {
                                      attrs: {
                                        "url-string": _vm.snippet.source_link,
                                        snippetID: _vm.snippet.id,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { cols: "6" } }, [
                                _c(
                                  "div",
                                  { staticClass: "lower-title mt-2 w-100" },
                                  [
                                    _vm._v(" GA Campaign "),
                                    _c("b-input-group", {
                                      staticClass: "align-bottom float-right",
                                      staticStyle: { width: "unset" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "append",
                                          fn: function () {
                                            return [
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-modal",
                                                      rawName: "v-b-modal",
                                                      value: `${_vm.snippet.id}-gacampaign`,
                                                      expression:
                                                        "`${snippet.id}-gacampaign`",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-danger cs-plain-btn mt-2",
                                                  attrs: {
                                                    disabled: !_vm.user.canEdit,
                                                  },
                                                },
                                                [
                                                  _c("b-icon-pencil", {}),
                                                  _vm._v(" Edit "),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  { staticClass: "cs-ga-campaign-text" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.snippet.ga_campaign_id) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("b-col", { attrs: { cols: "6" } }, [
                                _c(
                                  "div",
                                  { staticClass: "lower-title mt-2 w-100" },
                                  [
                                    _vm._v(" Business Unit "),
                                    _c("b-input-group", {
                                      staticClass: "align-bottom float-right",
                                      staticStyle: { width: "unset" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "append",
                                          fn: function () {
                                            return [
                                              _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-modal",
                                                      rawName: "v-b-modal",
                                                      value: `${_vm.snippet.id}-business-unit`,
                                                      expression:
                                                        "`${snippet.id}-business-unit`",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "btn btn-danger cs-plain-btn mt-2",
                                                  attrs: {
                                                    disabled: !_vm.user.canEdit,
                                                  },
                                                },
                                                [
                                                  _c("b-icon-pencil", {}),
                                                  _vm._v(" Edit "),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                                _vm.businessUnits
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.snippetBusinessUnit) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "p-2 d-flex flex-column",
                      attrs: { md: "4" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "top-container" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger w-100 cs-red-btn",
                              staticStyle: { cursor: "unset" },
                            },
                            [_vm._v("In Queue")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-danger w-100 cs-plain-btn mt-2",
                              staticStyle: { cursor: "unset" },
                            },
                            [
                              _vm._v(
                                "Start Date: " +
                                  _vm._s(_vm.syndicatorStartDate) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-danger w-100 cs-plain-btn mt-2",
                              staticStyle: { cursor: "unset" },
                            },
                            [
                              _vm._v(
                                "End Date: " +
                                  _vm._s(_vm.syndicatorEndDate) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal",
                                  value: `${_vm.snippet.id}-partners`,
                                  expression: "`${snippet.id}-partners`",
                                },
                              ],
                              staticClass:
                                "btn btn-danger w-100 cs-plain-btn cs-btn-no-border mt-4",
                              attrs: { disabled: !_vm.user.canEdit },
                            },
                            [
                              _c("b-icon-pencil", {}),
                              _vm._v(" Select Partners/Set End Dates "),
                              _c("b-badge", { attrs: { variant: "dark" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.selectedPartners.length) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          !_vm.selectedPartners.length
                            ? _c(
                                "b-card",
                                {
                                  staticClass: "mt-2",
                                  attrs: {
                                    align: "center",
                                    "border-variant": "danger",
                                    "text-variant": "danger",
                                  },
                                },
                                [
                                  _c("b-card-text", [
                                    _vm._v(" No Partners have been selected. "),
                                    _c("br"),
                                    _c(
                                      "a",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal",
                                            value: `${_vm.snippet.id}-partners`,
                                            expression:
                                              "`${snippet.id}-partners`",
                                          },
                                        ],
                                        attrs: {
                                          href: "#",
                                          disabled: !_vm.user.canEdit,
                                        },
                                      },
                                      [_vm._v("Select one now.")]
                                    ),
                                  ]),
                                  _c(
                                    "b-card-text",
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "exclamation-triangle-fill",
                                          scale: "2",
                                          variant: "danger",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "cs-edit-snippet-btn mt-3" },
                            [
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal",
                                      value: `${_vm.snippet.id}-edit`,
                                      expression: "`${snippet.id}-edit`",
                                    },
                                  ],
                                  attrs: {
                                    href: "#",
                                    disabled: !_vm.user.canEdit,
                                  },
                                },
                                [_vm._v("Edit Snippet")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "bottom-container mt-auto" }, [
                        _c("hr"),
                        _vm.updatedAt
                          ? _c("div", { staticClass: "cs-edit-history" }, [
                              _vm._v(
                                " Edited on " + _vm._s(_vm.updatedAt) + " | "
                              ),
                              _c(
                                "button",
                                {
                                  attrs: { id: "show-btn" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onShowModal(_vm.snippet)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    [_vm._v("See Original "), _c("b-icon-eye")],
                                    1
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "cs-card-black-btn-group" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-dark w-100 cs-black-btn p-2 mt-4",
                              attrs: { disabled: !_vm.user.canEdit },
                              on: {
                                click: function ($event) {
                                  return _vm.sendBackToDraft(_vm.snippet.id)
                                },
                              },
                            },
                            [_vm._v("Send Back to Drafts")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: `${_vm.snippet.id}-partners`,
            size: "lg",
            title: "Partners Receiving This Snippet",
            centered: "",
            "hide-footer": true,
            "header-class": "mchp-edit-header",
          },
          on: { shown: _vm.buttonDisabled, hide: _vm.onHide },
        },
        [
          _c("b-overlay", { attrs: { show: _vm.isUpdating } }, [
            _c(
              "div",
              [
                _c("p", [
                  _vm._v(
                    "Select the partners who will receive this snippet. You can also set the end date individually for each partner below."
                  ),
                ]),
                _c("hr"),
                _c(
                  "b-table-simple",
                  { attrs: { responsive: "", "sticky-header": "" } },
                  [
                    _c(
                      "b-thead",
                      { attrs: { "head-variant": "dark" } },
                      [
                        _c(
                          "b-tr",
                          [
                            _c("b-th", { attrs: { colspan: "2" } }, [
                              _vm._v("PARTNERS RECEIVING THIS SYNDICATION"),
                            ]),
                            _c("b-th", [_vm._v("END DATE")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-tbody",
                      _vm._l(_vm.modalPartnerList, function (pitem) {
                        return _c(
                          "b-tr",
                          { key: pitem.partnerId },
                          [
                            _c("b-td", [
                              _vm._v(_vm._s(pitem.name) + " "),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "limit-note hide",
                                  attrs: {
                                    id: `${pitem.partnerId}-snippet-limit`,
                                  },
                                },
                                [
                                  _vm._v(
                                    " You've reached the maximum limit for this partner. "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "b-td",
                              { staticClass: "text-right" },
                              [
                                _c("b-form-checkbox", {
                                  attrs: { value: pitem },
                                  on: {
                                    change: function ($event) {
                                      return _vm.chOnChange(
                                        pitem,
                                        _vm.snippet.id
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.selectedPartners,
                                    callback: function ($$v) {
                                      _vm.selectedPartners = $$v
                                    },
                                    expression: "selectedPartners",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-td",
                              [
                                _c("b-form-datepicker", {
                                  attrs: { min: _vm.minDate, max: _vm.maxDate },
                                  model: {
                                    value: pitem.end_date,
                                    callback: function ($$v) {
                                      _vm.$set(pitem, "end_date", $$v)
                                    },
                                    expression: "pitem.end_date",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "button",
            {
              staticClass: "btn btn-danger w-100 p-2",
              attrs: { id: `${_vm.snippet.id}-queue-save-btn` },
              on: {
                click: function ($event) {
                  return _vm.savePartner()
                },
              },
            },
            [_vm._v("Save")]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: `${_vm.snippet.id}-edit`,
            size: "lg",
            title: "Edit Snippet",
            centered: "",
            "hide-footer": true,
            "header-class": "mchp-edit-header",
          },
        },
        [
          _c("EditSnippets", {
            attrs: { propSnippet: _vm.snippet },
            on: { editedSummarySaved: _vm.savingUpdatedSnippet },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: `${_vm.snippet.id}-gacampaign`,
            title: "Update GA-campaign Code",
            size: "lg",
            centered: "",
            "hide-footer": true,
            "header-class": "mchp-edit-header",
          },
        },
        [
          _c("EditGAcampaign", {
            attrs: {
              propGaCampaignCode: _vm.snippet.ga_campaign_id,
              propSnippetId: _vm.snippet.id,
            },
            on: { updatedGaCampaignCode: _vm.savingUpdatedGaCampaignCode },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: `${_vm.snippet.id}-business-unit`,
            title: "Update Business Unit",
            size: "sm",
            centered: "",
            "hide-footer": true,
            "header-class": "mchp-edit-header",
          },
        },
        [
          _c("EditBusinessUnit", {
            attrs: {
              propSnippetId: _vm.snippet.id,
              propBusinessUnit: _vm.snippet.business_unit,
              propBusinessUnits: _vm.businessUnits,
            },
            on: { updatedBusinessUnit: _vm.savingUpdatedBusinessUnit },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: `${_vm.snippet.id}-content-type`,
            title: "Update Content Type",
            size: "lg",
            centered: "",
            "hide-footer": true,
            "header-class": "mchp-edit-header",
          },
        },
        [
          _c("EditContentType", {
            attrs: {
              propSnippetId: _vm.snippet.id,
              propContentType: _vm.item.content_type,
            },
            on: { updatedContentType: _vm.savingUpdatedContentType },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: `${_vm.snippet.id}-snippet-title`,
            title: "Update Title",
            size: "lg",
            centered: "",
            "hide-footer": true,
            "header-class": "mchp-edit-header",
          },
        },
        [
          _c("EditTitle", {
            attrs: {
              propTitle: _vm.snippet.title,
              propSnippetId: _vm.snippet.id,
            },
            on: { updateSnippetTitle: _vm.savingUpdateSnippetTitle },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: `${_vm.snippet.id}-edit-snippet-image`,
            title: "Update Image",
            size: "xl",
            centered: "",
            "hide-footer": "",
            "header-class": "mchp-edit-header",
          },
        },
        [
          _vm.snippet.images.length !== 0
            ? _c("EditImage", {
                attrs: {
                  propEditSnippetImage: true,
                  propSnippetId: _vm.snippet.id,
                  propSnippetImageData: _vm.snippet.images[0],
                },
                on: {
                  closeModal: _vm.closeModal,
                  updateSnippetImage: _vm.savingUpdateSnippetImage,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: `${_vm.snippet.id}-add-snippet-image`,
            title: "Add Image",
            size: "xl",
            centered: "",
            "hide-footer": "",
            "header-class": "mchp-edit-header",
          },
        },
        [
          _c("EditImage", {
            attrs: {
              propEditSnippetImage: false,
              propSnippetId: _vm.snippet.id,
              propSnippetImageData: "",
            },
            on: {
              closeModal: _vm.closeModal,
              addSnippetImage: _vm.savingAddSnippetImage,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: `original-title-${_vm.snippet.id}`,
            "hide-footer": "",
            centered: "",
            size: "lg",
            title: "Original Title",
          },
        },
        [
          _c("div", { staticClass: "d-block text-center" }, [
            _c("p", [_vm._v(_vm._s(_vm.snippet.original_title))]),
          ]),
        ]
      ),
      _c("ModalOriginalSummary", { ref: "refSeeOriginal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }