
import { Component, Vue } from "vue-property-decorator";
import { getSnippetBusinessUnitFilters } from "@/components/syndications/utils";
import FilterBarSyndication from "../../common/FilterBarSyndication.vue";
import store from "./../../../store";

@Component({
  name: "Syndications",
  components: { FilterBarSyndication },
  computed: {
    user() {
      return store.getters.getUserGroup("syndications");
    },
  },
})
export default class Syndications extends Vue {
  user!: any[];
  public dataList: any[] = []
  public fieldDisplay: string|any = ""
  public businessUnits: any = null;

  searchBarDataList(payload : any, field : string) {
    this.dataList = payload!.results ?? [];
    this.fieldDisplay = field ?? ""
  }

  created() {
    this.getBusinessUnits();
  }

  mounted() {
    // Retain active class if reload page
    let navMenuItem = document.querySelector("#nav-syndications .nav-link");
    navMenuItem!.classList.add("router-link-active");
  }
  // Retain active class if clicked
  setActiveParent() {
    let navMenuItem = document.querySelector("#nav-syndications .nav-link");
    navMenuItem!.classList.add("router-link-active");
  }

  public async getBusinessUnits(): Promise<void> {
    this.businessUnits = await getSnippetBusinessUnitFilters();
  }
}
