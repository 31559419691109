var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("b-form-input", {
                model: {
                  value: _vm.gaCampaignCode,
                  callback: function ($$v) {
                    _vm.gaCampaignCode = $$v
                  },
                  expression: "gaCampaignCode",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c("b-col", [
            _c(
              "button",
              {
                staticClass: "btn btn-danger w-100 cs-red-btn",
                on: { click: _vm.updateGaCampaignCode },
              },
              [_vm._v("Save")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }