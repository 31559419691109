
import {Component, Vue, Prop} from 'vue-property-decorator'
import { 
    getPartnersFromServer, 
    addNewPartnerToServer,
    getPartnerVFilterConstants,
    getPartnerAgreements,
    getOwners
} from "@/components/partners/utils";
import { getUserBusinessUnit } from "@/components/syndications/utils"
import { Country, State }  from 'country-state-city';
import store from "@/store";
import { SnippetOptionsEnum } from "./../../../enums";

interface filteredDataset {
    company_name: string,
    company_url: string,
    address_1: string,
    snippet_limit: number,
    partner_type: string,
    country: string,
    state_province: string,
    is_active: boolean,
    is_approved: boolean,
    user_id: number
}

@Component({
    data() {
        return {
            selected: null
        }
    },
    components: {},
    computed: {},
    methods: {}
})
export default class AddPartner extends Vue {
    /***
     * User roles
     * 1 = Super Admin, 
     * 2 = Content Syndication Manager, 
     * 3 = Reporting, 
     * 4 = Read
     * ***/
    @Prop() public role: any | undefined
    @Prop() readonly user: any | undefined

    public isStateProvinceDisabled:boolean = false;
    public activePartnerList: any = null;
    public partnerTypes:any = null;
    public snippetLimitOptions:Array<number> = Object.assign([], SnippetOptionsEnum['SNIPPET_LIMITS']);
    public errors:any = [];
    public country:any = [];
    public newCountryList:any = []
    public name:string = "";
    public partnerAgreement:any = [];
    public companyInfo:any = [];
    public owners:any = [];
    public ownersID:any = [];
    public companyDropdown:any = [];
    public userBU:any = null;
    public isActiveContainer:boolean = false;
    public isShowCompany:boolean = false;
    public companyErrorNote: string = '';
    public contact:any = {
            name: '',
            email: '',
            phone_number: ''
        }
    public changeDataset:filteredDataset = {
        company_name: '',
        company_url: '',
        address_1: '',
        snippet_limit: 10,
        partner_type: '',
        country: '',
        state_province: '',
        is_active: false,
        is_approved: false,
        user_id: 0
    }

    get userRole() {
        return this.role
    }

    async mounted(){
        this.isAddPartnerPage();
        this.partnerTypes = await getPartnerVFilterConstants();
        this.country = Country.getAllCountries();
        this.userBU = await getUserBusinessUnit();
        this.owners = await getOwners();
        this.disableStateProvince()
        this.filterOwners();
        this.filterCountry();
    }

    async created() {
        this.activePartnerList = await getPartnersFromServer(true);
        this.partnerAgreement = await getPartnerAgreements();
        this.filterTitleAndActiveStateFromAgreement();
        this.filterByCompany();
    }

    // Hide or Show Filter Bar
    // For Partner's Page
    isAddPartnerPage() {
        let barElement = document.getElementById('cs-bar-filter');
        this.$attrs.isAddPartnerPage ? barElement!.style.display = 'none' : barElement!.style.display = 'unset';
    }

    filterOwners() {
        const data = this.owners.filter((item:any) => {
            return item
        })
        const filterID = data.map((item:any) => {
            return { value: item.id, text: item.email }
        })
        
        filterID.unshift({ value: null, text: 'Select' })
        this.ownersID = filterID
    }

    disableStateProvince() {
        this.changeDataset.state_province === "" ? this.isStateProvinceDisabled = true : this.isStateProvinceDisabled = false;
    }

    filterTitleAndActiveStateFromAgreement() {
        // get title and isactive only
        this.companyInfo = this.partnerAgreement.agreements.map((item:any) => {
            return {title: item.title, isactive: item.isactive, uuid: item.uuid }
        })
    }

    filterByCompany() {
        if(this.companyInfo) {
            const data = this.companyInfo;
            const getCompany = data.map((item:any) => {
                let isExisting = false;
                try {
                    const partner = this.activePartnerList.results.filter((obj:any) => { 
                        let temp = item.title.endsWith(' ') ? item.title.slice(0,-1) : item.title
                        return obj.company_name === temp
                    })
                    partner.length > 0 ? isExisting = true :isExisting = false;
                    if (isExisting) {
                        return { value: item.title, text: item.title.endsWith(' ') ? item.title.slice(0, -1) : item.title, disabled: true }
                    } else {
                        return { value: item.title, text: item.title, disabled: false }
                    }
                } catch (err) {
                    store.commit('setToast', {
                        variant: 'danger',
                        title: 'Not Found',
                        body: "Sorry, Unable to connect to server. Please contact your administrator."
                    });
                }
            })
            getCompany.unshift({ value: null, text: 'Select', disabled:true })
            this.companyDropdown = getCompany
        }
    }

    getCompanyValue(company_name:any) {
        this.changeDataset.company_name = company_name
        this.isShowCompany = false
    }

    showCompanyDropdown() {
        this.isActiveContainer ? this.isActiveContainer = false : this.isActiveContainer = true;
    }

    showError(company_name:any) {
        this.isShowCompany = true
        this.isActiveContainer = false
        company_name === 'Select' ? this.companyErrorNote = 'Please select a valid company.' : this.companyErrorNote = `${company_name} already exists. Please select another company.`
    }

    get filterPartnerTypes() {
        if(this.partnerTypes?.results.partner_type) {
            const data = this.partnerTypes.results.partner_type
            return data?.map((item:any ) => {
                return item
            })
        }
        return []
    }

    filterCountry() {
        const USObject = { value: "US", text: "United States" };
        const countryList = this.country.filter((item:any) => {
            return item.name !== USObject.text ? { value: item.isoCode, text: item.name } : ''
        })
        const finalData = countryList.map((item:any) => {
            return { value: item.isoCode, text: item.name }
        })
        finalData.unshift(USObject)
        finalData.unshift({ value: null, text: 'Select', disabled:true })
        this.newCountryList = finalData
    }

    async checkAddPartnerForm(e:any) {
        e.preventDefault();
        this.errors = [];

        if (!this.changeDataset.company_name) { this.errors.push('Company name is required.'); }
        if (!this.changeDataset.address_1) { this.errors.push('Address 1 is required.'); }
        if (!this.changeDataset.company_url) { this.errors.push('Company URL is required.'); }
        if (!this.contact.name) { this.errors.push('Contact name is required.'); }
        if (!this.contact.email) { this.errors.push('Email is required.'); }
        if (!this.contact.phone_number) { this.errors.push('Phone number is required.'); }
        if (!this.changeDataset.snippet_limit) { this.errors.push('Max number of snippet is required.'); }
        if (!this.changeDataset.partner_type) { this.errors.push('Partner type is required.'); }
        if (!this.changeDataset.country) { this.errors.push('Country is required.'); }

        if (this.errors.length < 1) {

            const fname = this.contact.name.split(" ").splice(-2)[0];
            let lname = this.contact.name.split(" ").splice(-1)[0];
            if (lname === fname) {
                lname = ""
            } else if (lname === "" || lname === " ") {
                lname = ""
            } else {
                lname = this.contact.name.split(" ").splice(-1)[0]
            }

            let contactData = {
                contact: {
                    last_name: lname,
                    first_name: fname,
                    email: this.contact.email,
                    phone_number: this.contact.phone_number.includes('+')
                        ? `${this.contact.phone_number}`
                        : `+${this.contact.phone_number}`
                },
            }
            const getActiveApproved = this.companyInfo.filter((item:any) => {
                return item.title === this.changeDataset.company_name
            })
            this.changeDataset.is_active = JSON.parse(getActiveApproved[0].isactive);
            this.changeDataset.is_approved = JSON.parse(getActiveApproved[0].isactive);
            this.changeDataset = {...this.changeDataset, ...contactData};

            await addNewPartnerToServer(this.changeDataset);
            return true;
        } else {
            window.scrollTo(0,0)
        }
    }
}
