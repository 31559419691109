import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import {getUrlMSAL, authUserAccess, authUserLogout} from "./../utils"
import { AccessTokens } from "@/utils/types";

@Module({ namespaced: true })
export default class Login extends VuexModule {

  @Mutation
  setCredentials(payload: any) {
    console.log(payload);
  }

  @Action
  async getMSALUrl() {
    await getUrlMSAL();
  }

  @Action
  async loginWithCodeAction(params: AccessTokens) {
    await authUserAccess(params);
  }

  @Action
  async logoutWithMSALAction(){
    await authUserLogout();
  }
}