
import {Component, Vue, Watch, Prop} from 'vue-property-decorator'
import FinishedCards from './cards/FinishedCards.vue'
import {getSnippetFromServer, searchSnippetsInServer, getPartnerListFromServer} from "@/components/syndications/utils";
import { getPartnerSnippetsFromServerFiltered } from "@/components/partners/utils";
import {getModule} from "vuex-module-decorators";
import FilterModule from "@/store/Filter";

@Component({
  name: "Finished",
  components: {FinishedCards},
})
export default class Finished extends Vue {
  @Prop() readonly user: any | undefined
  @Prop({default: false}) isSyndicatedSnippetPage: any;
  @Prop() readonly dataList: any | Array<any>
  @Prop() readonly fieldDisplay: string|any
  public partnerId:number = parseInt(this.$route.params.partnerId);

  public finished_snippets: any = null;
  public isLoading: boolean = false;
  public isSearch: boolean = false;
  public syndicatedSnippets:any = [];
  public hasSnippets:boolean = false;
  public partnersList: any = null;
  public counter:number = 0;
  public isSyndicatedLoading: boolean = false;

  filterStore = getModule(FilterModule, this.$store);

  public perPage: number = 5;
  public currentPage: number = 1;
  public perPageSelect = [
    {"value": 5, "text": "5"},
    {"value": 10, "text": "10"},
    {"value": 15, "text": "15"},
    {"value": 20, "text": "20"},
    {"value": 25, "text": "25"}
  ];

  private RETIRED_STATE = 4;

  async mounted() {
    this.partnersList = await getPartnerListFromServer();
    if (this.isSyndicatedSnippetPage) {
      const searchParamsFromStore = {
        content_state__pk: this.RETIRED_STATE,
      };
      this.finished_snippets = await getPartnerSnippetsFromServerFiltered(this.partnerId, searchParamsFromStore);
      this.syndicatedSnippets = this.finished_snippets.filter(
        (item: any) => {
          return item
        }
      );
      
      this.hasSnippets = this.syndicatedSnippets.length > 0 ? true : false;
    } else {
      this.finished_snippets = await getSnippetFromServer(this.RETIRED_STATE);
    }
    this.$emit("searchBarDataList", this.finished_snippets, "title");
    await this.runSearch();
  }

  get getPageStart(): number {
    let start = (this.currentPage - 1) * this.perPage;
    if(this.hasSnippets) {
      if(this.partnerSnippetsForList.length) {
        start = start + 1;
      }
      else start = 0;
    }
    
    if(!this.hasSnippets) {
      if(this.snippetsForList.length) {
        start = start + 1;
      }
      else start = 0;
    }
    
    return start;
  }

  get rows(): number {
    return this.finished_snippets?.results
      ? this.finished_snippets?.results?.length
      : this.finished_snippets?.length;
  }

  get snippetResultsLength(): number {
    return this.finished_snippets?.results?.length ?? 0;
  }

  get searchTerm(): string|null {
    return this.filterStore.searchTermValue;
  }

  get businessUnit(): string|null {
    return this.filterStore.businessUnitValue;
  }

  get contentType(): string|null {
    return this.filterStore.contentTypeValue;
  }

  get partnerType(): string|null{
    return this.filterStore.partnerTypeValue;
  }

  get resetSearchFlag(): boolean{
    return this.filterStore.resetSearchFlagValue;
  }

  get snippetsForList() {
    if (this.finished_snippets?.results) {
      return this.finished_snippets.results.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
      );
    }
    return [];
  }

  get partnerSnippetsForList() {
    this.isSyndicatedLoading = true
    if (this.syndicatedSnippets) {
      if(this.syndicatedSnippets.length > 0) {
        return this.syndicatedSnippets.slice(
            (this.currentPage - 1) * this.perPage,
            this.currentPage * this.perPage
        );
      } else {
        this.isSyndicatedLoading = false;
        this.counter++
        if(this.counter === 1) {
          this.isSyndicatedLoading = true
        }
      }
    }
    return [];
  }

  @Watch('searchTerm')
  async searchTermChanged() {
    await this.runSearch();
  }

  @Watch('contentType')
  async searchContentType(){
    await this.runSearch();
  }

  @Watch('businessUnit')
  async searchBusinessUnit(){
    await this.runSearch();
  }

  @Watch('partnerType')
  async searchPartnerType(){
    await this.runSearch();
  }

  @Watch("resetSearchFlag")
  async resetSearchFilter() {
    if(this.resetSearchFlag) this.isLoading = true;
    this.isSearch = false;
    const searchParamsFromStore = {
      content_state__pk: this.RETIRED_STATE,
    };
    this.isSyndicatedSnippetPage ? this.syndicatedSnippets = await getPartnerSnippetsFromServerFiltered(this.partnerId, searchParamsFromStore) : this.finished_snippets = await getSnippetFromServer(this.RETIRED_STATE);
    this.isLoading = false;
    this.filterStore.setResetSearchFlag(false);
  }

  async runSearch() {
    // If search within syndicated snippet page
    const syndicatedPageSearchParamsFromStore = {
      content_state__pk: this.RETIRED_STATE,
      title__icontains: this.filterStore.generateSearchParams.title,
      content_type: this.filterStore.generateSearchParams.content_type,
      business_unit: this.filterStore.generateSearchParams.business_unit,
    };

    // Regular snippet in syndication
    const searchParamsFromStore = {
      content_state: this.RETIRED_STATE,
      ...this.filterStore.generateSearchParams
    }

    this.isLoading = true;
    this.isSearch = true;
    if (this.isSyndicatedSnippetPage) {
      this.syndicatedSnippets = await getPartnerSnippetsFromServerFiltered(this.partnerId, syndicatedPageSearchParamsFromStore)
    } else {
      this.finished_snippets = await searchSnippetsInServer(searchParamsFromStore);
    }

    this.isLoading = false;
  }

  updated() {
    this.isSyndicatedLoading = false;
  }
}
