
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import QueueCards from './cards/QueueCards.vue';
import {
  getSnippetFromServer,
  updateSnippetWithPayload,
  updateSnippetImageWithPayload,
  addSnippetImageWithPayload,
  updateSnippetSummary,
  searchSnippetsInServer,
  deleteSyndicatorRow,
  resetSyndicatorRowForRevert,
  getPartnerListFromServer,
  updateSyndicatorRow,
  getSnippetDetailFromServer
} from "@/components/syndications/utils";
import { getPartnerSnippetsFromServerFiltered } from "@/components/partners/utils";
import {getModule} from "vuex-module-decorators";
import FilterModule from "@/store/Filter";

@Component({
  name: "Queue",
  components: {QueueCards},
})
export default class Queue extends Vue {
  @Prop() readonly user: any | undefined
  @Prop({default: false}) isSyndicatedSnippetPage: any;
  @Prop() readonly dataList: any | Array<any>
  @Prop() readonly fieldDisplay: string|any
  @Prop() readonly businessUnits: string|any

  public cardSnippetDetail: any = '';
  public queued_snippets: any = null;
  public partnersList: any = null;
  public isLoading: boolean = false;
  public isSearch: boolean = false;
  public partnerId:number = parseInt(this.$route.params.partnerId);
  public hasSnippets:boolean = false;
  public syndicatedSnippets:any = [];
  public counter:number = 0;
  public isSyndicatedLoading: boolean = false;
  public showTimer = false;

  public perPage: number = 5;
  public currentPage: number = 1;
  public perPageSelect = [
    {"value": 5, "text": "5"},
    {"value": 10, "text": "10"},
    {"value": 15, "text": "15"},
    {"value": 20, "text": "20"},
    {"value": 25, "text": "25"}
  ];

  filterStore = getModule(FilterModule, this.$store);

  private QUEUED_STATE = 2;


  async mounted() {
    this.partnersList = await getPartnerListFromServer({active: true});
    if (this.isSyndicatedSnippetPage) {
      const searchParamsFromStore = {
        content_state__pk: this.QUEUED_STATE,
      };
      this.queued_snippets = await getPartnerSnippetsFromServerFiltered(this.partnerId, searchParamsFromStore);
      this.syndicatedSnippets = this.queued_snippets.filter(
        (item: any) => {
          return item
        }
      );
      
      this.hasSnippets = this.syndicatedSnippets.length > 0 ? true : false;
    } else {
      this.queued_snippets = await getSnippetFromServer(this.QUEUED_STATE);
    }
    this.$emit("searchBarDataList", this.queued_snippets, "title");
    await this.runSearch();
  }

  async reloadAfterUpdate(e: any): Promise<void> {
    await updateSnippetSummary(e['snippet'], e['start_date'], e['end_date']);
    this.queued_snippets = await getSnippetFromServer(this.QUEUED_STATE);
    await this.runSearch();
  }

  async reloadAfterUpdateGaCampaign(e: Event): Promise<void> {
    await updateSnippetWithPayload(e['id'], {"ga_campaign_id": e['gaCampaignCode']});
    this.queued_snippets = await getSnippetFromServer(this.QUEUED_STATE);
    await this.runSearch();
  }

  async reloadAfterUpdateBusinessUnit(e: Event): Promise<void> {
    await updateSnippetWithPayload(e['id'], {'business_unit': e['businessUnit']});
    this.queued_snippets = await getSnippetFromServer(this.QUEUED_STATE);
    await this.runSearch();
  }

  async reloadAfterUpdateContentType(e: Event): Promise<void> {
    await updateSnippetWithPayload(e['id'], {'content_type': e['contentType']});
    this.queued_snippets = await getSnippetFromServer(this.QUEUED_STATE);
    await this.runSearch();
  }

  async reloadAfterSnippetTitle(e: Event): Promise<void> {
    await updateSnippetWithPayload(e['id'], {"title": e['title']});
    this.queued_snippets = await getSnippetFromServer(this.QUEUED_STATE);
    await this.runSearch();
  }

  async reloadAfterSnippetImage(e: Event): Promise<void> {
    await updateSnippetImageWithPayload(
      e['snippetImageId'],
      {
        'path': e['selectedImageData'].path,
        'height': e['selectedImageData'].height ?? 0,
        'width': e['selectedImageData'].width ?? 0
      }
    );
    this.queued_snippets = await getSnippetFromServer(this.QUEUED_STATE);
    await this.runSearch();
  }

  async reloadAfterAddSnippetImage(e: Event): Promise<void> {
    await addSnippetImageWithPayload({
      'snippet': e['snippetId'],
      'path': e['selectedImageData'].path,
      'height': e['selectedImageData'].height ?? 0,
      'width': e['selectedImageData'].width ?? 0,
      'package_text': ''
    });
    this.queued_snippets = await getSnippetFromServer(this.QUEUED_STATE);
    const snippetDetailId = e['snippetId']
    this.cardSnippetDetail = await getSnippetDetailFromServer(snippetDetailId)
    await this.runSearch();
  }

  async reloadAfterSnippetBackToDraft(e: Event): Promise<void>{
    this.isLoading = true;
    const queued_snippets = this.queued_snippets?.results ?? this.queued_snippets;
    // get snippet; should return 1
    let snippet = queued_snippets.filter((i: any) => {return i.id == e['id']});

    // get snippet_partners (syndicator row) and reset the dates but don't remove partners
    // instead of delete @see Ticket#13658 (18-07-2022)
    if(snippet[0].snippet_partners.length > 0){
      let snippet_partner_ids: Array<number> = snippet[0].snippet_partners.map((i: any) => {return i.id});
      for(let id of snippet_partner_ids){
        await resetSyndicatorRowForRevert(id);
      }
    }

    // update snippet state
    await updateSnippetWithPayload(e['id'], {content_state: 1});  // update snippet
    // reload snippets
    this.queued_snippets = await getSnippetFromServer(this.QUEUED_STATE);
    this.isLoading = false;
    await this.runSearch();
  }

  async reloadAfterSnippetPartnerUpdate(e: Event): Promise<void> {
    this.isLoading = true;
    const updatedStartDate = e['replaceStartDate'];
    let payloadList = e['selected'].map((e: any) => {
      let item = {
        partner: e.partnerId,
        snippet: e.snippetId,
        start_date: updatedStartDate,
        end_date: e.end_date,
        is_suspended: false,
        is_retired: false
      }

      return item;
    });

    for(let payload of payloadList){
      await updateSyndicatorRow(payload);
    }

    if(e['remove'].length > 0) {
      let removeIds = e['remove'].map((e: any) => {return e.syndicatorId});
      for (let id of removeIds) {
        await deleteSyndicatorRow(id);
      }
    }

    this.queued_snippets = await getSnippetFromServer(this.QUEUED_STATE);
    this.isLoading = false;
    await this.runSearch();
  }

  get getPageStart(): number {
    let start = (this.currentPage - 1) * this.perPage;
    if(this.hasSnippets) {
      if(this.partnerSnippetsForList.length) {
        start = start + 1;
      }
      else start = 0;
    }
    
    if(!this.hasSnippets) {
      if(this.snippetsForList.length) {
        start = start + 1;
      }
      else start = 0;
    }
    
    return start;
  }

  get rows(): number {
    return this.queued_snippets?.results
      ? this.queued_snippets?.results?.length
      : this.queued_snippets?.length;
  }

  get searchTerm(): string|null {
    return this.filterStore.searchTermValue;
  }

  get businessUnit(): string|null {
    return this.filterStore.businessUnitValue;
  }

  get contentType(): string|null {
    return this.filterStore.contentTypeValue;
  }

  get partnerType(): string|null{
    return this.filterStore.partnerTypeValue;
  }

  get resetSearchFlag(): boolean{
    return this.filterStore.resetSearchFlagValue;
  }

  get snippetsForList() {
    if (this.queued_snippets?.results) {
      return this.queued_snippets.results.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
      );
    }
    return [];
  }

  get partnerSnippetsForList() {
    this.isSyndicatedLoading = true
    if (this.syndicatedSnippets) {
      if(this.syndicatedSnippets.length > 0) {
        return this.syndicatedSnippets.slice(
            (this.currentPage - 1) * this.perPage,
            this.currentPage * this.perPage
        );
      } else {
        this.isSyndicatedLoading = false;
        this.counter++
        if(this.counter === 1) {
          this.isSyndicatedLoading = true
        }
      }
    }
    return [];
  }

  @Watch('searchTerm')
  async searchTermChanged() {
    await this.runSearch();
  }

  @Watch('contentType')
  async searchContentType(){
    await this.runSearch();
  }

  @Watch('businessUnit')
  async searchBusinessUnit(){
    await this.runSearch();
  }

  @Watch('partnerType')
  async searchPartnerType(){
    await this.runSearch();
  }

  @Watch('resetSearchFlag')
  async resetSearchFilter(){
    if(this.resetSearchFlag) this.isLoading = true;
    this.isSearch = false;
    const searchParamsFromStore = {
      content_state__pk: this.QUEUED_STATE,
    };
    this.isSyndicatedSnippetPage ? this.syndicatedSnippets = await getPartnerSnippetsFromServerFiltered(this.partnerId, searchParamsFromStore) : this.queued_snippets = await getSnippetFromServer(this.QUEUED_STATE);
    this.isLoading = false;
    this.filterStore.setResetSearchFlag(false);
  }

  async runSearch(){
    // If search within syndicated snippet page
    const syndicatedPageSearchParamsFromStore = {
      content_state__pk: this.QUEUED_STATE,
      title__icontains: this.filterStore.generateSearchParams.title,
      content_type: this.filterStore.generateSearchParams.content_type,
      business_unit: this.filterStore.generateSearchParams.business_unit,
    };

    // Regular snippet in syndication
    const searchParamsFromStore = {
      content_state: this.QUEUED_STATE,
      ...this.filterStore.generateSearchParams
    }

    this.isLoading = true;
    this.isSearch = true;
    if (this.isSyndicatedSnippetPage) {
      this.syndicatedSnippets = await getPartnerSnippetsFromServerFiltered(this.partnerId, syndicatedPageSearchParamsFromStore)
    } else {
      this.queued_snippets = await searchSnippetsInServer(searchParamsFromStore);
    }
    this.isLoading = false;
  }

  reloadPage():void {
    this.cancelReload()
    setTimeout(()=> {
        location.reload()
    }, 5000)
  }

  cancelReload():void {
    const timer = document.getElementById('timer-container');
    timer!.classList.add('hide-timer')
  }

  updated() {
    this.isSyndicatedLoading = false;
  }
}
