
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import LiveCards from "./cards/LiveCards.vue";
import {
  getSnippetFromServer,
  searchSnippetsInServer,
  updateSnippetSummary,
  getPartnerListFromServer,
  updateSyndicatorRow,
  updateSnippetWithPayload,
  resetSyndicatorRowForRevert,
} from "@/components/syndications/utils";
import { getPartnerSnippetsFromServerFiltered } from "@/components/partners/utils";
import { getModule } from "vuex-module-decorators";
import FilterModule from "@/store/Filter";

@Component({
  name: "CurrentlyLive",
  components: { LiveCards },
})
export default class CurrentlyLive extends Vue {
  @Prop() readonly user: any | undefined;
  @Prop({ default: false }) isSyndicatedSnippetPage: any;
  @Prop() readonly dataList: any | Array<any>;
  @Prop() readonly fieldDisplay: string | any;

  public partnerId: number = parseInt(this.$route.params.partnerId);

  public live_snippets: any = null;
  public isLoading: boolean = false;
  public isSyndicatedLoading: boolean = false;
  public isSearch: boolean = false;
  public syndicatedSnippets: any = [];
  public hasSnippets: boolean = false;
  public partnersList: any = null;
  public counter: number = 0;

  public perPage: number = 5;
  public currentPage: number = 1;
  public perPageSelect = [
    { value: 5, text: "5" },
    { value: 10, text: "10" },
    { value: 15, text: "15" },
    { value: 20, text: "20" },
    { value: 25, text: "25" },
  ];

  filterStore = getModule(FilterModule, this.$store);

  private PUBLISH_STATE = 3;

  async mounted(): Promise<void> {
    // Live snippets = PUBLISHED = PB
    this.partnersList = await getPartnerListFromServer();
    if (this.isSyndicatedSnippetPage) {
      const searchParamsFromStore = {
        content_state__pk: this.PUBLISH_STATE,
      };
      this.live_snippets = await getPartnerSnippetsFromServerFiltered(
        this.partnerId,
        searchParamsFromStore
      );
      this.syndicatedSnippets = this.live_snippets.filter((item: any) => {
        return item;
      });
      this.hasSnippets = this.syndicatedSnippets.length > 0 ? true : false;
    } else {
      this.live_snippets = await getSnippetFromServer(this.PUBLISH_STATE);
    }
    this.$emit("searchBarDataList", this.live_snippets, "title");
    await this.runSearch();
  }

  get getPageStart(): number {
    let start = (this.currentPage - 1) * this.perPage;
    if (this.hasSnippets) {
      if (this.partnerSnippetsForList.length) {
        start = start + 1;
      } else start = 0;
    }

    if (!this.hasSnippets) {
      if (this.snippetsForList.length) {
        start = start + 1;
      } else start = 0;
    }

    return start;
  }

  get rows(): number {
    return this.live_snippets?.results?.length ?? 0;
  }

  get syndicatedSnippetsRow(): number {
    return this.syndicatedSnippets?.length ?? 0;
  }

  get searchTerm(): string | null {
    return this.filterStore.searchTermValue;
  }

  get businessUnit(): string | null {
    return this.filterStore.businessUnitValue;
  }

  get contentType(): string | null {
    return this.filterStore.contentTypeValue;
  }

  get partnerType(): string | null {
    return this.filterStore.partnerTypeValue;
  }

  get resetSearchFlag(): boolean {
    return this.filterStore.resetSearchFlagValue;
  }

  get snippetsForList() {
    if (this.live_snippets?.results) {
      return this.live_snippets.results.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    }
    return [];
  }

  async reloadAfterUpdate(e: any): Promise<void> {
    await updateSnippetSummary(e["snippet"], e["start_date"], e["end_date"]);
    this.live_snippets = await getSnippetFromServer(this.PUBLISH_STATE);
    await this.runSearch();
  }

  async reloadAfterSnippetBackToDraft(e: Event): Promise<void> {
    this.isLoading = true;
    const live_snippets = this.live_snippets?.results ?? this.live_snippets;
    // get snippet; should return 1
    let snippet = live_snippets.filter((i: any) => {return i.id == e["id"]});

    // get snippet_partners (syndicator row) and reset the dates but don't remove partners
    // instead of delete @see Ticket#13658 (18-07-2022)
    if (snippet[0].snippet_partners.length > 0) {
      let snippet_partner_ids: Array<number> = snippet[0].snippet_partners.map(
        (i: any) => {
          return i.id;
        }
      );
      for (let id of snippet_partner_ids) {
        await resetSyndicatorRowForRevert(id);
      }
    }

    // update snippet state
    await updateSnippetWithPayload(e["id"], { content_state: 1 }); // update snippet
    // reload snippets
    this.live_snippets = await getSnippetFromServer(this.PUBLISH_STATE);
    this.isLoading = false;
    await this.runSearch();
  }

  get partnerSnippetsForList() {
    this.isSyndicatedLoading = true;
    if (this.syndicatedSnippets) {
      if (this.syndicatedSnippets.length > 0) {
        return this.syndicatedSnippets.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
        );
      } else {
        this.isSyndicatedLoading = false;
        this.counter++;
        if (this.counter === 1) {
          this.isSyndicatedLoading = true;
        }
      }
    }
    return [];
  }

  async reloadAfterSnippetPartnerUpdate(e: Event): Promise<void> {
    this.isLoading = true;
    const updatedStartDate = e["replaceStartDate"];
    let payloadList = e["selected"].map((e: any) => {
      let item = {
        partner: e.partnerId,
        snippet: e.snippetId,
        start_date: updatedStartDate,
        end_date: e.end_date,
        is_suspended: false,
        is_retired: false,
      };
      return item;
    });

    for (let payload of payloadList) {
      await updateSyndicatorRow(payload);
    }

    this.live_snippets = await getSnippetFromServer(this.PUBLISH_STATE);
    this.isLoading = false;
    await this.runSearch();
  }

  @Watch("searchTerm")
  async searchTermChanged() {
    await this.runSearch();
  }

  @Watch("contentType")
  async searchContentType() {
    await this.runSearch();
  }

  @Watch("businessUnit")
  async searchBusinessUnit() {
    await this.runSearch();
  }

  @Watch("partnerType")
  async searchPartnerType() {
    await this.runSearch();
  }

  @Watch("resetSearchFlag")
  async resetSearchFilter() {
    if (this.resetSearchFlag) this.isLoading = true;

    this.isSearch = false;
    const searchParamsFromStore = {
      content_state__pk: this.PUBLISH_STATE,
    };
    this.isSyndicatedSnippetPage
      ? (this.syndicatedSnippets = await getPartnerSnippetsFromServerFiltered(
          this.partnerId,
          searchParamsFromStore
        ))
      : (this.live_snippets = await getSnippetFromServer(this.PUBLISH_STATE));
    this.isLoading = false;
    this.filterStore.setResetSearchFlag(false);
  }

  async runSearch() {
    // If search within syndicated snippet page
    const syndicatedPageSearchParamsFromStore = {
      content_state__pk: this.PUBLISH_STATE,
      title__icontains: this.filterStore.generateSearchParams.title,
      content_type: this.filterStore.generateSearchParams.content_type,
      business_unit: this.filterStore.generateSearchParams.business_unit,
    };

    // Regular snippet in syndication
    const searchParamsFromStore = {
      content_state: this.PUBLISH_STATE,
      ...this.filterStore.generateSearchParams,
    };

    this.isLoading = true;
    this.isSearch = true;
    if (this.isSyndicatedSnippetPage) {
      this.syndicatedSnippets = await getPartnerSnippetsFromServerFiltered(
        this.partnerId,
        syndicatedPageSearchParamsFromStore
      );
    } else {
      this.live_snippets = await searchSnippetsInServer(searchParamsFromStore);
    }

    this.isLoading = false;
  }

  created() {
    this.isLoading = false;
  }
  updated() {
    this.isSyndicatedLoading = false;
  }
}
