import Vue from 'vue';
import Cookies from 'vue-cookies';
import TextHighlight from 'vue-text-highlight';

import App from './App.vue';
import router from './router';
import store from './store';
// import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Global scss
import './global.scss';

import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';

// Global registration
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

// Use Section
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Cookies);


// Filters section
Vue.filter('pluralize', (word: string, amount: number) => (amount > 1 || amount === 0) ? `${word}s` : word);
Vue.filter('capitalize', (value: string) => {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});


// Components section
Vue.component('text-highlight', TextHighlight);
// Vue.component('v-select', vSelect)

// Logger
import VueAppInsights from 'vue-application-insights';

Vue.use(VueAppInsights, {
    id: `${process.env.VUE_APP_AZURE_MONITOR_INSTRUMENTATION_KEY}`,
    router
})

// Hide information from vue extension in browser in production or staging
// if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
//   Vue.config.devtools = false
//   Vue.config.productionTip = false
// }

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
