var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "accordionItem" },
    [
      _c("div", { staticClass: "accordionItem-heading" }, [
        _c("h4", { staticClass: "accordionItem-title" }, [
          _c(
            "div",
            {
              staticClass: "accordionItem-input",
              style: _vm.editQuestion ? "width: 100%" : "width: auto;",
              on: {
                click: function ($event) {
                  return _vm.headerClicked()
                },
              },
            },
            [
              _c("EditableInput", {
                attrs: {
                  value: _vm.accordionItem.question,
                  editInput: _vm.editQuestion,
                },
                on: { update: _vm.updateQuestion },
              }),
              _c("Icon", {
                attrs: {
                  name: "accordion",
                  height: "16",
                  width: "16",
                  color: "#525B5C",
                },
              }),
            ],
            1
          ),
          _vm.editable
            ? _c(
                "button",
                {
                  ref: "textarea",
                  staticClass: "mchp-util-unstyledButton",
                  attrs: { title: "Edit Item" },
                  on: { click: _vm.editQuestionContent },
                },
                [
                  _c("Icon", {
                    attrs: { name: _vm.editQuestion ? "cancel" : "edit" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.editable
            ? _c(
                "button",
                {
                  staticClass: "mchp-util-unstyledButton",
                  attrs: { title: "Remove Item" },
                  on: {
                    click: function ($event) {
                      return _vm.removeQuestionAnswer(_vm.accordionItem.id)
                    },
                  },
                },
                [_c("Icon", { attrs: { name: "remove" } })],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "transition",
        {
          on: {
            "before-enter": _vm.beforeEnter,
            enter: _vm.enter,
            "before-leave": _vm.beforeLeave,
            leave: _vm.leave,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isExpanded,
                  expression: "isExpanded",
                },
              ],
              ref: "accordionBody",
              staticClass: "accordionItem-body",
            },
            [
              _c(
                "div",
                { ref: "accordionInner", staticClass: "accordionItem-inner" },
                [
                  _c("EditableInput", {
                    attrs: {
                      value: _vm.accordionItem.answer,
                      editInput: _vm.editAnswer,
                      tag: "textarea",
                    },
                    on: { update: _vm.updateAnswer, type: _vm.adjustHeight },
                  }),
                  _vm.editable
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "mchp-util-unstyledButton mt-1 align-self-start",
                          attrs: { title: "Edit Item" },
                          on: { click: _vm.editAnswerContent },
                        },
                        [
                          _c("Icon", {
                            attrs: { name: _vm.editAnswer ? "cancel" : "edit" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }