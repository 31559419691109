
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import DraftCards from './cards/DraftCards.vue';
import {
  getSnippetFromServer,
  updateSnippetWithPayload,
  updateSnippetImageWithPayload,
  addSnippetImageWithPayload,
  updateSnippetSummary,
  searchSnippetsInServer,
  getSnippetDetailFromServer
} from "@/components/syndications/utils";
import {getModule} from "vuex-module-decorators";
import FilterModule from "@/store/Filter";

@Component({
  name: "Drafts",
  components: {DraftCards}
})
export default class Drafts extends Vue {
  @Prop() readonly user: any | undefined
  @Prop() readonly dataList: any | Array<any>
  @Prop() readonly fieldDisplay: string|any
  @Prop() readonly businessUnits: string|any

  public draft_snippets: any = null;
  public isLoading: boolean = false;
  public isSearch: boolean = false;
  public cardSnippetDetail: any = '';

  // pagination
  public perPage: number = 5;
  public currentPage: number = 1;
  public perPageSelect = [
    {"value": 5, "text": "5"},
    {"value": 10, "text": "10"},
    {"value": 15, "text": "15"},
    {"value": 20, "text": "20"},
    {"value": 25, "text": "25"}
  ];

  filterStore = getModule(FilterModule, this.$store);

  private DRAFT_STATE: number = 1;


  async created() {
    this.draft_snippets = await  getSnippetFromServer(this.DRAFT_STATE);
    this.$emit("searchBarDataList", this.draft_snippets, "title");
    await this.runSearch();
  }

  // update in this area
  async reloadAfterUpdate(e: Event): Promise<void> {
    this.isLoading = true;
    await updateSnippetSummary(e['snippet'], e['start_date'], e['end_date']); // e: {snippet: obj, start_date: date, end_date: date}
    this.draft_snippets = await  getSnippetFromServer(this.DRAFT_STATE);
    const snippetDetailId = e['snippet']['id']
    this.cardSnippetDetail = await getSnippetDetailFromServer(snippetDetailId)
    this.isLoading = false;
    await this.runSearch();
  }

  async reloadAfterStateUpdate(): Promise<void>{
    this.isLoading = true;
    this.draft_snippets = await  getSnippetFromServer(this.DRAFT_STATE);
    this.isLoading = false;
  }

  async reloadAfterUpdateGaCampaign(e: Event): Promise<void> {
    this.isLoading = true;
    await updateSnippetWithPayload(e['id'], {"ga_campaign_id": e['gaCampaignCode']});
    this.draft_snippets = await  getSnippetFromServer(this.DRAFT_STATE);
    const snippetDetailId = e['id']
    this.cardSnippetDetail = await getSnippetDetailFromServer(snippetDetailId)
    this.isLoading = false;
    await this.runSearch();
  }

  async reloadAfterUpdateBusinessUnit(e: Event): Promise<void> {
    this.isLoading = true;
    await updateSnippetWithPayload(e['id'], {'business_unit': e['businessUnit']});
    this.draft_snippets = await  getSnippetFromServer(this.DRAFT_STATE);
    const snippetDetailId = e['id']
    this.cardSnippetDetail = await getSnippetDetailFromServer(snippetDetailId)
    this.isLoading = false;
    await this.runSearch();
  }

  async reloadAfterUpdateContentType(e: Event): Promise<void> {
    this.isLoading = true;
    await updateSnippetWithPayload(e['id'], {'content_type': e['contentType']});
    this.draft_snippets = await  getSnippetFromServer(this.DRAFT_STATE);
    const snippetDetailId = e['id']
    this.cardSnippetDetail = await getSnippetDetailFromServer(snippetDetailId)
    this.isLoading = false;
    await this.runSearch();
  }

  async reloadAfterSnippetTitle(e: Event): Promise<void> {
    this.isLoading = true;
    await updateSnippetWithPayload(e['id'], {"title": e['title']});
    this.draft_snippets = await  getSnippetFromServer(this.DRAFT_STATE);
    const snippetDetailId = e['id']
    this.cardSnippetDetail = await getSnippetDetailFromServer(snippetDetailId)
    this.isLoading = false;
    await this.runSearch();
  }

  async reloadAfterSnippetImage(e: Event): Promise<void> {
    this.isLoading = true;
    await updateSnippetImageWithPayload(
      e['snippetImageId'],
      {
        'path': e['selectedImageData'].path,
        'height': e['selectedImageData'].height ?? 0,
        'width': e['selectedImageData'].width ?? 0
      }
    );
    this.draft_snippets = await getSnippetFromServer(this.DRAFT_STATE);
    const snippetDetailId = e['snippetId']
    this.cardSnippetDetail = await getSnippetDetailFromServer(snippetDetailId)
    this.isLoading = false;
    await this.runSearch();
  }

  async reloadAfterAddSnippetImage(e: Event): Promise<void> {
    this.isLoading = true;
    await addSnippetImageWithPayload({
      'snippet': e['snippetId'],
      'path': e['selectedImageData'].path,
      'height': e['selectedImageData'].height ?? 0,
      'width': e['selectedImageData'].width ?? 0,
      'package_text': ''
    });
    this.draft_snippets = await getSnippetFromServer(this.DRAFT_STATE);
    const snippetDetailId = e['snippetId']
    this.cardSnippetDetail = await getSnippetDetailFromServer(snippetDetailId)
    this.isLoading = false;
    await this.runSearch();
  }

  get getPageStart(): number {
    let start = (this.currentPage - 1) * this.perPage;
    if(this.snippetsForList.length) {
      start = start + 1;
    }
    else start = 0;
    return start;
  }

  get rows(): number {
    return this.draft_snippets?.results?.length ?? 0;
  }

  get searchTerm(): string|null {
    return this.filterStore.searchTermValue;
  }

  get businessUnit(): string|null {
    return this.filterStore.businessUnitValue;
  }

  get contentType(): string|null {
    return this.filterStore.contentTypeValue;
  }

  get partnerType(): string|null{
    return this.filterStore.partnerTypeValue;
  }

  get resetSearchFlag(): boolean{
    return this.filterStore.resetSearchFlagValue;
  }

  get snippetsForList() {
    if (this.draft_snippets?.results) {
      return this.draft_snippets.results.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
      );
    }
    return [];
  }

  @Watch('searchTerm')
  async searchTermChanged() {
    await this.runSearch();
  }

  @Watch('contentType')
  async searchContentType(){
    await this.runSearch();
  }

  @Watch('businessUnit')
  async searchBusinessUnit(){
    await this.runSearch();
  }

  @Watch('partnerType')
  async searchPartnerType(){
    await this.runSearch();
  }

  @Watch('resetSearchFlag')
  async resetSearchFilter(){
    if(this.resetSearchFlag) this.isLoading = true;
    this.isSearch = true;
    this.draft_snippets = await  getSnippetFromServer(this.DRAFT_STATE);
    this.isLoading = false;
    this.filterStore.setResetSearchFlag(false);
  }

  async runSearch(){
    const searchParamsFromStore = {content_state: this.DRAFT_STATE, ...this.filterStore.generateSearchParams};
    this.isLoading = true;
    this.isSearch = true;
    this.draft_snippets = await searchSnippetsInServer(searchParamsFromStore);
    this.isLoading = false;
  }
}
