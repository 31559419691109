var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "timer-backdrop hide-timer",
          attrs: { id: "timer-container" },
        },
        [
          _c("div", { attrs: { id: "cs-reload-modal-timer" } }, [
            _vm._m(0),
            _c("div", { staticClass: "timer-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger mr-2",
                  on: {
                    click: function ($event) {
                      return _vm.reloadPage()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function ($event) {
                      return _vm.cancelReload()
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
            ]),
          ]),
        ]
      ),
      _vm.isSearch
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "cs-search-box" },
                [
                  _c("b-card", { staticClass: "text-center" }, [
                    _vm.isSyndicatedSnippetPage
                      ? _c("div", [
                          _c("h6", [
                            _vm._v(
                              "Found " +
                                _vm._s(_vm.syndicatedSnippets.length) +
                                " " +
                                _vm._s(`Results`)
                            ),
                          ]),
                        ])
                      : _c("div", [
                          _c("h6", [
                            _vm._v(
                              "Found " +
                                _vm._s(_vm.queued_snippets.results.length) +
                                " " +
                                _vm._s(`Results`)
                            ),
                          ]),
                        ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isSyndicatedSnippetPage
        ? _c(
            "div",
            [
              _c(
                "b-overlay",
                {
                  staticClass: "cs-container-scroll-control",
                  attrs: { show: _vm.isLoading },
                },
                _vm._l(_vm.partnerSnippetsForList, function (snip) {
                  return _c("QueueCards", {
                    key: snip.id,
                    attrs: {
                      id: "queueCardList",
                      item: snip,
                      snippetDetail: _vm.cardSnippetDetail,
                      "prop-partner-list": _vm.partnersList,
                      isSearchResult: _vm.isSearch,
                      isSyndicatedSnippetPage: _vm.isSyndicatedSnippetPage,
                      businessUnits: _vm.businessUnits,
                      user: _vm.user,
                    },
                    on: {
                      updateSnippetTitleSaved: _vm.reloadAfterSnippetTitle,
                      updateBusinessUnitSaved:
                        _vm.reloadAfterUpdateBusinessUnit,
                      updateContentTypeSaved: _vm.reloadAfterUpdateContentType,
                      updateSnippetImageSaved: _vm.reloadAfterSnippetImage,
                      addSnippetImageSaved: _vm.reloadAfterAddSnippetImage,
                      updateSnippetSaved: _vm.reloadAfterUpdate,
                      snippetBackToDraft: _vm.reloadAfterSnippetBackToDraft,
                    },
                  })
                }),
                1
              ),
              _vm.hasSnippets
                ? _c(
                    "b-row",
                    {
                      staticClass: "pl-2 pt-3 pr-2 pagination-container",
                      attrs: { sm: "12" },
                    },
                    [
                      _c("b-col", { attrs: { cols: "4", md: "4", sm: "12" } }, [
                        _c("p", [
                          _vm._v(
                            "Showing " +
                              _vm._s(_vm.getPageStart) +
                              " - " +
                              _vm._s(
                                (_vm.currentPage - 1) * _vm.perPage +
                                  _vm.partnerSnippetsForList.length
                              ) +
                              " of " +
                              _vm._s(_vm.rows) +
                              " results"
                          ),
                        ]),
                      ]),
                      _c("b-col", { attrs: { cols: "4", md: "4", sm: "12" } }, [
                        _c(
                          "div",
                          [
                            _c("b-pagination", {
                              staticClass: "cs-page-buttons",
                              attrs: {
                                "total-rows": _vm.rows,
                                "per-page": _vm.perPage,
                                "last-text": ">",
                                "first-text": "<",
                                "next-text": "Next",
                                "prev-text": "Previous",
                                align: "center",
                                "aria-controls": "queueCardList",
                              },
                              model: {
                                value: _vm.currentPage,
                                callback: function ($$v) {
                                  _vm.currentPage = $$v
                                },
                                expression: "currentPage",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("b-col", { attrs: { cols: "4", md: "4", sm: "12" } }, [
                        _c(
                          "p",
                          { staticClass: "text-right" },
                          [
                            _c("span", [_vm._v("Results per page: ")]),
                            _vm._v(" "),
                            _c("b-form-select", {
                              staticClass: "cs-pagination-select",
                              attrs: {
                                align: "right",
                                options: _vm.perPageSelect,
                              },
                              model: {
                                value: _vm.perPage,
                                callback: function ($$v) {
                                  _vm.perPage = $$v
                                },
                                expression: "perPage",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "b-overlay",
                {
                  staticClass: "cs-container-scroll-control",
                  attrs: { show: _vm.isLoading },
                },
                _vm._l(_vm.snippetsForList, function (snip) {
                  return _c("QueueCards", {
                    key: snip.id,
                    staticClass: "cs-regular-snippets",
                    attrs: {
                      id: "queueCardList",
                      item: snip,
                      snippetDetail: _vm.cardSnippetDetail,
                      "prop-partner-list": _vm.partnersList,
                      isSearchResult: _vm.isSearch,
                      businessUnits: _vm.businessUnits,
                      user: _vm.user,
                    },
                    on: {
                      updateSnippetSaved: _vm.reloadAfterUpdate,
                      updateGaCampaignCodeSaved:
                        _vm.reloadAfterUpdateGaCampaign,
                      updateBusinessUnitSaved:
                        _vm.reloadAfterUpdateBusinessUnit,
                      updateContentTypeSaved: _vm.reloadAfterUpdateContentType,
                      updateSnippetPartner: _vm.reloadAfterSnippetPartnerUpdate,
                      updateSnippetTitleSaved: _vm.reloadAfterSnippetTitle,
                      updateSnippetImageSaved: _vm.reloadAfterSnippetImage,
                      addSnippetImageSaved: _vm.reloadAfterAddSnippetImage,
                      snippetBackToDraft: _vm.reloadAfterSnippetBackToDraft,
                    },
                  })
                }),
                1
              ),
              !_vm.hasSnippets
                ? _c(
                    "b-row",
                    {
                      staticClass: "pl-2 pt-3 pr-2 pagination-container",
                      attrs: { sm: "12" },
                    },
                    [
                      _c("b-col", { attrs: { md: "4", sm: "12" } }, [
                        _c("p", [
                          _vm._v(
                            "Showing " +
                              _vm._s(_vm.getPageStart) +
                              " - " +
                              _vm._s(
                                (_vm.currentPage - 1) * _vm.perPage +
                                  _vm.snippetsForList.length
                              ) +
                              " of " +
                              _vm._s(_vm.rows) +
                              " results"
                          ),
                        ]),
                      ]),
                      _c("b-col", { attrs: { md: "4", sm: "12" } }, [
                        _c(
                          "div",
                          [
                            _c("b-pagination", {
                              staticClass: "cs-page-buttons",
                              attrs: {
                                "total-rows": _vm.rows,
                                "per-page": _vm.perPage,
                                "last-text": ">",
                                "first-text": "<",
                                "next-text": "Next",
                                "prev-text": "Previous",
                                align: "center",
                                "aria-controls": "queueCardList",
                              },
                              model: {
                                value: _vm.currentPage,
                                callback: function ($$v) {
                                  _vm.currentPage = $$v
                                },
                                expression: "currentPage",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("b-col", { attrs: { md: "4", sm: "12" } }, [
                        _c(
                          "p",
                          { staticClass: "text-right" },
                          [
                            _c("span", [_vm._v("Results per page: ")]),
                            _vm._v(" "),
                            _c("b-form-select", {
                              staticClass: "cs-pagination-select",
                              attrs: {
                                align: "right",
                                options: _vm.perPageSelect,
                              },
                              model: {
                                value: _vm.perPage,
                                callback: function ($$v) {
                                  _vm.perPage = $$v
                                },
                                expression: "perPage",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
      _vm.isSyndicatedLoading
        ? _c(
            "div",
            { staticClass: "cs-fullscreen-loading" },
            [
              _c("b-spinner", {
                staticStyle: { width: "3rem", height: "3rem" },
                attrs: { label: "Large Spinner" },
              }),
              _c("b-spinner", {
                staticStyle: { width: "3rem", height: "3rem" },
                attrs: { label: "Large Spinner", type: "grow" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isSyndicatedSnippetPage && !_vm.hasSnippets
        ? _c(
            "div",
            [
              _c(
                "b-row",
                { staticClass: "cs-empty-container" },
                [
                  _c("b-col", [
                    _vm.isSyndicatedLoading
                      ? _c("div", {
                          staticClass:
                            "d-flex align-items-center justify-content-center v-center-height",
                        })
                      : _c("div", { staticClass: "cs-empty-snippets" }, [
                          _vm._v(
                            " There are no items available for this partner yet. "
                          ),
                        ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "timer-content mb-3" }, [
      _c("h2", [_vm._v("Do you want to reload page?")]),
      _c("span", [_vm._v("This will reload after 5 seconds.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }