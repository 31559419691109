
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import EditSnippets from "../components/EditSnippets.vue";
import AnalyticGraph from "../components/AnalyticGraph.vue";
import ElemURL from "../components/ElemURL.vue";
import FilterModule from "../../../../store/Filter";
import Icon from "@/components/Icons.vue";
import { DateTime } from 'luxon';

@Component({
  name: "LiveCards",
  components: { EditSnippets, ElemURL, Icon, AnalyticGraph },
})
export default class LiveCards extends Vue {
  @Prop() readonly user: any | undefined;
  @Prop({ default: "" }) item: any;
  @Prop({ default: false }) isSearchResult: boolean;
  @Prop({ default: "" }) propPartnerList: any;
  @Prop({ default: false }) isSyndicatedSnippetPage: any;

  public snippet: any = null;
  private modalPartnerList: any = [];
  public partnerList: any = null;
  private selectedPartners: any = [];
  private removedPartners: any = [];
  public isUpdating: boolean = false;
  private DRAFT_STATE: number = 1;

  public minEndDate = DateTime.local().plus({ days: 1 }).toISODate()
  syndicatorEndDateValue = ""

  filterStore = getModule(FilterModule, this.$store);

  get updatedAt(): any {
    let date = new Date(this.snippet.updated_at).toISOString().split("T")[0];
    return date;
  }

  async created() {
    // Regular syndication page or Syndicated page
    this.snippet = this.item;
    this.partnerList = this.propPartnerList;
  }

  mounted() {
    this.computePartnerList();
    this.syndicatorEndDate();
  }

  get syndicatorStartDate(): string {
    if (this.snippet.length != 0 && this.isSyndicatedSnippetPage === false) {
      try {
        return this.snippet.snippet_partners[0]["start_date"];
      } catch (err) {
        return "---";
      }
    }
    return this.item.snippet_partners[0]["start_date"];
  }

  syndicatorEndDate() {
    if (this.snippet.length != 0) {
      try {
        const dataFiltered = this.selectedPartners.map((item:any) => {
          return item.end_date
        })
        
        if (this.selectedPartners.length > 1) {
          for (let i = 0; i < dataFiltered.length; i++) {
            if (dataFiltered.length > 1) {
              const compareDates = (dataFiltered[0] === dataFiltered[i])
              if (!compareDates) {
                this.syndicatorEndDateValue = "varies by partner";
                break;
              } else {
                this.syndicatorEndDateValue = dataFiltered[0];
              }
            } else {
              this.syndicatorEndDateValue = dataFiltered[0];
            }
          }
        } else {
          this.syndicatorEndDateValue = dataFiltered[0];
        }
        

      } catch (err) {
        return "---";
      }
    }
  }

  public computePartnerList(): void {
    if (this.partnerList != null) {
      let partner_set = this.partnerList.results.map((e: any) => {
        let item = {
          partnerId: e.id,
          name: e.company_name,
          snippetId: this.snippet.id,
          is_selected: this.checkIfSelected(e.id),
          start_date: this.findPartnerSnippetStartDate(e.id, this.snippet.id),
          end_date: this.findPartnerSnippetEndDate(e.id, this.snippet.id),
        };

        if (item.is_selected) {
          const matched = this.item.snippet_partners.find(
            (e: any) => e.partner == item.partnerId
          );
          if (matched) {
            item["syndicatorId"] = matched.id;
          }
          this.selectedPartners.push(item);
        }

        return item;
      });
      this.modalPartnerList = partner_set;
    }
  }

  public findPartnerSnippetEndDate(
    partner_id: number,
    snippet_id: number
  ): any {
    const partner = this.partnerList.results.find(
      (e: any) => e.id == partner_id
    );
    const partner_snippet = partner.partner_snippets.find(
      (e: any) => e.snippet.id == snippet_id
    );
    let end_date = new Date(partner_snippet?.end_date) ?? "";
    let live_end_date = this.snippet.snippet_partners[0]?.end_date ?? "";
    const queueCardSnippetEndDate = live_end_date
      ? new Date(live_end_date)?.toISOString()?.split("T")[0]
      : "";
    return this.isValidDate(end_date)
      ? end_date.toISOString().split("T")[0]
      : queueCardSnippetEndDate;
  }

  public findPartnerSnippetStartDate(
    partner_id: number,
    snippet_id: number
  ): any {
    const partner = this.partnerList.results.find(
      (e: any) => e.id == partner_id
    );
    const partner_snippet = partner.partner_snippets.find(
      (e: any) => e.snippet.id == snippet_id
    );
    let start_date = new Date(partner_snippet?.start_date);

    return this.isValidDate(start_date)
      ? start_date.toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0];
  }

  public isValidDate(d: any): boolean {
    return !isNaN(d.getTime());
  }

  public checkIfSelected(partner_id: number) {
    const found: any = this.item.partners.find((e: any) => {
      return e.id == partner_id;
    });
    return found ? true : false;
  }

  public savePartner() {
    if (this.selectedPartners.length) {
      this.isUpdating = true;
      this.$emit("updateSnippetPartner", {
        selected: this.selectedPartners,
        replaceStartDate: this.snippet.snippet_partners[0]["start_date"],
      });

      // close modal
      let modalId = `${this.snippet.id}-partners-live`;
      this.$bvModal.hide(modalId);

      this.isUpdating = false;
    } else {
      this.$store.commit("setToast", {
        variant: "danger",
        title: "No partners selected",
        body: "No partners have been selected. Please select atleast one partner.",
      });
    }
  }

  public sendBackToDraft(id: number): void {
    this.$emit("snippetBackToDraft", {
      id: id,
      payload: { content_state: this.DRAFT_STATE },
    });
  }

  public savingUpdatedSnippet(): void {
    this.$emit("updateSnippetSaved", { updated_snip: this.snippet });
  }

  get searchFlag(): boolean {
    return this.isSearchResult;
  }

  get searchTermArray(): Array<string> {
    let queries = this.filterStore.searchTermValue?.split(" ") ?? [];
    return queries;
  }
}
