var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "cs-add-partner-container cs-edit-partner-modal" },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { md: "1" } }),
              _c("b-col", { attrs: { md: "10" } }, [
                _c(
                  "form",
                  {
                    attrs: { method: "post" },
                    on: { submit: _vm.checkEditPartnerForm },
                  },
                  [
                    _c("h1", { staticClass: "mt-5" }, [
                      _vm._v("Edit Partner Details"),
                    ]),
                    _vm.errors.length
                      ? _c("div", { staticClass: "cs-error-info" }, [
                          _c("b", [
                            _vm._v("Please correct the following error(s):"),
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function (error) {
                              return _c("li", { key: error.id }, [
                                _vm._v(_vm._s(error)),
                              ])
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm.errors.length
                      ? _c("div", { staticClass: "cs-error-info" }, [
                          _c("b", [
                            _vm._v("Please correct the following error(s):"),
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.errors, function (error) {
                              return _c("li", { key: error.id }, [
                                _vm._v(_vm._s(error)),
                              ])
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0",
                        attrs: {
                          label: "Company Name*",
                          "label-for": "company-name",
                        },
                      },
                      [
                        _c("b-form-select", {
                          staticClass: "mb-4",
                          attrs: {
                            id: "company-name",
                            disabled: true,
                            required: true,
                            options: _vm.filterPartnersCompany,
                          },
                          model: {
                            value: _vm.partnerCardData.company_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.partnerCardData, "company_name", $$v)
                            },
                            expression: "partnerCardData.company_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0",
                        attrs: { label: "Country*", "label-for": "country" },
                      },
                      [
                        _c("b-form-select", {
                          staticClass: "mb-4",
                          attrs: { id: "country", options: _vm.newCountryList },
                          model: {
                            value: _vm.partnerCardData.country,
                            callback: function ($$v) {
                              _vm.$set(_vm.partnerCardData, "country", $$v)
                            },
                            expression: "partnerCardData.country",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0",
                        attrs: { label: "Address*", "label-for": "address-1" },
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "mb-3",
                          attrs: { id: "address-1", required: true },
                          model: {
                            value: _vm.partnerCardData.address_1,
                            callback: function ($$v) {
                              _vm.$set(_vm.partnerCardData, "address_1", $$v)
                            },
                            expression: "partnerCardData.address_1",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0",
                        attrs: {
                          label: "Company URL*",
                          "label-for": "company-url",
                        },
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "mb-3",
                          attrs: { id: "company-url", required: true },
                          model: {
                            value: _vm.partnerCardData.company_url,
                            callback: function ($$v) {
                              _vm.$set(_vm.partnerCardData, "company_url", $$v)
                            },
                            expression: "partnerCardData.company_url",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("hr"),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0",
                        attrs: {
                          label: "Contact Name*",
                          "label-for": "contact-name",
                        },
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "mb-3",
                          attrs: { id: "contact-name", required: true },
                          model: {
                            value: _vm.contactDataFromProps.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.contactDataFromProps, "name", $$v)
                            },
                            expression: "contactDataFromProps.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mb-0",
                                attrs: {
                                  label: "Email Address*",
                                  "label-for": "email-address",
                                },
                              },
                              [
                                _c("b-form-input", {
                                  staticClass: "mb-3",
                                  attrs: {
                                    id: "email-address",
                                    required: true,
                                  },
                                  model: {
                                    value: _vm.contactDataFromProps.email,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.contactDataFromProps,
                                        "email",
                                        $$v
                                      )
                                    },
                                    expression: "contactDataFromProps.email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mb-0",
                                attrs: {
                                  label: "Phone Number*",
                                  "label-for": "phone-number",
                                },
                              },
                              [
                                _c("b-form-input", {
                                  staticClass: "mb-3",
                                  attrs: { id: "phone-number", required: true },
                                  model: {
                                    value:
                                      _vm.contactDataFromProps.phone_number,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.contactDataFromProps,
                                        "phone_number",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "contactDataFromProps.phone_number",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("hr"),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0",
                        attrs: {
                          label: "Microchip Owner*",
                          "label-for": "cs-owner",
                        },
                      },
                      [
                        _c("b-form-select", {
                          staticClass: "mb-4",
                          attrs: {
                            disabled: _vm.role === 2 ? true : false,
                            id: "cs-owner",
                            options: _vm.ownersID,
                          },
                          model: {
                            value: _vm.partnerCardData.user_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.partnerCardData, "user_id", $$v)
                            },
                            expression: "partnerCardData.user_id",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mb-0",
                                attrs: {
                                  label: "Type of Partner*",
                                  "label-for": "partner-type",
                                },
                              },
                              [
                                _c("b-form-select", {
                                  staticClass: "mb-4",
                                  attrs: {
                                    id: "partner-type",
                                    options: _vm.filterPartnerTypes,
                                  },
                                  model: {
                                    value: _vm.partnerCardData.partner_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.partnerCardData,
                                        "partner_type",
                                        $$v
                                      )
                                    },
                                    expression: "partnerCardData.partner_type",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mb-0",
                                attrs: {
                                  label: "Max number of live snippets*",
                                  "label-for": "max-snippet-number",
                                },
                              },
                              [
                                _c("b-form-select", {
                                  staticClass: "mb-3",
                                  attrs: {
                                    id: "max-snippet-number",
                                    options: _vm.snippetLimitOptions,
                                    disabled: _vm.role === 2 ? true : false,
                                  },
                                  model: {
                                    value: _vm.partnerCardData.snippet_limit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.partnerCardData,
                                        "snippet_limit",
                                        $$v
                                      )
                                    },
                                    expression: "partnerCardData.snippet_limit",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("hr", { staticClass: "grayed-out" }),
                    _c("div", { staticClass: "cs-api-container" }, [
                      _c("strong", [_vm._v("API Key")]),
                      _c("div", { staticClass: "cs-api-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.apiKey,
                              expression: "apiKey",
                            },
                          ],
                          staticClass: "cs-api-key-field",
                          attrs: { type: "text", disabled: "true" },
                          domProps: { value: _vm.apiKey },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.apiKey = $event.target.value
                            },
                          },
                        }),
                        _c("div", [
                          _c(
                            "a",
                            {
                              staticClass: "cs-renew-api-key",
                              attrs: {
                                href: "",
                                id: `renew-icon-${_vm.partnerCardData.id}`,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.renewAPIKey(
                                    $event,
                                    `renew-icon-${_vm.partnerCardData.id}`
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "17",
                                    height: "18",
                                    viewBox: "0 0 17 18",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M5.17027 10.3325H0.508789V14.994",
                                      stroke: "black",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M15.7157 11.9741C14.5897 15.2755 11.3165 17.3471 7.85081 16.9517C4.38515 16.5563 1.6626 13.8007 1.30908 10.3306",
                                      stroke: "black",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M11.8296 7.66832H16.4911V3.00684",
                                      stroke: "black",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M1.28613 6.02642C2.41178 2.72518 5.68444 0.653324 9.14991 1.04802C12.6154 1.44272 15.3383 4.19745 15.6928 7.66727",
                                      stroke: "black",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c("div", [
                          _c(
                            "a",
                            {
                              staticClass: "cs-copy-api-key",
                              attrs: { href: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyAPIKey(
                                    $event,
                                    `copy-alert-${_vm.partnerCardData.id}`
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "23",
                                    height: "24",
                                    viewBox: "0 0 23 24",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M9.60832 21.087H1.95648C1.42823 21.087 1 20.6588 1 20.1305V4.82684C1 4.29859 1.42823 3.87036 1.95648 3.87036H3.86944",
                                      stroke: "#53575A",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M13.4343 3.87036H15.3473C15.8755 3.87036 16.3038 4.29859 16.3038 4.82684V8.17452",
                                      stroke: "#53575A",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M8.65176 3.86944C9.44414 3.86944 10.0865 3.22709 10.0865 2.43472C10.0865 1.64235 9.44414 1 8.65176 1C7.85939 1 7.21704 1.64235 7.21704 2.43472C7.21704 3.22709 7.85939 3.86944 8.65176 3.86944Z",
                                      stroke: "#53575A",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M10.0052 2.91388H12.4777C13.006 2.91388 13.4342 3.34211 13.4342 3.87036V4.82684C13.4342 5.35509 13.006 5.78332 12.4777 5.78332H4.82586C4.29762 5.78332 3.86938 5.35509 3.86938 4.82684V3.87036C3.86938 3.34211 4.29762 2.91388 4.82586 2.91388H7.29837",
                                      stroke: "#53575A",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d: "M22.0425 22.0435C22.0425 22.5718 21.6143 23 21.086 23H12.4777C11.9495 23 11.5212 22.5718 11.5212 22.0435V11.044C11.5212 10.5158 11.9495 10.0875 12.4777 10.0875H19.2553C19.509 10.0875 19.7522 10.1884 19.9316 10.3678L21.7623 12.1985C21.9417 12.3778 22.0425 12.6211 22.0425 12.8747V22.0435Z",
                                      stroke: "#53575A",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M14.3906 14.3907H19.173",
                                      stroke: "#53575A",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M14.3906 17.2601H19.173",
                                      stroke: "#53575A",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c("div", { staticStyle: { position: "relative" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "cs-copy-alert hide",
                                attrs: {
                                  id: `copy-alert-${_vm.partnerCardData.id}`,
                                },
                              },
                              [
                                _c("span", { staticClass: "caret up" }),
                                _c("div", { staticClass: "cs-alert-content" }, [
                                  _vm._v(
                                    " The API Key has been saved to your clipboard. "
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm.apiKey === ""
                      ? _c("div", { staticClass: "no-api-key" }, [
                          _vm._v(" Click refresh icon to generate API key "),
                        ])
                      : _vm._e(),
                    _c("hr", { staticClass: "grayed-out" }),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-danger w-100 cs-red-btn mt-4 mb-4 pt-2 pb-2",
                        attrs: {
                          type: "submit",
                          disabled: _vm.partnerCardData.user_id === null,
                        },
                      },
                      [_vm._v("Save")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("b-col", { attrs: { md: "1" } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }