var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mchp-analytic-tabs" }, [
    _vm.campaign === null
      ? _c("div", [
          _vm._v("Add a Google Analytics campaign ID to see Clicks and views."),
        ])
      : _c(
          "div",
          { attrs: { "content-class": "mt-3" } },
          [
            _c(
              "b-row",
              { staticClass: "mt-1" },
              [
                _c(
                  "b-col",
                  [
                    _c("label", { attrs: { for: "view-start-date" } }, [
                      _vm._v("Start Date"),
                    ]),
                    _c("b-form-datepicker", {
                      staticClass: "mb-1",
                      attrs: {
                        id: "view-start-date",
                        placeholder: "Start Date",
                        "date-format-options": {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        },
                      },
                      model: {
                        value: _vm.viewStartDate,
                        callback: function ($$v) {
                          _vm.viewStartDate = $$v
                        },
                        expression: "viewStartDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  [
                    _c("label", { attrs: { for: "view-end-date" } }, [
                      _vm._v("End Date"),
                    ]),
                    _c("b-form-datepicker", {
                      staticClass: "mb-1",
                      attrs: {
                        id: "view-end-date",
                        placeholder: "End Date",
                        readonly: true,
                        disabled: true,
                        "date-format-options": {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        },
                      },
                      model: {
                        value: _vm.viewEndDate,
                        callback: function ($$v) {
                          _vm.viewEndDate = $$v
                        },
                        expression: "viewEndDate",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.campaignId
              ? _c("div", [
                  _vm.isLoading
                    ? _c(
                        "div",
                        { staticClass: "text-center mb-3 mt-3" },
                        [
                          _c("b-spinner", {
                            attrs: { variant: "danger", label: "Loading..." },
                          }),
                        ],
                        1
                      )
                    : !_vm.isError && !_vm.isLoading
                    ? _c(
                        "div",
                        [
                          _vm.pageViews.length
                            ? _c("Sparkline", {
                                attrs: { dataPoints: _vm.pageViews },
                              })
                            : _c(
                                "div",
                                { staticClass: "text-center mb-5 mt-5" },
                                [_c("span", [_vm._v("No Data")])]
                              ),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "text-center mb-5 mt-5" }, [
                        _c("span", [_vm._v(_vm._s(_vm.msg))]),
                      ]),
                ])
              : _c("div", { staticClass: "text-center mb-5 mt-5" }, [
                  _c("span", [_vm._v(_vm._s(_vm.msg))]),
                ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }