import service from "../../../utils/http/apiClient";
import { AccessTokens } from "@/utils/types";
import store from "@/store";
import router from "@/router";
import authUtils from "@/utils/authUtils";
import {getUserProfile} from "@/components/dashboard/utils";


export const getUrlMSAL = async ():Promise<any> => {
    try {
        const response = await service.get("/api/v1/msal/login_url");
        const loginRedirect = response.data?.login_url;
        window.location.href = loginRedirect
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger', 
            title: 'Not Found', 
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
    }
}

export const authUserLogout = async (): Promise<void> => {
    try{
        const response = await service.post("/server/logout", {});
        const logoutRedirect = response.data?.logout_url;
        window.location.href = logoutRedirect;
    } catch (err){
        // FATAL error if this happens
        //await router.push({name: 'Login'});
        console.log(err);
    }
}

export const authUserAccess = async (params: AccessTokens):Promise<void> => {
    try {
        const response = await service.post("/api/v1/msal/login_with_code", params);

        // instead of cookie move to localstorage
        const jwt_token: string = response?.data?.jwt_token;
        authUtils.setToken(jwt_token);  // JWT token

        const ms_token: string = response?.data?.ms_auth_token;
        authUtils.setMSAuthToken(ms_token);

        // set user
        const data = await getUserProfile();
        authUtils.setUserInfo(data);
        
        await router.replace({path: '/dashboard'});
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger', 
            title: 'Not Found', 
            body: "Sorry, Unable to login. Please try again."
        });

        await router.push({name: 'Login'});
    }
}
