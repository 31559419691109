
import { Component, Vue } from 'vue-property-decorator';
import {mapGetters} from 'vuex';

@Component({
  computed: {
    ...mapGetters({
      myName: 'Login/getCurrentUser'
    })
  }
})
export default class Login extends Vue {
  auth(): void {
    this.$store.dispatch('Login/getMSALUrl');
  }
}
