import service from "../../../utils/http/apiClient";
import store from "@/store";

function addDays(numOfDays: number, date = new Date()){
    date.setDate(date.getDate() + numOfDays);
    return date;
}

export const getAnalytics = async (payload: any): Promise<any> => {
    try{
        const response = await service.get("/analytics/", payload);
        return response.data;
    } catch (err: any){
        const errorResponse = err.response.data.error;
        store.commit('setToast', {
            variant: 'danger',
            title: `Error Code: ${errorResponse.code}`,
            body: `${errorResponse.message}`
        });
    }
}

export const getUserBusinessUnit = async ():Promise<any> => {
    const response = await service.get("syndication-user/account_me/");
    return response.data.profile.business_unit;
}

export const getSnippetFromServer = async (snippet_state: number):Promise<any> => {
    try {
        const today = new Date();
        const pub3weeksAgo = new Date(new Date().setDate(today.getDate() - 21));  // 3 weeks = 21 days
        const userBusinessUnit = localStorage.getItem("userBusinessUnit");

        const query_params = {
            'content_state': snippet_state,
            'business_unit': userBusinessUnit,
            'published_before': pub3weeksAgo.toISOString().split('T')[0],
        }

        const response = await service.get("snippet/", query_params);
        return response.data;
        
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger', 
            title: 'Not Found', 
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
    }
}

export const getPartnerListFromServer = async (params?: {[key: string]: any}): Promise<any> => {
    try{
        const response = await service.get("partner/", params);
        return response.data;
    } catch(err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Not Found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
    }
}

export const getPartnerListDetailFromServer = async (id:number): Promise<any> => {
    try{
        const response = await service.get(`partner/${id}/`);
        return response.data;
    } catch(err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Not Found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
    }
}

export const updateSnippetSummary = async (snippet: any, sDate: Date, eDate: Date):Promise<any> => {
    try {
        const path = `snippet/${snippet.id}/update_dates/`;
        const kwargs = {'summary': snippet.summary, start_date: sDate, end_date: eDate};
        const response = await service.patch(path, kwargs);

        store.commit('setToast', {
          variant: 'success',
          title: 'Summary Updated',
          body: "Snippet Summary updated successfully."
        });

        return response.data;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Not Found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
    }
}

export const updateSnippetWithPayload = async (id: number, payload: {[key: string]: any}): Promise<any> => {
    try {
        const response = await service.patch(`snippet/${id}/`, payload);

        const payload_keys = Object.keys(payload);
        const keys_str = payload_keys.length == 1 ? payload_keys[0] : payload_keys.join(",");
        store.commit('setToast', {
          variant: 'success',
          title: "Snippet Details Updated",
          body: `Snippet Updated.`
        });

        return response.data;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Not Found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
    }
}

export const getSnippetImageOptions = async (query_params: {[key: string]: any}): Promise<any> => {
    try {
        const response = await service.get("snippet-image/options", query_params);

        return response.data;
    } catch (err) {
        return false;
    }
}

export const addSnippetImageWithPayload = async (payload: {[key: string]: any}): Promise<any> => {
    try {
        const response = await service.post('snippet-image/', payload);

        store.commit('setToast', {
          variant: 'success',
          title: "Snippet Updated",
          body: `Snippet image added succesfully.`
        });

        return response.data;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Not Found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
    }
}

export const updateSnippetImageWithPayload = async (id: number, payload: {[key: string]: any}): Promise<any> => {
    try {
        const response = await service.patch(`snippet-image/${id}/`, payload);

        store.commit('setToast', {
          variant: 'success',
          title: "Snippet Updated",
          body: `Snippet image updated succesfully.`
        });

        return response.data;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Not Found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
    }
}

export const updateSyndicatorRow = async (payload: {[key: string]: any}): Promise<any> => {
    try {
        const response = await service.post(`syndicator/partner_upsert/`, payload);
        return response;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: `Update Failed`,
            body: `${err.response.data}.`
        });
        setTimeout(() => {
            const timer = document.getElementById('timer-container');
            timer!.classList.remove('hide-timer')
        }, 1000)
        return err.response;
    }
}

export const deleteSyndicatorRow = async (id: number): Promise<any> => {
    try {
        const response = await service.remove(`syndicator/${id}/`);
        return response;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: `Syndicator Remove {id} Failed`,
            body: "Sorry, Something failed. Please contact your administrator."
        });

        return err.response;
    }
}

export const resetSyndicatorRowForRevert = async (id: number): Promise<any> => {
    try{
        const today_plus_1 = addDays(1)
        const payload = {
            end_date: today_plus_1.toISOString().split('T')[0]
        };

        const response = await service.patch(`syndicator/${id}/`, payload);
        return response;
    }catch (err){
        store.commit('setToast', {
            variant: 'danger',
            title: `Server Failed to Respond in a Timely Matter.`,
            body: "Sorry, Something failed. Please contact your administrator."
        });

        return err.response;
    }
}

export const updateSnippetStateToQueued = async (id: number, snippet: any): Promise<any> => {
    try {
        const response = await service.patch(`snippet/${id}/update_state/?commit=True`, {snippet});

        store.commit('setToast', {
          variant: 'success',
          title: 'Snippet State Updated',
          body: "Snippet has been published successfully."
        });

        return {status: 200, data: response.data};
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Snippet State Update Failed',
            body: "Sorry, Something failed. Please contact your administrator."
        });

        return err.response;
    }
}

export const searchSnippetsInServer = async (params: { [key: string]: unknown }): Promise<any> => {
    try {
        const response = await service.get("snippet/", params);
        return response.data;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Not Found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
    }
}

export const getSnippetBusinessUnitFilters = async ():Promise<any> => {
    let responseData = null;

    try {
        const response = await service.get("syndication/business-units/");
        responseData = response.data;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Not Found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
        responseData = [];
    }

    return responseData;
}

export const getSnippetVFilterConstants = async ():Promise<any> => {
    try {
        const response = await service.get("snippets/v-filters/");
        return response.data;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Not Found',
            body: "Sorry, Unable to connect to server. Please contact your administrator."
        });
    }
}

export const getSnippetCampaignId = async (id: number): Promise<any> => {
    try {
        const response = await service.get(`snippet/${id}/`);
        return response.data.ga_campaign_id === null ? '' : response.data.ga_campaign_id;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Snippet GA Campaign ID Failed',
            body: "Sorry, Something failed. Please contact your administrator."
        });

        return err.response;
    }
}

export const getSnippetDetailFromServer = async (id: number): Promise<any> => {
    try {
        const response = await service.get(`snippet/${id}/`);
        return response.data;
    } catch (err) {
        store.commit('setToast', {
            variant: 'danger',
            title: 'Snippet detail failed',
            body: "Sorry, Something failed. Please contact your administrator."
        });

        return err.response;
    }
}

