
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'EditBusinessUnit'
})
export default class EditBusinessUnit extends Vue{
  @Prop({}) propSnippetId: any;
  @Prop({}) propBusinessUnit: any;
  @Prop({}) propBusinessUnits: any;

  public snippetId: number = 0;
  public businessUnit: string = '';
  public businessUnits: any;

  created(){
    this.snippetId = this.propSnippetId;
    this.businessUnit = this.propBusinessUnit;
    this.businessUnits = this.propBusinessUnits;
  }

  public updateBusinessUnit(){
    this.$emit('updatedBusinessUnit', {
      modalId: this.snippetId + '-business-unit',
      snippetId: this.snippetId,
      businessUnit: this.businessUnit
    });
  }

  get snippetBusinessUnit(): any {
    return this.businessUnits[this.businessUnit]
      ? this.businessUnits[this.businessUnit]?.title
      : this.businessUnit
  }

  get businessUnitsOptions() {
    let businessUnitOptions = [{"text": "None", "value": ''}];

    if (this.businessUnits) {
      for (let [key, value] of Object.entries<any>(this.businessUnits)) {
        businessUnitOptions.push({"text": value['title'], "value": key});
      }
    }

    return businessUnitOptions;
  }
}
