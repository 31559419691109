
import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
import {getModule} from "vuex-module-decorators";
import {getPartnersFromServer, searchPartnersInServer} from "@/components/partners/utils";
import FilterModule from "@/store/Filter";
import InActiveCards from './cards/InactiveCards.vue'
import {getSnippetFromServer} from "@/components/syndications/utils";


@Component({
  name: "ActivePartners",
  components: {InActiveCards}
})
export default class ActivePartners extends Vue {
  @Prop() readonly user: any | undefined
  @Prop() readonly dataList: any | Array<any>
  @Prop() readonly fieldDisplay: string|any
  
  public inActivePartnerList: any = null;
  public isLoading: boolean = false;
  public isSearch: boolean = false;

  public perPage: number = 5;
  public currentPage: number = 1;
  public perPageSelect = [
    {"value": 5, "text": "5"},
    {"value": 10, "text": "10"},
    {"value": 15, "text": "15"},
    {"value": 20, "text": "20"},
    {"value": 25, "text": "25"}
  ];

  filterStore = getModule(FilterModule, this.$store);

  async mounted(){
    this.inActivePartnerList = await getPartnersFromServer(false);
    this.$emit("searchBarDataList", this.inActivePartnerList, "company_name");
    this.isAddPartnerPage()
  }

  get getPageStart(): number {
    let start = (this.currentPage - 1) * this.perPage;
    if(this.snippetsForList.length) {
      start = start + 1;
    }
    else start = 0;
    return start;
  }

  get rows(): number{
    return this.inActivePartnerList?.results?.length ?? 0;
  }

  get searchName(): string|null {
    return this.filterStore.searchNameValue;
  }

  get partnerType(): string|null{
    return this.filterStore.partnerTypeValue;
  }

  get snippetsForList() {
    if (this.inActivePartnerList?.results) {
      return this.inActivePartnerList.results.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
      );
    }
    return [];
  }

  @Watch('searchName')
  async searchTermChanged() {
    await this.runSearch();
  }

  @Watch('partnerType')
  async searchPartnerType(){
    await this.runSearch();
  }

  @Watch('resetSearchFlag')
  async resetSearchFilter(){
    this.isLoading = true;
    this.isSearch = false;
    this.inActivePartnerList = await getPartnersFromServer(false);
    this.isLoading = false;
    this.filterStore.setResetSearchFlag(false);
  }

  async runSearch(){
    const searchParamsFromStore = {active: false, ...this.filterStore.generateSearchParams};
    this.isLoading = true;
    this.isSearch = true;
    this.inActivePartnerList = await searchPartnersInServer(searchParamsFromStore);
    this.isLoading = false;
  }

  // Hide or Show Filter Bar
  // For Partner's Page
  isAddPartnerPage() {
      let barElement = document.getElementById('cs-bar-filter');
      this.$attrs.isAddPartnerPage ? barElement!.style.display = 'none' : barElement!.style.display = 'unset';
  }
}
