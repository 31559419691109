import { VuexModule, Module, Mutation } from 'vuex-module-decorators';

@Module({
    namespaced: true,
    name: 'FilterModule',
})
class FilterModule extends VuexModule{
    private searchName: string|null = null;  // partner
    private searchTerm: string|null = null;  // snippets
    private contentType: string|null = null;
    private businessUnit: string|null = null;
    private partnerType: string|null = null;
    private resetSearchFlag: boolean = false;

    get searchNameValue(): string|null{
        return this.searchName;
    }

    get searchTermValue(): string|null{
        return this.searchTerm;
    }

    get businessUnitValue(): string|null{
        return this.businessUnit;
    }

    get contentTypeValue(): string|null{
        return this.contentType
    }

    get partnerTypeValue(): string|null{
        return this.partnerType;
    }

    get resetSearchFlagValue(): boolean{
        return this.resetSearchFlag;
    }

    get generateSearchParams(){
        const params: any = {};

        if(typeof this.searchName != 'undefined' && this.searchName){
            params.name = this.searchName;
        }
        if(typeof this.searchTerm != 'undefined' && this.searchTerm) {
            params.title = this.searchTerm;
        }
        if(typeof this.contentType != 'undefined' && this.contentType) {
            params.content_type = this.contentType;
        }
        if(typeof this.businessUnit != 'undefined' && this.businessUnit) {
            params.business_unit = this.businessUnit;
        }
        if(typeof this.partnerType != 'undefined' && this.partnerType){
            params.partner_type = this.partnerType;
        }

        // add published_before field
        const today = new Date();
        const pub3weeksAgo = new Date(new Date().setDate(today.getDate() - 21));  // 3 weeks = 21 days
        params["published_before"] = pub3weeksAgo.toISOString().split('T')[0];

        return params;
    }

    @Mutation
    setSearchName(term: string|null){
        this.searchName = term;
    }

    @Mutation
    setSearchTerm(term: string|null){
        this.searchTerm = term;
    }

    @Mutation
    setContentType(term: string|null) {
        this.contentType = term;
    }

    @Mutation
    setBusinessUnit(term: string|null) {
        this.businessUnit = term;
    }

    @Mutation
    setPartnerType(term: string|null) {
        this.partnerType = term;
    }

    @Mutation
    setResetSearchFlag(flag: boolean) {
        this.resetSearchFlag = flag;
    }
}

export default FilterModule;