import service from "../../../utils/http/apiClient";
import { AccessTokens } from "@/utils/types";
import store from "@/store";

export const getPartnerTypes = async (): Promise<any> => {
    try{
        const response = await service.get('/partner-type/');
        return response.data?.results ?? [];
    }catch (err){
        store.commit('setToast', {
            variant: 'danger',
            title: 'Server Unreachable',
            body: "Sorry, Server is Unreachable. Please contact your Administrator."
        })
    }
}

export const postPartnerType = async (payload: any): Promise<any> => {
    try{
        const response = await service.post('/partner-type/', payload);
        store.commit('setToast', {
            variant: 'success',
            title: 'Added',
            body: "Saved successfully."
        })
    }catch (err){
        store.commit('setToast', {
            variant: 'danger',
            title: 'Server Unreachable',
            body: "Sorry, Server is Unreachable. Please contact your Administrator."
        })
    }
}

export const removePartnerType = async (payload: any): Promise<any> => {
    try{
        const response = await service.remove(`/partner-type/${payload.id}/`, {data: payload.newPartner});
        store.commit('setToast', {
            variant: 'success',
            title: 'Removed',
            body: "Deleted successfully."
        })
    }catch (err){
        store.commit('setToast', {
            variant: 'danger',
            title: 'Server Unreachable',
            body: "Sorry, Server is Unreachable. Please contact your Administrator."
        })
    }
}

export const putPartnerType = async (payload: any): Promise<any> => {
    try{
        await service.put(`/partner-type/${payload.id}/`, payload);
        store.commit('setToast', {
            variant: 'success',
            title: 'Updated',
            body: "Updated successfully."
        })
    }catch (err){
        store.commit('setToast', {
            variant: 'danger',
            title: 'Server Unreachable',
            body: "Sorry, Server is Unreachable. Please contact your Administrator."
        })
    }
}

export const getSyndicationUsers = async (): Promise<any> => {
    try{
        const response = await service.get('/syndication-user/');
        return response.data;
    }catch (err){
        store.commit('setToast', {
            variant: 'danger',
            title: 'Server Unreachable',
            body: "Sorry, Server is Unreachable. Please contact your Administrator."
        })
    }
}

export const getUserAccess = async (): Promise<any> => {
    try{
        const result = await service.get(`syndication/groups/`);
        return result.data.groups;
    }catch (err){
        store.commit('setToast', {
            variant: 'danger',
            title: 'Server Unreachable',
            body: "Sorry, Server is Unreachable. Please contact your Administrator."
        })
    }
}

export const getBusinessUnits = async (): Promise<any> => {
    try{
        const result = await service.get('syndication/business-units/');
        return result.data;
    }catch (err){
        store.commit('setToast', {
            variant: 'danger',
            title: 'Server Unreachable',
            body: "Sorry, Server is Unreachable. Please contact your Administrator."
        })
    }
}

export const updateUsersDetails = async (origin: any, updatedRows: any): Promise<any> => {
    try{
        for (const index in updatedRows) {
            const row = updatedRows[index];
            const itemToUpdate = origin.filter((item: any) => row.id == item.id).shift();
            
            const userAccessId = row.user_access;
            itemToUpdate.groups = [userAccessId]

            const businessUnitString = row.business_unit;
            itemToUpdate.profile.business_unit = businessUnitString;
            
            delete itemToUpdate.id;
            await service.put(`/syndication-user/${row.id}/`, itemToUpdate);
        }
        store.commit('setToast', {
            variant: 'success',
            title: 'Updated Users',
            body: "Changed rows are updated successfully."
        })
    }catch (err){
        store.commit('setToast', {
            variant: 'danger',
            title: 'Server Unreachable',
            body: "Sorry, Server is Unreachable. Please contact your Administrator."
        })
    }
}