
import { Component, Vue } from 'vue-property-decorator';
import { getUserProfile, getBusinessUnits, postBusinessUnit } from "@/components/dashboard/utils";
import {getUserBusinessUnit} from "@/components/syndications/utils";
import EndSoonBlock from "@/components/dashboard/views/components/EndSoonBlock.vue";
import StartSoonBlock from "@/components/dashboard/views/components/StartSoonBlock.vue";
import authUtils from "@/utils/authUtils";

@Component({
  name: "Dashboard",
  components: { EndSoonBlock, StartSoonBlock },
})

export default class Dashboard extends Vue {
  public loggedUser: any = null;
  public isLoading: boolean = false;
  public userBusinessUnit: string = '';
  public optionUnits: Array<any> = [];
  public unitSelected: any = null;
  public isSubmit: boolean = false;

  async onRequest() {
    this.isSubmit=true;
    let response = await postBusinessUnit(this.unitSelected);
    let data = await getUserProfile();
    authUtils.setUserInfo(data);
    this.loggedUser = authUtils.getUserInfo();
    this.isSubmit=false;
  }

  async mounted() {
    this.isLoading = true;
    this.loggedUser = authUtils.getUserInfo();
    if(this.loggedUser == null){
      let data = await getUserProfile();
      authUtils.setUserInfo(data);
    }
    this.isLoading = false;
    this.userBusinessUnit = await getUserBusinessUnit();
    this.optionUnits = await getBusinessUnits();
  }

  get options() {
    const list: Array<any> = [{ value: null, text: 'Select Business Unit' }];
    for (const key in this.optionUnits) {
      list.push({
        value: key,
        text: this.optionUnits[key].title
      })
    }
    
    return list;
  }
  
  get fullName(): string{
    let firstName: string = this.loggedUser?.first_name ?? '';
    let surName: string = this.loggedUser?.last_name ?? '';
    return `${firstName} ${surName}`;
  }

  get email(): string{
    return this.loggedUser?.email ?? '';
  }

  get jobTitle(): string{
    return this.loggedUser?.profile?.job_title ?? '';
  }

  get business_unit(): string{
    return this.loggedUser?.profile?.business_unit ?? "";
  }

  get bu_stats() {
    return this.loggedUser?.stats ?? {};
  }

  get userHasBURequest(): boolean{
    let profile = this.loggedUser?.profile ?? {};
    if("bu_requested" in profile && profile.bu_requested){
      return true;
    }
    return false;
  }

  get userBusinessUnitFromServer():string {
    if (this.userBusinessUnit) {
      return this.userBusinessUnit.toUpperCase()
    }
    return "No business unit."
  }

  fromDashboardLink(e:Event, tabName:string) {
    e.preventDefault();
    try {
      if (tabName === 'Drafts') {
        this.$router.push({name: 'Drafts'})
      }
      else if (tabName === 'Queue') {
        this.$router.push({name: 'Queue'})
      }
      else if (tabName === 'Live') {
        this.$router.push({name: 'Live'})
      }
      else {
        this.$router.push({name: 'Finished'})
      }
    } catch (err) {
        console.log(err)
    } finally {
        localStorage.setItem("userBusinessUnit", this.userBusinessUnit)
        localStorage.setItem("isFromDashboard", "true");
    }
    
  }
}
