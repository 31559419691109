
import { Component, Prop, Vue } from "vue-property-decorator";
import EditSnippets from "../components/EditSnippets.vue";
import EditTitle from "../components/EditTitle.vue";
import EditImage from "../components/EditImage.vue";
import EditGAcampaign from "../components/EditGAcampaign.vue";
import EditBusinessUnit from "../components/EditBusinessUnit.vue";
import EditContentType from "../components/EditContentType.vue";
import ElemURL from "../components/ElemURL.vue";
import { getModule } from "vuex-module-decorators";
import FilterModule from "@/store/Filter";
import { updateSnippetStateToQueued } from "@/components/syndications/utils";
import ModalOriginalSummary from "./../common/ModalOriginalSummary.vue"
import store from "@/store";

@Component({
  name: "DraftCards",
  components: {
    EditSnippets,
    EditTitle,
    EditImage,
    EditGAcampaign,
    EditBusinessUnit,
    EditContentType,
    ElemURL,
    ModalOriginalSummary
  }
})
export default class DraftCards extends Vue {
  @Prop() readonly user: any | undefined;
  @Prop({ default: "" }) item: any;
  @Prop({ default: false }) isSearchResult: any;
  @Prop({ default: "" }) snippetDetail: any;
  @Prop({ default: "" }) businessUnits: any;

  public isUpdating: boolean = false;
  public snippet: any = null;
  public snippetOriginalText: any = "";
  public snippetId: any = "";

  filterStore = getModule(FilterModule, this.$store);

  public copyLastModifiedBy(lastModifiedBy: string) {
    navigator.clipboard.writeText(lastModifiedBy);
    store.commit('setToast', {
      variant: 'success',
      title: 'Last Modified By Copied',
      body: "Last Modified By copied to System Clipboard."
    });
  }

  public onShowModal(payload : any) {
    const params = {text: payload.original_summary, id: payload.id};
    const ref = this.$refs!.refSeeOriginal ?? "";
    ref["onShowModal"](params);
  }

  public closeModal(e: Event): void {
    this?.$bvModal.hide(e['modalId']);
  }

  public savingUpdatedSnippet(e: Event): void {
    let ref_id = `${e["snippet"]["id"]}-edit`;
    this?.$bvModal.hide(ref_id);

    if (this.snippet.snippet_partners.length != 0) {
      this.snippet.snippet_partners[0]["start_date"] = e["start_date"];
      this.snippet.snippet_partners[0]["end_date"] = e["end_date"];
    }

    this.$emit("updateSnippetSaved", e);
  }

  public savingUpdatedGaCampaignCode(e: Event): void {
    let ref_id = `${e["snippetId"]}-gacampaign`;
    this?.$bvModal.hide(ref_id);

    let gaCode = e["gaCampaignCode"];
    this.snippet.ga_campaign_id = gaCode;

    this.$emit("updateGaCampaignCodeSaved", {
      gaCampaignCode: gaCode,
      id: e["snippetId"],
    });
  }

  public savingUpdatedBusinessUnit(e: Event): void {
    // Close Modal
    this?.$bvModal.hide(e['modalId']);
    // Update current business unit
    this.snippet.business_unit = e['businessUnit'];

    this.$emit('updateBusinessUnitSaved', {
      id: e['snippetId'],
      businessUnit: e['businessUnit']
    });
  }

  public savingUpdatedContentType(e: Event): void {
    // Close Modal
    this?.$bvModal.hide(e['modalId']);
    // Update current business unit
    this.snippet.content_type = e['contentType'];

    this.$emit('updateContentTypeSaved', {
      id: e['snippetId'],
      contentType: e['contentType']
    });
  }

  public savingUpdateSnippetTitle(e: Event):void {
    let ref_id = `${e["snippetId"]}-snippet-title`;
    this?.$bvModal.hide(ref_id);

    let snip_title = e["title"];
    this.snippet.title = snip_title;

    this.$emit("updateSnippetTitleSaved", { title: snip_title, id: e["snippetId"] })
  }

  public savingUpdateSnippetImage(e: Event): void {
    // Close Modal
    this?.$bvModal.hide(e['modalId']);
    // Update current image
    this.snippet.images[0].path = e['selectedImageData'].path
    // Update current image from db
    this.$emit('updateSnippetImageSaved', {
      snippetId: e['snippetId'],
      snippetImageId: e['snippetImageId'],
      selectedImageData: e['selectedImageData']
    })
  }

  public savingAddSnippetImage(e: Event): void {
    // Close Modal
    this?.$bvModal.hide(e['modalId']);
    // Update current image
    this.snippet.images = [{
      path: e['selectedImageData'].path
    }];
    // Update current image from db
    this.$emit('addSnippetImageSaved', {
      snippetId: e['snippetId'],
      selectedImageData: e['selectedImageData']
    })
  }

  public async publishAction(id: number, snippet: any): Promise<void> {
    this.isUpdating = true;
    let data = await updateSnippetStateToQueued(id, snippet);
    if (data.status == 200) {
      this.$emit("updatedSnippetState");
    }
    this.isUpdating = false;
  }

  get searchFlag(): boolean {
    return this.isSearchResult;
  }

  get partner_count(): number {
    return this.snippet?.partners.length ?? 0;
  }

  get selectedPartnerIds(): Array<number> {
    let partnerIds = this.snippet.partners.map((x: any) => {
      return x.id;
    });

    return partnerIds;
  }

  get searchTermArray(): Array<string> {
    let queries = this.filterStore.searchTermValue?.split(" ") ?? [];
    return queries;
  }

  get hasErrors(): boolean {
    let snippet = this.snippet;
    return (
      Object.prototype.hasOwnProperty.call(snippet, "errors") &&
      snippet["errors"].length > 0
    );
  }

  get NumberOfErrors(): number {
    return this.snippet.errors.length ?? 0;
  }

  get updatedAt(): any {
    let date = new Date(this.snippet.updated_at).toISOString().split("T")[0];
    return date;
  }

  get snippetBusinessUnit(): any {
    return this.businessUnits[this.snippet.business_unit]
      ? this.businessUnits[this.snippet.business_unit]?.title
      : this.snippet.business_unit
  }

  created(): void {
    this.snippet = this.item;
  }

  beforeUpdate() {
    this.snippet.id === this.snippetDetail.id
      ? (this.snippet = this.snippetDetail)
      : (this.snippet = this.item);
  }
}
