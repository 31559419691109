import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '../components/login/views/Login.vue'
import Syndications from '../components/syndications/views/Syndications.vue'
import Queue from '../components/syndications/views/Queue.vue'
import Drafts from '../components/syndications/views/Drafts.vue'
import Partners from '../components/partners/views/Partners.vue'
import AddPartner from '../components/partners/views/AddPartner.vue'
import ActivePartners from '../components/partners/views/Active.vue'
import Inactive from '../components/partners/views/Inactive.vue'
import SyndicatedSnippets from '../components/partners/views/SyndicatedSnippets.vue'
import UserSettings from '../components/usersettings/views/UserSettings.vue'
import Dashboard from '../components/dashboard/views/Dashboard.vue'
import Faq from '../components/faq/views/Faq.vue'
import Navbar from '../components/Navbar.vue'

import store from "./../store";
import authUtils from "../utils/authUtils";
import CurrentlyLive from "@/components/syndications/views/CurrentlyLive.vue";
import Finished from "@/components/syndications/views/Finished.vue";
import { getUserProfile } from "@/components/dashboard/utils";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Login
  },
  {
    path: '/login/',
    name: 'Login',
    component: Login
  },
  {
    path: '/msal/redirect',
    name: 'MsalRedirect',
  },
  {
    path: '/',
    name: 'Navbar',
    component: Navbar,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { requiresAuth: true}
      },
      {
        path: '/syndications',
        name: 'Syndications',
        component: Syndications,
        children: [
          {
            name: 'Drafts',
            path: 'drafts',
            component: Drafts,
            meta: { requiresAuth: true}
          },
          {
            name: 'Queue',
            path: 'queue',
            component: Queue,
            meta: { requiresAuth: true}
          },
          {
            name: 'Live',
            path: 'live',
            component: CurrentlyLive,
            meta: { requiresAuth: true}
          },
          {
            name: 'Finished',
            path: 'finished',
            component: Finished,
            meta: { requiresAuth: true}
          }
        ],
        meta: { requiresAuth: true}
      },
      {
        path: '/syndicated/:partnerId',
        name: 'Syndicated Snippets',
        component: SyndicatedSnippets,
        children: [
          {
            name: 'Syndicated Live',
            path: 'live',
            component: CurrentlyLive,
            props: { isSyndicatedSnippetPage: true },
            meta: { requiresAuth: true},
          },
          {
            name: 'Syndicated Queue',
            path: 'queue',
            component: Queue,
            props: { isSyndicatedSnippetPage: true },
            meta: { requiresAuth: true},
          },
          {
            name: 'Syndicated Finished',
            path: 'finished',
            component: Finished,
            props: { isSyndicatedSnippetPage: true },
            meta: { requiresAuth: true},
          },
        ]
      },
      {
        path: '/partners',
        name: 'Partners',
        component: Partners,
        meta: { requiresAuth: true},
        children: [
          {
            name: 'Active',
            path: 'active',
            component: ActivePartners,
            meta: { requiresAuth: true},
            props: { isAddPartnerPage: false },
          },
          {
            name: 'Inactive',
            path: 'inactive',
            component: Inactive,
            meta: { requiresAuth: true},
            props: { isAddPartnerPage: false },
          },
          {
            name: 'Add Partner',
            path: 'addpartner',
            component: AddPartner,
            meta: { requiresAuth: true},
            props: { isAddPartnerPage: true },
          }
        ]
      },
      {
        path: '/user-settings',
        name: 'User Settings',
        component: UserSettings,
        meta: { requiresAuth: true, requiresAdmin: true}
      },
      {
        path: '/faq',
        name: 'Faq',
        component: Faq
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from ,next) => {
  const path: string = to?.name ?? "";

  // Navigation guard
  const requiresAuth: boolean = to.matched.some(e => e.meta.requiresAuth);
  const requiresAdmin: boolean = to.matched.some(e => e.meta.requiresAdmin ?? false);
  const isLoggedIn: boolean = authUtils.isLoggedIn();

  if(requiresAuth && !isLoggedIn){
    next({name: 'Login'});
  } else if(['MsalRedirect'].includes(path)) {
    store.dispatch('Login/loginWithCodeAction', to.query);
  } else{
    try {
      const grpNo = JSON.parse(localStorage.getItem("userInfo") ?? "{}" )["groups"][0] ?? 4;
      const profile = await getUserProfile();
      authUtils.setUserInfo(profile);
      await store.dispatch('userAccess');
      
      if(grpNo != profile!.groups[0]) {
        await store.commit('setToast', {
          variant: 'warning',
          title: 'User rights changed!',
          body: "An administrator updated your settings, your page will reload."
        })
        
        await setTimeout(() => {
          window.location.reload()
        }, 1000);
      }

      if(requiresAdmin) {
        if(grpNo == 1) next();
        else next({name: 'Dashboard'});
      }
      else {
        next();
      }

    } catch (error) {
        next();
    }
  }
});

export default router
