
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SnippetOptionsEnum } from "./../../../../enums";

@Component({
  name: "EditContentType"
})
export default class EditContentType extends Vue{
  @Prop({}) propSnippetId: any;
  @Prop({}) propContentType: any;

  public snippetId: number = 0;
  public contentType: string = '';
  public contentTypeOptions: any;

  created(){
    this.snippetId = this.propSnippetId;
    this.contentType = this.convertUtmMedium(this.propContentType);
    this.contentTypeOptions = SnippetOptionsEnum['SNIPPET_CONTENT_TYPES'];
  }

  public convertUtmMedium(contentType: string): string {
    return contentType
      .split('')
      .map((character) => {
        if (character == character.toUpperCase() && !['-'].includes(character)) {
          return '-' + character.toLowerCase();
        } else {
          return character;
        }
      })
      .join('');
  }

  public updateContentType() {
    this.$emit('updatedContentType', {
      modalId: this.snippetId + '-content-type',
      snippetId: this.snippetId,
      contentType: this.contentType
    });
  }

  get contentTypeDescription(): any {
    return this.contentType
      ? this.contentTypeOptions.find((item: any): any => item.value == this.contentType)?.description
      : ''
  }
}
