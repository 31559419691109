
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Sparkline from "../components/SparkLine.vue";
import { DateTime } from "luxon";
import { getAnalytics } from "@/components/syndications/utils";

@Component({
  name: "AnalyticGraph",
  components: {
    Sparkline,
  }
})
export default class AnalyticGraph extends Vue {
  @Prop({ default: "" }) campaignId!: string;

  public viewStartDate: string = DateTime.now().minus({ days: 30 }).toISODate();
  public viewEndDate: string = DateTime.now().toISODate();
  public campaign: string = "";
  public pageViews: any[] = [];
  public isLoading: boolean = false;
  public msg: string = "NO DATA AVAILABLE";
  public isError = false;

  async getSnippetAnalytics(payload: any){
    try {
      this.isError = false;
      this.msg = "NO DATA AVAILABLE";
      
      if(this.campaignId) {
        this.isLoading = true;
        const {startDate, endDate} = payload;
        payload = {
            metrics: 'ga:pageviews',
            startDate: this.viewStartDate,
            endDate: this.viewEndDate,
            filters: this.campaignId ? `ga:campaign==${this.campaignId}` : ''
        }

        let searchParam = new URLSearchParams(payload);
        ["ga:year", "ga:month", "ga:day"].forEach((item: any) => {
          searchParam.append("dimensions", item)
        })

        const result = await getAnalytics(searchParam);
        const rows = result[0]!.data.rows;
        rows.forEach((row: any) => {
          this.pageViews.push(row.metrics[0].values[0])
        })
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.isError = false;
      this.msg = "Server error, please try again.";
    }
  }

  async analyticsDate(params: any){
    this.pageViews = []
    await this.getSnippetAnalytics(params);
  }

  async created() {
    await this.getSnippetAnalytics({
      startDate: this.viewStartDate,
      endDate: this.viewEndDate
    });
  }

  @Watch("viewStartDate")
  async onUpdateStartDate(value: any) {
    this.analyticsDate({
      startDate: value,
      endDate: this.viewEndDate
    })
  }

  @Watch("viewEndDate")
  async onUpdateEndDate(value: any) {
    this.analyticsDate({
      startDate: this.viewEndDate,
      endDate: value
    })
  }

}
