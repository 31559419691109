var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mchp-card-row-partner mb-4" },
        [
          _c(
            "div",
            { staticClass: "mchp-partner-btn-top-container d-flex mb-5" },
            [
              _c(
                "b-modal",
                {
                  attrs: {
                    id: `${_vm.item.id}-edit-partner`,
                    title: "",
                    size: "xl",
                    centered: "",
                    "hide-footer": true,
                    "header-class": "mchp-edit-header",
                  },
                },
                [
                  _c("EditPartner", {
                    attrs: {
                      id: `${_vm.item.id}-edit-partner`,
                      partner_item: _vm.item,
                      page_is_active: false,
                    },
                  }),
                ],
                1
              ),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal",
                      value: `${_vm.item.id}-edit-partner`,
                      expression: "`${item.id}-edit-partner`",
                    },
                  ],
                  staticClass: "btn btn-danger cs-plain-btn mt-2",
                  attrs: { disabled: !_vm.user.canEdit },
                },
                [_c("b-icon-pencil", {}), _vm._v(" Edit Partner Details")],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("b-card-title", [
                _vm._v(" Company Information "),
                _c("hr"),
                _vm.searchFlag
                  ? _c(
                      "h5",
                      [
                        _c(
                          "text-highlight",
                          { attrs: { queries: _vm.searchNameArray } },
                          [_vm._v(" " + _vm._s(_vm.item.company_name) + " ")]
                        ),
                      ],
                      1
                    )
                  : _c("h5", [
                      _vm._v(" " + _vm._s(_vm.item.company_name) + " "),
                    ]),
                _c(
                  "a",
                  { staticClass: "mchp-partner-link", attrs: { href: "#" } },
                  [_vm._v(_vm._s(_vm.item.company_url))]
                ),
                _c("div", { staticClass: "mchp-partner-block" }, [
                  _c("address", [
                    _vm._v(" " + _vm._s(_vm.item.address_1) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.item.address_2) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.item.city) + " "),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.item.state_province) +
                        " " +
                        _vm._s(_vm.item.zip_code) +
                        " "
                    ),
                    _c("br"),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("b-card-title", [
                _vm._v(" Contact "),
                _c("hr"),
                _c("h5", [
                  _vm._v(
                    _vm._s(_vm.item.contact.first_name) +
                      " " +
                      _vm._s(_vm.item.contact.last_name)
                  ),
                ]),
                _c("div", { staticClass: "mchp-partner-link mb-0" }, [
                  _vm._v(_vm._s(_vm.item.contact.email)),
                ]),
                _c("div", { staticClass: "mchp-partner-block mt-1" }, [
                  _vm._v(_vm._s(_vm.item.contact.phone_number)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("b-card-title", [
                _vm._v(" Contract Details "),
                _c("hr"),
                _c("h5", [_vm._v("Partner Type")]),
                _c("div", { staticClass: "mchp-partner-block" }, [
                  _vm._v(_vm._s(_vm.partnerType)),
                ]),
                _c("h5", [_vm._v("Number of snippets allowed")]),
                _c("div", { staticClass: "mchp-partner-block" }, [
                  _vm._v(_vm._s(_vm.item.snippet_limit)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mchp-partner-btn-bottom-container d-block mt-5" },
            [
              _c(
                "b-button",
                {
                  staticClass: "default-btn-base mchp-syn-snip-btn",
                  attrs: { pill: "", variant: "outline-secondary" },
                },
                [
                  _vm._v(
                    "Syndicated Snippets (" +
                      _vm._s(_vm.item.partner_snippets.length) +
                      ")"
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "mchp-partner-btn-bottom-container d-block mb-4 mt-2",
            },
            [
              _vm.item.is_approved
                ? _c(
                    "b-button",
                    {
                      staticClass: "default-btn-base btn-success",
                      attrs: { pill: "" },
                    },
                    [_vm._v("Approved Partner")]
                  )
                : _c(
                    "b-button",
                    {
                      staticClass: "default-btn-base btn-warning",
                      attrs: { pill: "" },
                    },
                    [_vm._v("Unapproved Partner")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }