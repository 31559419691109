import jwt_decode from "jwt-decode";
import axios from "axios";
import {SERVER_URL} from "@/config";

function setToken(token: string): void {
    localStorage.setItem("token", JSON.stringify(token));
}

function getToken(): any | null {
    try {
        const storedToken: string = localStorage.getItem("token") ?? ""
        const token = JSON.parse(storedToken);
        return token;
    } catch {
        return null;
    }
}

function deleteToken(): void {
    localStorage.removeItem("token");
}

function isTokenExpired(token: string): boolean {
    try {
        const decoded_token: any = jwt_decode(token);
        return decoded_token.exp < Date.now().valueOf() / 1000;
    } catch {
        return true;
    }
}

function isLoggedIn(): boolean {
    const refresh = getToken()?.refresh;
    return !isTokenExpired(refresh ?? "");
}

function refreshAccessTokenIfExpired(): Promise<any> {
    return new Promise((resolve, reject) => {
        const token = getToken();
        if (!isTokenExpired(token.access)) {
            resolve(token);
            return;
        }

        const url = `${SERVER_URL}/api/v1/token/refresh/`;
        const config = {refresh: token.refresh};
        axios
            .post(url, config)
            .then(({data}): void => {
                const newToken = {...token, access: data.access};
                setToken(newToken);
                resolve(newToken);
            })
            .catch((error: any) => {
                // Handle more errors
                if (error.response) {
                    if (error.response.status === 401) {
                        //store.dispatch(ACTIONS.AUTH_LOGOUT);
                        console.log('error dispatch');
                    }
                }
                reject(error);
            });
    });
}

function setMSAuthToken(token: string): void{
    localStorage.setItem("ms_token", JSON.stringify(token));
}

function getMSAuthToken(): string | null {
    try {
        const storedToken: string = localStorage.getItem("ms_token") ?? ""
        const token = JSON.parse(storedToken);
        return token;
    } catch {
        return null;
    }
}

function deleteMSAuthToken(): void {
    localStorage.removeItem("ms_token");
}

function setUserInfo(user: any): void{
    localStorage.setItem("userInfo", JSON.stringify(user));
}

function getUserInfo(): any | null {
    try {
        const storedInfo: string = localStorage.getItem("userInfo") ?? ""
        const userInfo = JSON.parse(storedInfo);
        return userInfo;
    } catch {
        return null;
    }
}

export default {
    setToken,
    getToken,
    setUserInfo,
    getUserInfo,
    deleteToken,
    isTokenExpired,
    isLoggedIn,
    refreshAccessTokenIfExpired,
    // ms auth token
    setMSAuthToken,
    getMSAuthToken,
    deleteMSAuthToken,
}