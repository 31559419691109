
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Icon extends Vue {
  @Prop({ default: "box", required: true }) name!: string;
  @Prop({ default: 16 }) width!: string;
  @Prop({ default: 16 }) height!: string;
  @Prop({ default: "currentColor" }) color!: string;

  public path: string = "";

  // Set the Icon path based on the name prop
  get iconPath(): string {
    switch (this.name) {
      case "info":
        return (this.path =
          "M8 15.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Zm0-1a6.5 6.5 0 1 1 0-13 6.5 6.5 0 0 1 0 13Zm-.5-7.906v3.954c0 .628.509 1.136 1.137 1.136h.954a.5.5 0 1 0 0-1h-.954a.136.136 0 0 1-.137-.136V6.412a.818.818 0 0 0-.818-.818h-.954a.5.5 0 0 0 0 1H7.5ZM8 4a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Z");
      case "partner":
      case "partners":
        return (this.path =
          "M3.394 9.939h1.264l2.386 2.674c.358.416.944.567 1.465.374.254-.095.524-.323.703-.619a1.52 1.52 0 0 0 .204-.563 1.55 1.55 0 0 0 1.641-1.127l3.573-.679c.167.375.542.636.979.636H17a.375.375 0 0 0 0-.75h-1.391a.321.321 0 0 1-.321-.321v-4.87a.32.32 0 0 1 .321-.32H17a.375.375 0 0 0 0-.75h-1.391c-.414 0-.773.235-.952.579l-3.164-1.148a2.062 2.062 0 0 0-1.624.09l-1.824.912-.084-.068a1.864 1.864 0 0 0-1.802-.234L3.462 4.836v-.142a1.07 1.07 0 0 0-1.071-1.07H1a.375.375 0 0 0 0 .75h1.391a.32.32 0 0 1 .321.32v4.874a.321.321 0 0 1-.321.317H1a.375.375 0 0 0 0 .75h1.391c.46 0 .852-.29 1.003-.696Zm3.889-5.48a1.202 1.202 0 0 0 1.09 2.103l1.186-.389 2.064 2.644a.668.668 0 0 1-.536 1.068v.009a.286.286 0 0 0-.075-.009h-.117l-.934-.934a.375.375 0 0 0-.53.53l.92.92a.8.8 0 0 1-1.087.604.507.507 0 0 0-.047-.015l-.648-.648a.375.375 0 0 0-.53.53l.62.621.008.041c.037.153-.017.312-.097.446-.085.14-.202.259-.322.304a.58.58 0 0 1-.64-.165L5.106 9.314a.377.377 0 0 0-.28-.125H3.462V5.644l2.975-1.193a1.12 1.12 0 0 1 .846.008Zm5.154 5.194c.14-.435.064-.916-.215-1.288l-1.906-2.441.54-.178a.374.374 0 1 0-.234-.712l-2.479.815a.45.45 0 0 1-.342-.832l2.401-1.2a1.31 1.31 0 0 1 1.034-.057l3.302 1.197v4.296l-2.101.4Z");
      case "setting":
      case "settings":
        return (this.path =
          "m13.275 5.763.352-.738a1.642 1.642 0 0 0-2.189-2.188l-.737.352a.64.64 0 0 1-.879-.364l-.274-.771a1.642 1.642 0 0 0-3.093 0l-.274.77a.642.642 0 0 1-.879.363l-.738-.35a1.64 1.64 0 0 0-2.188 2.189l.35.737a.637.637 0 0 1-.364.878l-.77.274a1.643 1.643 0 0 0 0 3.094l.77.274c.171.06.308.191.378.358a.643.643 0 0 1-.014.521l-.35.739a1.636 1.636 0 0 0 .32 1.867 1.639 1.639 0 0 0 1.869.32l.736-.35a.643.643 0 0 1 .88.364l.274.77a1.642 1.642 0 0 0 3.093 0l.274-.77a.642.642 0 0 1 .358-.378.643.643 0 0 1 .521.014l.738.35a1.642 1.642 0 0 0 2.188-2.189l-.352-.736a.641.641 0 0 1 .364-.879l.771-.274a1.642 1.642 0 0 0-.001-3.094l-.77-.273a.643.643 0 0 1-.364-.88Zm-.551-1.168-.352.739a1.642 1.642 0 0 0 .933 2.251l.77.274a.641.641 0 0 1 0 1.209l-.77.274a1.64 1.64 0 0 0-.933 2.251l.352.738a.64.64 0 0 1-.855.855l-.739-.351a1.642 1.642 0 0 0-2.251.932l-.274.77a.64.64 0 0 1-1.208 0l-.274-.77a1.641 1.641 0 0 0-2.251-.932l-.739.351a.642.642 0 0 1-.855-.855l.352-.74a1.644 1.644 0 0 0-.933-2.251l-.77-.274a.64.64 0 0 1 0-1.208l.77-.274a1.644 1.644 0 0 0 .933-2.25l-.352-.74a.642.642 0 0 1 .855-.855l.74.352a1.64 1.64 0 0 0 2.25-.931l.274-.77a.641.641 0 0 1 1.208 0l.274.77a1.642 1.642 0 0 0 2.251.932l.739-.352a.642.642 0 0 1 .855.855Zm-4.719 7.107a3.239 3.239 0 1 0 0-6.478 3.239 3.239 0 0 0 0 6.478Zm0-1a2.238 2.238 0 1 1-.001-4.477 2.238 2.238 0 0 1 .001 4.477Z");
      case "syndication":
        return (this.path =
          "M14.364.037H1.636C1.009.037.5.546.5 1.173V13.9c0 .628.509 1.137 1.136 1.137h12.728c.627 0 1.136-.509 1.136-1.137V1.173c0-.627-.509-1.136-1.136-1.136Zm-9.41 14H1.636A.136.136 0 0 1 1.5 13.9V4.855h3.454v9.182ZM14.5 4.855V13.9a.136.136 0 0 1-.136.137h-8.41V4.855H14.5Zm0-1h-13V1.173c0-.075.061-.136.136-.136h12.728c.075 0 .136.061.136.136v2.682Z");
      case "dashboard":
        return (this.path =
          "M3.21 5.718a.307.307 0 0 0-.026.025L.652 8.203a.5.5 0 0 0 .696.717l1.697-1.648v6.049c0 .661.518 1.18 1.136 1.18h7.637c.618 0 1.136-.519 1.136-1.18v-6.05l1.698 1.649a.5.5 0 0 0 .696-.717L8.776 1.819a1.1 1.1 0 0 0-1.552 0L3.21 5.718Zm8.744.582v7.021c0 .091-.051.18-.136.18h-1.409v-2.9c0-1.411-1.088-2.54-2.409-2.54-1.321 0-2.409 1.129-2.409 2.54v2.9h-1.41c-.085 0-.136-.089-.136-.18v-7.02L7.92 2.537a.113.113 0 0 1 .16 0L11.954 6.3Zm-2.545 7.201H6.591v-2.9c0-.841.621-1.54 1.409-1.54s1.409.699 1.409 1.54v2.9Z");
      case "accordion":
        return (this.path =
          "M7.531 2.436a.5.5 0 0 1 .756 0l3.157 3.646a.5.5 0 0 1-.378.827H4.752a.5.5 0 0 1-.378-.827l3.157-3.646Zm.756 11.128a.5.5 0 0 1-.756 0L4.374 9.918a.5.5 0 0 1 .378-.827h6.314a.5.5 0 0 1 .378.827l-3.157 3.646Z");
      case "edit":
      case "pencil":
        return (this.path =
          "M11.368 1.164 1.55 10.982a.501.501 0 0 0-.138.21L.025 15.354a.49.49 0 0 0 .621.621l4.15-1.383A.497.497 0 0 0 5 14.469l9.844-9.843.018-.02.45-.449A2.452 2.452 0 0 0 11.844.689l-.469.468-.007.007ZM2.092 12.255l1.653 1.653-2.479.826.826-2.479Zm11.711-7.976-9.15 9.149-2.081-2.081 9.15-9.149 2.081 2.081Zm.693-.694-2.08-2.081.111-.112a1.472 1.472 0 0 1 2.085 2.077l-.116.116Z");
      case "remove":
      case "delete":
      case "trash":
        return (this.path =
          "M4.572 2.612H.49a.49.49 0 0 0 0 .98h1.192l1.136 10.8A1.796 1.796 0 0 0 4.604 16h6.793c.918 0 1.689-.694 1.785-1.608l1.137-10.8h1.191a.49.49 0 0 0 0-.98h-4.081V1.143C11.429.512 10.917 0 10.286 0H5.715c-.632 0-1.143.512-1.143 1.143v1.469Zm8.762.98-1.127 10.697a.814.814 0 0 1-.811.731H4.604a.815.815 0 0 1-.811-.731L2.667 3.592h10.667ZM4.572 6.064l.327 6.857a.49.49 0 0 0 .978-.046l-.326-6.857a.49.49 0 0 0-.979.046Zm5.877-.046-.326 6.857a.49.49 0 0 0 .978.046l.327-6.857a.489.489 0 1 0-.979-.046Zm-2.938.023v6.857a.49.49 0 1 0 .979 0V6.041a.49.49 0 1 0-.979 0Zm2.938-3.429H5.551V1.143c0-.09.073-.163.164-.163h4.571c.09 0 .163.073.163.163v1.469Z");
      case "add":
      case "plus":
        return (this.path =
          "M7.5 7.5H.667a.5.5 0 0 0 0 1H7.5v6.833a.5.5 0 0 0 1 0V8.5h6.834a.5.5 0 0 0 0-1H8.5V.667a.5.5 0 0 0-1 0V7.5Z");
      case "cancel":
        return (this.path =
          "M8 7.293 3.168 2.461a.5.5 0 0 0-.707.707L7.293 8l-4.832 4.831a.5.5 0 0 0 .707.707L8 8.707l4.833 4.832a.5.5 0 0 0 .707-.707L8.707 8l4.832-4.832a.5.5 0 0 0-.707-.707L8 7.293Z");
      case "subscript":
        return (this.path = "M6.4 6 10 9.6 8.6 11 5 7.4 1.4 11 0 9.6 3.6 6 0 2.4 1.4 1 5 4.6 8.6 1 10 2.4 6.4 6Zm6.912 7.674 1.069-1.146c.195-.202.37-.408.536-.61.16-.193.305-.4.432-.616.12-.2.218-.413.293-.634.07-.215.107-.44.106-.666a2.16 2.16 0 0 0-.153-.822 1.715 1.715 0 0 0-.45-.642 2.03 2.03 0 0 0-.72-.406 3.17 3.17 0 0 0-1.95.045 2.146 2.146 0 0 0-.763.494c-.209.21-.37.46-.475.736-.1.266-.153.548-.156.833l-.003.237h1.539l.006-.227c.002-.127.02-.253.054-.375a.895.895 0 0 1 .157-.294.7.7 0 0 1 .259-.184c.224-.089.473-.094.702-.015a.62.62 0 0 1 .215.157c.06.072.107.155.137.244a1.092 1.092 0 0 1 .023.601c-.03.104-.07.205-.124.299a2.95 2.95 0 0 1-.263.388 6.435 6.435 0 0 1-.43.502l-2.156 2.354V15H16v-1.326h-2.688Z");
      case "superscript":
        return (this.path = "m6.4 10 3.6 3.6L8.6 15 5 11.4 1.4 15 0 13.6 3.6 10 0 6.4 1.4 5 5 8.6 8.6 5 10 6.4 6.4 10Zm6.912-3.326 1.069-1.147c.195-.2.37-.407.536-.608.16-.195.304-.4.432-.617.12-.2.218-.413.293-.634.07-.215.107-.44.106-.666a2.16 2.16 0 0 0-.153-.822 1.715 1.715 0 0 0-.45-.642 2.03 2.03 0 0 0-.72-.406 3.17 3.17 0 0 0-1.95.045 2.145 2.145 0 0 0-.763.494c-.209.209-.37.46-.475.736-.1.266-.153.548-.156.833l-.003.237h1.539l.006-.227c.002-.127.02-.253.054-.375a.895.895 0 0 1 .157-.294.701.701 0 0 1 .259-.184c.224-.09.473-.094.702-.015a.62.62 0 0 1 .215.157c.06.072.107.154.137.243a1.092 1.092 0 0 1 .023.602c-.03.104-.07.205-.124.299a2.94 2.94 0 0 1-.263.388 6.427 6.427 0 0 1-.43.502l-2.156 2.353V8H16V6.674h-2.688Z");
      case "analytics-graph":
        return (this.path = "m13.574 2.213-2.418 3.089a1.198 1.198 0 0 0-1.621.532l-2.629-.938a1.2 1.2 0 1 0-2.311.263L.72 7.469V.5a.22.22 0 0 0-.442 0v14.672c0 .301.245.546.547.546h14.671a.22.22 0 1 0 0-.441H.825a.105.105 0 0 1-.105-.105v-1.184l3.452-2.256a1.197 1.197 0 0 0 2.072-.578h2.534a1.198 1.198 0 1 0 2.31-.616l2.525-1.431a1.2 1.2 0 1 0-.222-.381l-2.523 1.429a1.2 1.2 0 0 0-2.09.558H6.244a1.197 1.197 0 0 0-2.377.22c0 .148.027.29.076.421L.72 13.461V7.983l4.104-2.446a1.199 1.199 0 0 0 1.945-.221l2.646.944a1.198 1.198 0 1 0 2.089-.687l2.4-3.067a1.2 1.2 0 1 0-.33-.293Zm-9.149 9.123a.298.298 0 0 0-.014-.024l-.006-.008a.758.758 0 1 1 .02.032Zm6.18-.795a.054.054 0 0 0 .007.013l.009.016a.757.757 0 1 1-.016-.029Zm3.916-1.459a.757.757 0 1 1-.001-1.515.757.757 0 0 1 .001 1.515Zm-3.155-2.713a.757.757 0 1 1-.758-.758c.471 0 .758.338.758.758ZM5.082 5.15l-.01-.018-.013-.021a.757.757 0 1 1 .023.039Zm8.945-3.097A.223.223 0 0 0 14 2.027l-.004-.003a.757.757 0 1 1 .031.029Z")
      default:
        return (this.path =
          "M7.531 2.436a.5.5 0 0 1 .756 0l3.157 3.646a.5.5 0 0 1-.378.827H4.752a.5.5 0 0 1-.378-.827l3.157-3.646Zm.756 11.128a.5.5 0 0 1-.756 0L4.374 9.918a.5.5 0 0 1 .378-.827h6.314a.5.5 0 0 1 .378.827l-3.157 3.646Z");
    }
  }

  mounted(): void {
    this.iconPath;
  }

  @Watch("name")
  nameChanged(newName: string): void {
    this.name = newName;
    this.iconPath;
  }
}
